import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TransportOrdersTable.scss';
import { useTranslation } from 'react-i18next';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import CostCell from 'common/components/Table/cell-renderers/CostCell/CostCell';
import { TransportOrderStatusEnum } from 'common/utils/api/models';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import { TransportOrderT } from 'common/store/transport-orders/models';
import LocationCell from './cell-renderers/LocationCell/LocationCell';
import ReceivedCell from './cell-renderers/ReceivedCell/ReceivedCell';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { DirectionEnum } from 'common/constants';
import IdCell from 'common/components/Table/cell-renderers/IdCell/IdCell';

const cx = classNames.bind(styles);

type PropsT = {
    orders: Array<TransportOrderT>;
    className?: string;
    goToTransportOrderDetails: (event: React.MouseEvent, orderId: string) => void;
    isLoading: boolean;
};

const TransportOrdersTable: React.FC<PropsT> = React.memo((props) => {
    const { orders, className, goToTransportOrderDetails, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<TransportOrderT, void>> = [
        {
            renderHeader: () => <span>{t('common:transport-orders-page.table.columns.id')}</span>,
            headerClassName: cx('table__header', 'table__header--id'),
            render: (order: TransportOrderT) => <IdCell id={order.number} />,
            className: cx('table__column', 'table__column--id'),
            testSelector: 'id',
        },
        {
            renderHeader: () => <span>{t('common:transport-orders-page.table.columns.pickup')}</span>,
            headerClassName: cx('table__header'),
            render: (order: TransportOrderT) => <LocationCell order={order} type={DirectionEnum.origin} />,
            className: cx('table__column'),
            testSelector: 'pickup',
        },
        {
            renderHeader: () => <span>{t('common:transport-orders-page.table.columns.dropoff')}</span>,
            headerClassName: cx('table__header'),
            render: (order: TransportOrderT) => <LocationCell order={order} type={DirectionEnum.destination} />,
            className: cx('table__column'),
            testSelector: 'dropoff',
        },
        // {
        //     renderHeader: () => <span>{t('common:transport-orders-page.table.columns.type')}</span>,
        //     headerClassName: cx('table__header', 'table__header--type'),
        //     render: (order: TransportOrderT) => <TypeCell order={order} />,
        //     className: cx('table__column', 'table__column--type'),
        //     testSelector: 'equipment',
        // },
        {
            renderHeader: () => <span>{t('common:transport-orders-page.table.columns.date')}</span>,
            headerClassName: cx('table__header'),
            render: (order: TransportOrderT) => <ReceivedCell order={order} />,
            className: cx('table__column', 'table__column--create'),
            testSelector: 'received',
        },
        {
            renderHeader: () => <span>{t('common:transport-orders-page.table.columns.price')}</span>,
            headerClassName: cx('table__header'),
            render: (order: TransportOrderT) => <CostCell cost={order.totalCarrierCost} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'price',
        },
        {
            renderHeader: () => <span>{t('common:transport-orders-page.table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (order: TransportOrderT) => <StatusCell order={order} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    const handleSelectRow = (event: React.MouseEvent, order: TransportOrderT) => {
        if (!order.id) {
            return;
        }

        goToTransportOrderDetails(event, order.id);
    };

    const getRowMods = (meta: TableRowMetaT, order: TransportOrderT) => {
        return {
            [TableRowModsEnum.hasLeftGreenBorder]: order.status === TransportOrderStatusEnum.done,
            [TableRowModsEnum.hasLeftRedBorder]: false,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<TransportOrderT> tableName="transportOrder" isLoading={isLoading} rows={orders}>
                {(rows, isUsedPrevRows) => (
                    <Table<TransportOrderT, void>
                        testSelector="transport-orders"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default TransportOrdersTable;
