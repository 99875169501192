import { TransportOrderDetailsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_TRANSPORT_ORDER_DETAILS_REQUEST =
    'common/transport-order-details/FETCH_TRANSPORT_ORDER_DETAILS_REQUEST';
export const FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN =
    'common/transport-order-details/FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN';
export const FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR =
    'common/transport-order-details/FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR';
export const FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS =
    'common/transport-order-details/FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS';

export type TransportOrderDetailsStateT = {
    details: TransportOrderDetailsT | null;
    requestStatus: RequestStatusT;
};

export type TransportOrderDetailsByIdStateT = Record<TransportOrderIdT, TransportOrderDetailsStateT>;

export type FetchTransportOrderDetailsRequestActionT = {
    type: typeof FETCH_TRANSPORT_ORDER_DETAILS_REQUEST;
    id: TransportOrderIdT;
    options?: FetchOptionsT;
};

export type FetchTransportOrderDetailsRequestBeginActionT = {
    type: typeof FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN;
    id: TransportOrderIdT;
};

export type FetchTransportOrderDetailsRequestErrorActionT = {
    type: typeof FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR;
    error: Error;
    id: TransportOrderIdT;
};

export type FetchTransportOrderDetailsRequestSuccessActionT = {
    type: typeof FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS;
    transportOrderDetails: TransportOrderDetailsT | null;
    id: TransportOrderIdT;
};

export type TransportOrderDetailsActionT =
    | FetchTransportOrderDetailsRequestActionT
    | FetchTransportOrderDetailsRequestBeginActionT
    | FetchTransportOrderDetailsRequestErrorActionT
    | FetchTransportOrderDetailsRequestSuccessActionT;
