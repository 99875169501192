import React from 'react';
import classNames from 'classnames/bind';

import styles from './TruckDetailsForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import TruckModelDropdown from 'common/components/dropdowns/TruckModelDropdown/TruckModelDropdown';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import { uppercaseFormatter } from 'common/utils/form-formatters';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { AssetTypeEnum, CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import ActiveContractDropdown from 'common/components/ActiveContractDropdown/ActiveContractDropdown';
import { TruckDetailsT } from 'common/store/trucks/models';
import FieldValue from 'common/components/FieldValue/FieldValue';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import GPSTrackingStatusAlert from 'common/components/GPSTrackingStatusAlert/GPSTrackingStatusAlert';
import { selectGPSIsChecking, selectGPSStatus } from 'common/store/gps-tracking-status/selectors';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';
import { selectCarrierContractById } from 'common/store/carrier-contracts/selectors';
import { fetchCarrierContracts } from 'common/store/carrier-contracts/actions';
import { usePlateNumberUniqCheck } from 'common/components/AssetSidebarContent/hooks/usePlateNumberUniqCheck';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import ChangeRateWarning from '../ChangeRateWarning/ChangeRateWarning';
import useOnceShow from 'common/utils/hooks/useOnceShow';
import { selectTrucksDictById } from 'common/store/trucks-dict/selectors';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import LinkedTrailerAlert from 'common/layouts/CommonEditableTruckDetailsLayout/LinkedTrailerAlert/LinkedTrailerAlert';
import Money from 'common/components/Money/Money';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    truckDetails: TruckDetailsT | null;
    onSubmit: (values: FormValuesT) => void;
    onCancel: () => void;
    isNewTruck: boolean;
    isLoading: boolean;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
};

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.truckModel]: null,
    [FieldsEnum.cabinColor]: '',
    [FieldsEnum.countryCode]: null,
    [FieldsEnum.plateNumber]: '',
    [FieldsEnum.vin]: '',
    [FieldsEnum.contract]: null,
    [FieldsEnum.ratePerKm]: '',
};

const TruckDetailsForm: React.FC<PropsT> = (props) => {
    const {
        partnerId,
        onSubmit,
        truckDetails,
        isNewTruck,
        isLoading,
        onCancel,
        setNeedCloseConfirmation,
        onOpenTrailerDetails,
        onOpenTruckDetails,
    } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrucksDict());
    }, []);

    React.useEffect(() => {
        return () => {
            if (setNeedCloseConfirmation) {
                setNeedCloseConfirmation(false);
            }
        };
    }, []);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const contractById = useSelector(selectCarrierContractById(partnerId));

    const [initialValues, initialErrors] = React.useMemo(() => {
        let values = {
            ...INITIAL_VALUES,
        };

        if (truckDetails) {
            values = {
                ...values,
                [FieldsEnum.truckModel]: truckDetails.dictTruckId,
                [FieldsEnum.cabinColor]: truckDetails.cabinColor || '',
                [FieldsEnum.countryCode]: truckDetails.countryCode || null,
                [FieldsEnum.plateNumber]: truckDetails.plateNumber || '',
                [FieldsEnum.vin]: truckDetails.vin || '',
                [FieldsEnum.contract]: truckDetails.contractId || null,
                [FieldsEnum.ratePerKm]: String(truckDetails.ratePerKm),
            };
        }

        const errors = validateForm(t, values);

        return [values, errors];
    }, [t, truckDetails]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onSubmit(values);

            formikHelpers.setTouched({});
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (hasAnyFieldValueChanges && setNeedCloseConfirmation) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [setNeedCloseConfirmation, hasAnyFieldValueChanges]);

    const isCheckingGPSTracking = useSelector(selectGPSIsChecking);
    const gpsStatus = useSelector(selectGPSStatus);

    const isAllowContract = gpsStatus === GPStatusEnum.enabled || !gpsStatus;
    const contractValue = formik.values[FieldsEnum.contract];

    React.useEffect(() => {
        if (!isAllowContract && contractValue !== null) {
            formik.setFieldValue(FieldsEnum.contract, null);
        }
    }, [isAllowContract, contractValue]);

    const plateNumber = formik.values[FieldsEnum.plateNumber];
    const truckPlateNumberUniqCheck = usePlateNumberUniqCheck({
        plateNumber,
        companyId: partnerId,
        assetType: AssetTypeEnum.truck,
    });

    let plateNumberAsyncError: React.ReactNode = null;

    const duplicateTruckId = truckPlateNumberUniqCheck.duplicateId;
    if (duplicateTruckId) {
        plateNumberAsyncError = (
            <Link
                onClick={() => {
                    onOpenTruckDetails(duplicateTruckId);
                }}
                theme={LinkThemeEnum.boldTomatoRed}
            >
                {t('common:trucks-page.add-truck.errors.not-uniq-plate-number', { plateNumber })}
            </Link>
        );
    }

    const hasAsyncErrors = !!plateNumberAsyncError;

    const isDisabledSubmit = isLoading || isCheckingGPSTracking;

    const selectedContractId = formik.values[FieldsEnum.contract];
    const contract = selectedContractId ? contractById[selectedContractId] : null;

    const isAllowShowChanges = !isNewTruck;

    const isShowRateWarning =
        !isNewTruck &&
        (initialValues[FieldsEnum.contract] !== formik.values[FieldsEnum.contract] ||
            initialValues[FieldsEnum.ratePerKm] !== formik.values[FieldsEnum.ratePerKm]);

    const rateWarning = useOnceShow(isShowRateWarning);

    const trucksDictById = useSelector(selectTrucksDictById);
    const selectedTruckModel = formik.values[FieldsEnum.truckModel];
    const truckModel = trucksDictById[selectedTruckModel as string];

    const hasLinkedTrailer = !!truckDetails?.linkedTrailerId;

    const isAvailableSelectContract = isAllowContract || isCheckingGPSTracking;

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            {truckDetails?.linkedTrailerId && (
                <LinkedTrailerAlert
                    className={cx('alert')}
                    trailerId={truckDetails?.linkedTrailerId}
                    plateNumber={truckDetails?.linkedTrailerPlateNumber}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                />
            )}
            <GPSTrackingStatusAlert
                className={cx('gps-tracking')}
                companyId={partnerId}
                assetType={AssetTypeEnum.truck}
                plateNumber={formik.values[FieldsEnum.plateNumber]}
            />
            {isNewTruck ? (
                <FormikField
                    name={FieldsEnum.plateNumber}
                    error={formik.errors[FieldsEnum.plateNumber]}
                    meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                    asyncError={plateNumberAsyncError}
                    label={t('common:trucks-page.add-truck.fields.plate-number.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.plateNumber}
                            value={formik.values[FieldsEnum.plateNumber]}
                            placeholder={t('common:trucks-page.add-truck.fields.plate-number.placeholder')}
                            formatter={uppercaseFormatter}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            isLoading={truckPlateNumberUniqCheck.requestStatus.loading}
                            hasChanges={isAllowShowChanges && props.hasChanges}
                            renderRightIcon={({ isLoading }) =>
                                isLoading ? (
                                    <ControlLoaderIcon
                                        fillColor={StyleGuideColorsEnum.brandAccent}
                                        size={DEFAULT_ICON_SIZE}
                                    />
                                ) : null
                            }
                        />
                    )}
                </FormikField>
            ) : (
                <FieldValue
                    label={t('common:trucks-page.add-truck.fields.plate-number.label')}
                    value={truckDetails?.plateNumber || ''}
                />
            )}
            <FormikField
                name={FieldsEnum.countryCode}
                error={formik.errors[FieldsEnum.countryCode]}
                label={t('common:trucks-page.add-truck.fields.country.label')}
                meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <CountryDropdown
                        value={formik.values[FieldsEnum.countryCode]}
                        onChange={props.onChange}
                        overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasChanges={isAllowShowChanges && props.hasChanges}
                    />
                )}
            </FormikField>
            <FormikField
                name={FieldsEnum.vin}
                error={formik.errors[FieldsEnum.vin]}
                meta={formik.getFieldMeta(FieldsEnum.vin)}
                label={t('common:trucks-page.add-truck.fields.truck-vin.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.vin}
                        value={formik.values[FieldsEnum.vin]}
                        placeholder={t('common:trucks-page.add-truck.fields.truck-vin.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        hasChanges={isAllowShowChanges && props.hasChanges}
                    />
                )}
            </FormikField>
            <FormikField
                name={FieldsEnum.truckModel}
                error={formik.errors[FieldsEnum.truckModel]}
                meta={formik.getFieldMeta(FieldsEnum.truckModel)}
                label={t('common:trucks-page.add-truck.fields.truck-model.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <TruckModelDropdown
                        placeholder={t('common:trucks-page.add-truck.fields.truck-model.placeholder')}
                        value={formik.values[FieldsEnum.truckModel]}
                        onChange={props.onChange}
                        hasWarning={props.hasWarning}
                        hasError={props.hasError}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasChanges={isAllowShowChanges && props.hasChanges}
                    />
                )}
            </FormikField>
            <FieldGroup>
                <FieldValue
                    className={cx('field--emission-standard')}
                    label={t('common:trucks-page.add-truck.fields.emission-standard.label')}
                    placeholder={t('common:trucks-page.add-truck.fields.emission-standard.placeholder')}
                    value={truckModel?.emissionClass || ''}
                />
                <FormikField
                    className={cx('field--cabin-color')}
                    name={FieldsEnum.cabinColor}
                    error={formik.errors[FieldsEnum.cabinColor]}
                    meta={formik.getFieldMeta(FieldsEnum.cabinColor)}
                    label={t('common:trucks-page.add-truck.fields.cabin-color.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.cabinColor}
                            value={formik.values[FieldsEnum.cabinColor]}
                            placeholder={t('common:trucks-page.add-truck.fields.cabin-color.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasChanges={isAllowShowChanges && props.hasChanges}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                {isAvailableSelectContract ? (
                    <FormikField
                        className={cx('field--contract')}
                        name={FieldsEnum.contract}
                        error={formik.errors[FieldsEnum.contract]}
                        meta={formik.getFieldMeta(FieldsEnum.contract)}
                        label={t('common:trucks-page.add-truck.fields.contract.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <ActiveContractDropdown
                                hasNoneOption
                                value={formik.values[FieldsEnum.contract]}
                                isDisabled={false}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                companyId={partnerId}
                                hasChanges={isAllowShowChanges && props.hasChanges}
                            />
                        )}
                    </FormikField>
                ) : (
                    <FieldValue
                        className={cx('field--contract')}
                        label={t('common:trucks-page.add-truck.fields.contract.label')}
                        value={t('common:dedicated.none')}
                    />
                )}
                {formik.values[FieldsEnum.contract] !== null && (
                    <FieldValue
                        className={cx('field--rate')}
                        label={t('common:trucks-page.add-truck.fields.rate.label')}
                        value={<Money amount={contract?.truckRateKm} currency={CurrencyEnum.EUR} />}
                    />
                )}
                {formik.values[FieldsEnum.contract] === null && (
                    <FormikField
                        className={cx('field--rate')}
                        name={FieldsEnum.ratePerKm}
                        error={formik.errors[FieldsEnum.ratePerKm]}
                        meta={formik.getFieldMeta(FieldsEnum.ratePerKm)}
                        label={t('common:trucks-page.add-truck.fields.rate.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <NumberInput
                                name={FieldsEnum.ratePerKm}
                                unitType={UnitTypeEnum.euroAbbreviation}
                                placeholder={t('common:trucks-page.add-truck.fields.rate.placeholder')}
                                value={formik.values[FieldsEnum.ratePerKm]}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasChanges={isAllowShowChanges && props.hasChanges}
                                step={1}
                            />
                        )}
                    </FormikField>
                )}
            </FieldGroup>
            {rateWarning.isShow ? <ChangeRateWarning onClose={rateWarning.close} /> : null}
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout hasPaddings>
                {isNewTruck && (
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={isDisabledSubmit}
                        className={cx('button')}
                        isDisabled={hasAsyncErrors}
                        type="submit"
                    >
                        {t('common:trucks-page.add-truck.submit')}
                    </Button>
                )}
                {!isNewTruck && (
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('actions__action', 'actions__action--cancel')}
                            onClick={() => {
                                formik.resetForm();
                                onCancel();
                            }}
                        >
                            {t('common:trucks-page.actions.discard')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            isLoading={isDisabledSubmit}
                            isDisabled={hasAsyncErrors}
                            className={cx('actions__action', 'actions__action--delete')}
                            type="submit"
                        >
                            {t('common:trucks-page.actions.save')}
                        </Button>
                    </div>
                )}
            </FooterSideBarLayout>
        </form>
    );
};

export default TruckDetailsForm;
