import React from 'react';
import { Link as RRDLink, LinkProps } from 'react-router-dom';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Link.scss';

const cx = classNames.bind(styles);

type LinkToObjT = LinkProps<HistoryStateT>['to'];

export type LinkToT = string | LinkToObjT;

export type LinkPropsT = {
    className?: string;
    testSelector?: string;
    theme: LinkThemeEnum;
    size?: LinkSizeEnum;
    isExternal?: boolean;
    to?: LinkToT;
    target?: string;
    onClick?: () => void;
};

export enum LinkThemeEnum {
    charcoal = 'charcoal',
    boldCharcoal = 'bold-charcoal',
    boldAzul = 'bold-azul',
    boldBrandDark = 'bold-brand-dark',
    boldWhite = 'bold-white',
    boldTomatoRed = 'bold-tomato-red',
    boldBrandAccent = 'bold-brand-accent',
}

export enum LinkSizeEnum {
    large = 'large',
    medium = 'medium',
    small = 'small',
}

const Link: React.FC<LinkPropsT> = React.memo((props) => {
    const { testSelector, theme, className, isExternal, to, children, onClick, size, ...rest } = props;

    const linkProps = {
        ...rest,
        className: cs(
            className,
            cx('link', {
                [`link--theme-${theme}`]: !!theme,
                [`link--size-${size}`]: !!size,
            }),
        ),
        'data-test-selector': `${testSelector}_link`,
    };

    if (!to && onClick) {
        return (
            <span {...linkProps} onClick={onClick}>
                {children}
            </span>
        );
    }

    if (isExternal) {
        return (
            <a {...linkProps} href={to as string}>
                {children}
            </a>
        );
    }

    return (
        <RRDLink {...linkProps} to={to as LinkToT}>
            {children}
        </RRDLink>
    );
});

export default Link;
