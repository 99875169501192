import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './AuthLayoutMessage.scss';

import BigDoneIcon from 'common/icons/BigDoneIcon';

const cx = classNames.bind(styles);

export enum AuthMessageIconsEnum {
    success = 'success',
}

type PropsT = {
    iconType: AuthMessageIconsEnum;
    title: string;
    description?: string;
    testSelector?: string;
};

const AuthLayoutMessage: React.FC<PropsT> = React.memo((props) => {
    const { iconType, title, description, testSelector } = props;

    return (
        <div className={cx('container')} data-test-selector={testSelector}>
            <div className={cx('icon')}>{iconType === AuthMessageIconsEnum.success && <BigDoneIcon />}</div>
            <div className={cx('title')}>{title}</div>
            <div className={cx('description')}>{description}</div>
        </div>
    );
});

export default AuthLayoutMessage;
