import React from 'react';

import isEqual from 'lodash/isEqual';
import classNames from 'classnames/bind';
import cs from 'classnames';
import styles from './RangeSelector.scss';
import Zone from './Zone/Zone';
import ZoneText from './ZoneText/ZoneText';
import Steps from './Steps/Steps';
import Trigger from './Trigger/Trigger';
import { RangeSelectorZoneThemeEnum } from './constants';
import DnDIconWrap from './DnDIconWrap/DnDIconWrap';
import min from 'lodash/min';
import max from 'lodash/max';
import isNumber from 'lodash/isNumber';
import { checkValuesIsAllowed } from './utils';

const cx = classNames.bind(styles);

export type RangeSelectorRangeT = {
    id: string;
    values: number[];
    theme: RangeSelectorZoneThemeEnum | null;
    label?: string;
    labelTheme?: RangeSelectorZoneThemeEnum | null;
};

export type RangeSelectorLabelsConfigT = {
    shownLabelCount: number;
    shownStepCount: number;
    hasStartStep?: boolean;
    hasEndStep?: boolean;
};

export type PropsT = {
    className?: string;
    minValuesRangeWidth?: number;
    maxValuesRangeWidth?: number;
    valuesRange: number[];
    valueStep: number;
    values: number[] | null;
    availableValues: number[];

    ranges: Array<RangeSelectorRangeT>;
    isDisabled?: boolean;
    isLocked?: boolean;
    isLockedLeftRangeControl?: boolean;
    isLockedRightRangeControl?: boolean;
    renderLabel: (value: number) => string | number;
    onSelect: (selectedRange: [number, number]) => void;
    labelsConfig: RangeSelectorLabelsConfigT;
};

const checkCursorOnTrigger = (triggerRef: React.RefObject<HTMLDivElement>, event: MouseEvent) => {
    if (!triggerRef?.current) {
        return false;
    }

    const bounds = triggerRef?.current.getBoundingClientRect();

    return (
        bounds.left <= event.clientX &&
        event.clientX <= bounds.right &&
        bounds.top <= event.clientY &&
        event.clientY <= bounds.bottom
    );
};

const POSITIONS_RANGE: [number, number] = [0, 100];
const POSITIONS_RANGE_WIDTH = POSITIONS_RANGE[1] - POSITIONS_RANGE[0];

const checkIsSamePositions = (positionA: number, positionB: number): boolean => {
    return positionA.toFixed(2) === positionB.toFixed(2);
};

type CheckIsAllowedRangeOptionsT = {
    minRangePositions: number | null;
    maxRangePositions: number | null;
};
const checkIsAllowedRange = (diff: number, options: CheckIsAllowedRangeOptionsT): boolean => {
    const { minRangePositions, maxRangePositions } = options;

    if (isNumber(minRangePositions) && diff <= minRangePositions) {
        return false;
    }
    if (isNumber(maxRangePositions) && diff >= maxRangePositions) {
        return false;
    }

    return true;
};

type DraggingLimitPositionsT = {
    min: number;
    max: number;
};

const isNumberLimit = (limit: number | null | undefined): boolean => !Number.isNaN(limit);

const RangeSelector: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        valuesRange,
        valueStep,
        values,
        renderLabel,
        onSelect,
        isLockedLeftRangeControl,
        isLockedRightRangeControl,
        availableValues,
        ranges,
        minValuesRangeWidth,
        maxValuesRangeWidth,
        labelsConfig,
    } = props;

    const isLocked = props.isLocked || props.isDisabled;
    const isAllowDragSelectedZone = !isLockedLeftRangeControl && !isLockedRightRangeControl;

    const prevPositionXRef = React.useRef<number>(0);

    const [isHovered, setHovered] = React.useState<boolean>(false);

    const valuesRangeWidth = valuesRange[1] - valuesRange[0];
    const stepsCount = valuesRangeWidth / valueStep;
    const positionStepWidth = POSITIONS_RANGE_WIDTH / stepsCount;

    const getPosition = React.useCallback(
        (value: number): number => {
            const relativeValue = value - valuesRange[0];
            return (relativeValue / valuesRangeWidth) * POSITIONS_RANGE_WIDTH;
        },
        [valuesRange, valuesRangeWidth, POSITIONS_RANGE_WIDTH],
    );

    const availablePositions = React.useMemo(() => {
        return availableValues.map(getPosition);
    }, [availableValues, getPosition]);

    const rangesPositions = React.useMemo(() => {
        return ranges.map((range) => range.values.map(getPosition));
    }, [ranges, getPosition]);

    const selectionRangeRef = React.useRef<HTMLDivElement>(null);

    const draggingLeftPositionRef = React.useRef<DraggingLimitPositionsT | null>(null);
    const draggingRightPositionRef = React.useRef<DraggingLimitPositionsT | null>(null);

    // dragging left & right cursor
    const isDraggingRef = React.useRef<boolean>(false);

    // dragging left cursor
    const leftTriggerRef = React.useRef<HTMLDivElement>(null);
    const isDraggingLeftRef = React.useRef<boolean>(false);
    const initialLeftPosition = React.useMemo<number>(() => {
        if (typeof values?.[0] === 'number') {
            return getPosition(Math.max(values[0], availableValues[0]));
        }

        return availableValues[0];
    }, []);
    const leftPositionRef = React.useRef<number>(initialLeftPosition);

    // dragging right cursor
    const rightTriggerRef = React.useRef<HTMLDivElement>(null);
    const isDraggingRightRef = React.useRef<boolean>(false);
    const initialRightPosition = React.useMemo<number>(() => {
        if (typeof values?.[1] === 'number') {
            return getPosition(Math.max(values[1], availableValues[1]));
        }

        return availableValues[1];
    }, []);
    const rightPositionRef = React.useRef<number>(initialRightPosition);

    const [currentRangeIndex, setCurrentRangeIndex] = React.useState<number>(-1);

    React.useEffect(() => {
        const leftPosition = leftPositionRef.current;

        const newCurrentRangeIndex = rangesPositions.findIndex((rangePosition) => {
            return rangePosition[0] <= leftPosition && leftPosition < rangePosition[1];
        });

        setCurrentRangeIndex(newCurrentRangeIndex);
    }, [rangesPositions]);

    const updateLeftPosition = React.useCallback(
        (newLeftPosition: number) => {
            const newCurrentRangeIndex = rangesPositions.findIndex((rangePosition) => {
                return rangePosition[0] <= newLeftPosition && newLeftPosition < rangePosition[1];
            });

            setCurrentRangeIndex(newCurrentRangeIndex);

            if (selectionRangeRef.current) {
                selectionRangeRef.current.style.left = `${newLeftPosition}%`;
            }
            leftPositionRef.current = newLeftPosition;
        },
        [rangesPositions],
    );

    const updateRightPosition = React.useCallback((newRightPosition: number) => {
        if (selectionRangeRef.current) {
            selectionRangeRef.current.style.right = `${POSITIONS_RANGE_WIDTH - newRightPosition}%`;
        }
        rightPositionRef.current = newRightPosition;
    }, []);

    const minRangePositions = minValuesRangeWidth
        ? (minValuesRangeWidth / valuesRangeWidth) * POSITIONS_RANGE_WIDTH
        : null;

    const maxRangePositions = maxValuesRangeWidth
        ? (maxValuesRangeWidth / valuesRangeWidth) * POSITIONS_RANGE_WIDTH
        : null;

    React.useEffect(() => {
        if (!values) {
            return;
        }

        const [newLeftPosition, newRightPosition] = values.map(getPosition);
        updateLeftPosition(newLeftPosition);
        updateRightPosition(newRightPosition);
    }, [values, minRangePositions, maxRangePositions]);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = React.useState<DivSizeT>({
        width: 0,
        height: 0,
    });

    React.useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        setContainerSize({
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
        });
    }, []);

    const handleMouseMove = React.useCallback(
        (event: MouseEvent) => {
            if (!isDraggingRef.current && !isDraggingLeftRef.current && !isDraggingRightRef.current) {
                return;
            }

            if (isLocked) {
                return;
            }

            const positionX = event.clientX;
            const diff = positionX - prevPositionXRef.current;
            if (Number.isNaN(diff)) {
                return;
            }

            prevPositionXRef.current = positionX;

            const leftPosition = leftPositionRef.current;
            if (isDraggingRef.current || isDraggingLeftRef.current) {
                const newLeftPosition = leftPosition + (diff / containerSize.width) * POSITIONS_RANGE_WIDTH;

                const isAllowedNewLeftPosition =
                    (typeof draggingLeftPositionRef.current?.min === 'number'
                        ? newLeftPosition >= draggingLeftPositionRef.current.min
                        : true) &&
                    (typeof draggingLeftPositionRef.current?.max === 'number'
                        ? newLeftPosition <= draggingLeftPositionRef.current.max
                        : true);

                if (isAllowedNewLeftPosition) {
                    updateLeftPosition(newLeftPosition);
                }
            }

            const rightPosition = rightPositionRef.current;
            if (isDraggingRef.current || isDraggingRightRef.current) {
                const newRightPosition = rightPosition + (diff / containerSize.width) * POSITIONS_RANGE_WIDTH;

                const isAllowedNewRightPosition =
                    (typeof draggingRightPositionRef.current?.min === 'number'
                        ? newRightPosition >= draggingRightPositionRef.current.min
                        : true) &&
                    (typeof draggingRightPositionRef.current?.max === 'number'
                        ? newRightPosition <= draggingRightPositionRef.current.max
                        : true);

                if (isAllowedNewRightPosition) {
                    updateRightPosition(newRightPosition);
                }
            }
        },
        [isLocked, containerSize, POSITIONS_RANGE_WIDTH, updateLeftPosition, updateRightPosition],
    );

    const handleMouseDown = React.useCallback(
        (event: MouseEvent) => {
            if (isLocked) {
                return;
            }

            const isLeftTriggerClick = checkCursorOnTrigger(leftTriggerRef, event);
            const isRightTriggerClick = checkCursorOnTrigger(rightTriggerRef, event);
            const isRangeClick = checkCursorOnTrigger(selectionRangeRef, event);
            if (!isLeftTriggerClick && !isRightTriggerClick && !isRangeClick) {
                return;
            }

            const leftPosition = leftPositionRef.current;
            const rightPosition = rightPositionRef.current;

            const positionX = event.clientX;
            if (isNumber(positionX)) {
                prevPositionXRef.current = positionX;
            }

            if (isLeftTriggerClick) {
                isDraggingLeftRef.current = true;

                draggingLeftPositionRef.current = {
                    min: max(
                        [
                            maxRangePositions ? rightPosition - maxRangePositions : null,
                            availablePositions[0] || POSITIONS_RANGE[0],
                        ].filter(isNumberLimit),
                    ) as number,
                    max: min(
                        [
                            minRangePositions ? rightPosition - minRangePositions : null,
                            availablePositions[1] || POSITIONS_RANGE[1],
                        ].filter(isNumberLimit),
                    ) as number,
                };
            }

            if (isRightTriggerClick) {
                isDraggingRightRef.current = true;
                draggingRightPositionRef.current = {
                    min: max(
                        [
                            minRangePositions ? leftPosition + minRangePositions : null,
                            availablePositions[0] || POSITIONS_RANGE[0],
                        ].filter(isNumberLimit),
                    ) as number,
                    max: min(
                        [
                            maxRangePositions ? leftPosition + maxRangePositions : null,
                            availablePositions[1] || POSITIONS_RANGE[1],
                        ].filter(isNumberLimit),
                    ) as number,
                };
            }

            if (!isLeftTriggerClick && !isRightTriggerClick && isRangeClick && isAllowDragSelectedZone) {
                isDraggingRef.current = true;

                draggingLeftPositionRef.current = {
                    min: availablePositions[0] || POSITIONS_RANGE[0],
                    max: (availablePositions[1] || POSITIONS_RANGE[1]) - (minRangePositions || 0),
                };

                draggingRightPositionRef.current = {
                    min: (availablePositions[0] || POSITIONS_RANGE[0]) + (minRangePositions || 0),
                    max: availablePositions[1] || POSITIONS_RANGE[1],
                };
            }
        },
        [isLocked, isAllowDragSelectedZone, availablePositions, POSITIONS_RANGE, positionStepWidth, minRangePositions],
    );

    const handleMouseUp = React.useCallback(() => {
        if (isLocked) {
            return;
        }

        if (!isDraggingRightRef.current && !isDraggingLeftRef.current && !isDraggingRef.current) {
            return;
        }

        const leftPosition = leftPositionRef.current;
        const rightPosition = rightPositionRef.current;

        const currentLeftStep = Math.round(leftPosition / positionStepWidth);
        const newLeftPosition = Math.max(getPosition(availableValues[0]), currentLeftStep * positionStepWidth);
        if (!checkIsSamePositions(leftPosition, newLeftPosition) && selectionRangeRef.current) {
            updateLeftPosition(newLeftPosition);
        }

        const currentRightStep = Math.round(rightPosition / positionStepWidth);
        const newRightPosition = Math.min(getPosition(availableValues[1]), currentRightStep * positionStepWidth);
        if (!checkIsSamePositions(rightPosition, newRightPosition) && selectionRangeRef.current) {
            updateRightPosition(newRightPosition);
        }

        const [minValue] = valuesRange;
        const selectedRange: [number, number] = [
            minValue + currentLeftStep * valueStep,
            minValue + currentRightStep * valueStep,
        ];

        if (selectedRange[1] - selectedRange[0] < valueStep) {
            if (isDraggingLeftRef.current) {
                selectedRange[0] -= valueStep;
            } else {
                selectedRange[1] += valueStep;
            }
        }

        const isSameValue = isEqual(selectedRange, values);
        if (!isSameValue) {
            onSelect(selectedRange);
        }

        isDraggingLeftRef.current = false;
        isDraggingRightRef.current = false;
        isDraggingRef.current = false;
    }, [
        isLocked,
        positionStepWidth,
        availableValues,
        getPosition,
        valuesRange,
        valueStep,
        updateLeftPosition,
        updateRightPosition,
    ]);

    const handleMouseLeave = React.useCallback(() => {
        if (isLocked) {
            return;
        }

        handleMouseUp();
    }, [isLocked, handleMouseUp]);

    const currentRange = ranges[currentRangeIndex] || null;

    const currentRangeTheme = currentRange?.theme || RangeSelectorZoneThemeEnum.green;

    const handleTriggerMouseLeave = React.useCallback(() => {
        if (isLocked) {
            return;
        }

        if (isHovered) {
            setHovered(false);
        }
    }, [isLocked, isHovered, setHovered]);

    const handleTriggerMouseEnter = React.useCallback(() => {
        if (isLocked) {
            return;
        }

        if (!isHovered) {
            setHovered(true);
        }
    }, [isLocked, isHovered, setHovered]);

    const leftDisableRange = React.useMemo(() => {
        return [POSITIONS_RANGE[0], availablePositions[0]];
    }, [POSITIONS_RANGE, availablePositions]);
    const isEmptyLeftDisableRange = leftDisableRange[0] === leftDisableRange[1];

    const rightDisableRange = React.useMemo(() => {
        return [availablePositions[1], POSITIONS_RANGE[1]];
    }, [POSITIONS_RANGE, availablePositions]);
    const isEmptyRightDisableRange = rightDisableRange[0] === rightDisableRange[1];

    const filledPositions = React.useMemo(() => {
        const leftRanges = rangesPositions.map((position) => position[0]);
        const rightRanges = rangesPositions.map((position) => position[1]);

        if (!isEmptyLeftDisableRange) {
            leftRanges.push(leftDisableRange[0]);
            rightRanges.push(leftDisableRange[1]);
        }

        if (!isEmptyRightDisableRange) {
            leftRanges.push(rightDisableRange[0]);
            rightRanges.push(rightDisableRange[1]);
        }

        return [min(leftRanges) || 0, max(rightRanges) || 0];
    }, [rangesPositions, leftDisableRange, rightDisableRange]);

    const isAllowedSelectedValues = checkValuesIsAllowed(values, {
        availableValues,
        minValuesRangeWidth,
        maxValuesRangeWidth,
    });

    React.useEffect(() => {
        if (!isAllowedSelectedValues) {
            return () => {
                // nothing
            };
        }

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [isAllowedSelectedValues, handleMouseMove, handleMouseUp, handleMouseDown, handleMouseLeave]);

    const isDragging = isDraggingRef.current || isDraggingRightRef.current || isDraggingLeftRef.current;
    return (
        <div
            className={cs(
                cx('container', {
                    'container--isDisabled': props.isDisabled,
                }),
                className,
            )}
            ref={containerRef}
            data-test-selector="range-selector"
        >
            <Zone
                key="all-range"
                theme={RangeSelectorZoneThemeEnum.light}
                left={POSITIONS_RANGE[0]}
                width={POSITIONS_RANGE_WIDTH}
                hasLeftBorder
                hasRightBorder
            />
            {!isEmptyLeftDisableRange && (
                <Zone
                    isDisabled
                    hasLeftBorder
                    left={leftDisableRange[0]}
                    width={leftDisableRange[1] - leftDisableRange[0]}
                />
            )}
            {!isEmptyRightDisableRange && (
                <Zone
                    isDisabled
                    hasRightBorder
                    left={rightDisableRange[0]}
                    width={rightDisableRange[1] - rightDisableRange[0]}
                />
            )}
            {ranges.map((range, rangeIndex) => {
                const positions = rangesPositions[rangeIndex];

                return (
                    <Zone
                        key={rangeIndex}
                        theme={range.theme}
                        hasLeftBorder={positions[0] === POSITIONS_RANGE[0]}
                        hasRightBorder={positions[1] === POSITIONS_RANGE[1]}
                        left={positions[0]}
                        width={positions[1] - positions[0]}
                    />
                );
            })}
            <Steps
                minValue={valuesRange[0]}
                stepsCount={stepsCount}
                step={valueStep}
                shownLabelCount={labelsConfig.shownLabelCount}
                shownStepCount={labelsConfig.shownStepCount}
                hasStartStep={labelsConfig.hasStartStep}
                hasEndStep={labelsConfig.hasEndStep}
                renderLabel={renderLabel}
                filledPositions={filledPositions}
            />
            {values && (
                <div
                    className={cx('selection-range', {
                        'selection-range--isDragable': isAllowDragSelectedZone && !isLocked,
                        'selection-range--isDragging': isDragging,
                        'selection-range--isLocked': isLocked,
                        'selection-range--isOutOffValues': !isAllowedSelectedValues,
                    })}
                    onMouseEnter={handleTriggerMouseEnter}
                    onMouseLeave={handleTriggerMouseLeave}
                    ref={selectionRangeRef}
                >
                    <Zone
                        isSelected
                        isHovered={isHovered}
                        theme={currentRangeTheme}
                        left={POSITIONS_RANGE[0]}
                        width={POSITIONS_RANGE_WIDTH}
                    />
                    {currentRange && (
                        <ZoneText
                            containerRef={containerRef}
                            selectionRangeRef={selectionRangeRef}
                            theme={currentRangeTheme}
                            text={currentRange.label || ''}
                            values={values}
                        />
                    )}
                    {isAllowDragSelectedZone && <DnDIconWrap values={values} />}
                    {!isLockedLeftRangeControl && (
                        <Trigger isLeft isDisabled={isLocked} divRef={leftTriggerRef} theme={currentRangeTheme} />
                    )}
                    {!isLockedRightRangeControl && (
                        <Trigger isRight isDisabled={isLocked} divRef={rightTriggerRef} theme={currentRangeTheme} />
                    )}
                </div>
            )}
        </div>
    );
});

export default RangeSelector;
