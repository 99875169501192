import React from 'react';
import classNames from 'classnames/bind';

import styles from './SpotRequestsFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import Input from 'common/components/Input/Input';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { useDispatch } from 'react-redux';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import PinIcon, { PinIconProps } from 'common/icons/PinIcon';
import SelectedFilters from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/SelectedFilters/SelectedFilters';
import { QueryFiltersT, QueryKeysEnum } from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/query-models';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { useQueryParam } from 'use-query-params';
import { SpotRequestsFiltersSidebarDataT } from './models';
import { createJsonParams } from 'common/utils/query';
import { CarrierSidebarDataT } from 'carrier/layouts/SideBars/models';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<SpotRequestsFiltersSidebarDataT, CarrierSidebarDataT>;

const SpotRequestsFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.spotRequestsFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);

    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);
            setQueryFilters({
                ...initialQueryFilters,
                ...queryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const hasAddressValue = !!formik.values[FieldsEnum.address];

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('spot-requests.list.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--trailer-type')}
                        name={FieldsEnum.dictTrailerTypeId}
                        error={formik.errors[FieldsEnum.dictTrailerTypeId]}
                        meta={formik.getFieldMeta(FieldsEnum.dictTrailerTypeId)}
                        label={t('spot-requests.list.filters.fields.trailer-type')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DictTrailerDropdown
                                placeholder={t('spot-requests.list.filters.placeholders.trailer-type')}
                                value={formik.values[FieldsEnum.dictTrailerTypeId]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('spot-requests.list.filters.fields.created-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('spot-requests.list.filters.placeholders.created-dates')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--address')}
                        name={FieldsEnum.address}
                        error={formik.errors[FieldsEnum.address]}
                        meta={formik.getFieldMeta(FieldsEnum.address)}
                        label={t('spot-requests.list.filters.fields.address')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        tooltipPosition={TooltipPositionEnum.topLeft}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                {t('spot-requests.list.filters.tooltip.address')}
                            </TooltipContent>
                        }
                    >
                        {(props) => (
                            <Input
                                renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                                name={FieldsEnum.address}
                                value={formik.values[FieldsEnum.address] || ''}
                                placeholder={t('spot-requests.list.filters.placeholders.address')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.pickupDates}
                        error={formik.errors[FieldsEnum.pickupDates]}
                        meta={formik.getFieldMeta(FieldsEnum.pickupDates)}
                        label={t('spot-requests.list.filters.fields.pickup-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.pickupDates]}
                                placeholder={t('spot-requests.list.filters.placeholders.pickup-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.dropoffDates}
                        error={formik.errors[FieldsEnum.dropoffDates]}
                        meta={formik.getFieldMeta(FieldsEnum.dropoffDates)}
                        label={t('spot-requests.list.filters.fields.drop-off-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.dropoffDates]}
                                placeholder={t('spot-requests.list.filters.placeholders.drop-off-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('spot-requests.list.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default SpotRequestsFiltersSidebarContent;
