import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './UserSignUpForm.scss';

import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { authRoutesEnum, AUTO_COMPLETE_OFF_FIX } from 'common/constants';
import { useTranslation } from 'react-i18next';

import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';

const cx = classNames.bind(styles);

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.firstName]: '',
    [FieldsEnum.lastName]: '',
    [FieldsEnum.email]: '',
    [FieldsEnum.phone]: '',
    [FieldsEnum.password]: '',
    [FieldsEnum.acceptTerms]: true,
};

type PropsT = {
    loading?: boolean;
    onSubmit: (formValues: FormValuesT) => void;
};

const UserSignUpForm: React.FC<PropsT> = React.memo((props) => {
    const { loading, onSubmit } = props;
    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const initialErrors = React.useMemo(() => {
        return validateForm(t, INITIAL_VALUES);
    }, [t, INITIAL_VALUES]);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues: INITIAL_VALUES,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onSubmit(values);

            formikHelpers.setTouched({});
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('fields')}>
                <FormikField
                    name={FieldsEnum.firstName}
                    error={formik.errors[FieldsEnum.firstName]}
                    meta={formik.getFieldMeta(FieldsEnum.firstName)}
                    label={t('common:sign-up.fields.firstName.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.firstName}
                            value={formik.values[FieldsEnum.firstName]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.lastName}
                    error={formik.errors[FieldsEnum.lastName]}
                    meta={formik.getFieldMeta(FieldsEnum.lastName)}
                    label={t('common:sign-up.fields.lastName.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.lastName}
                            value={formik.values[FieldsEnum.lastName]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.email}
                    error={formik.errors[FieldsEnum.email]}
                    meta={formik.getFieldMeta(FieldsEnum.email)}
                    label={t('common:sign-up.fields.email.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.email}
                            value={formik.values[FieldsEnum.email]}
                            placeholder={t('common:sign-up.fields.email.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.phone}
                    error={formik.errors[FieldsEnum.phone]}
                    meta={formik.getFieldMeta(FieldsEnum.phone)}
                    label={t('common:sign-up.fields.phone.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <PhoneNumberInput
                            name={FieldsEnum.phone}
                            value={formik.values[FieldsEnum.phone]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    name={FieldsEnum.password}
                    error={formik.errors[FieldsEnum.password]}
                    meta={formik.getFieldMeta(FieldsEnum.password)}
                    label={t('common:sign-up.fields.password.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            type="password"
                            name={FieldsEnum.password}
                            value={formik.values[FieldsEnum.password]}
                            placeholder={t('common:sign-up.fields.password.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            autoComplete={AUTO_COMPLETE_OFF_FIX}
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('checkbox')}
                    name={FieldsEnum.acceptTerms}
                    error={formik.errors[FieldsEnum.acceptTerms]}
                    meta={formik.getFieldMeta(FieldsEnum.acceptTerms)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    isCheckbox
                >
                    {(props) => (
                        <Checkbox
                            checked={formik.values[FieldsEnum.acceptTerms]}
                            onChange={props.onChange}
                            label={
                                <span className={cx('terms')}>
                                    {t('common:sign-up.user-form.terms.read')}{' '}
                                    <Link
                                        isExternal
                                        theme={LinkThemeEnum.boldCharcoal}
                                        to={t('common:sign-up.user-form.terms.link')}
                                        target="blank"
                                    >
                                        {t('common:sign-up.user-form.terms.link-text')}
                                    </Link>
                                </span>
                            }
                        />
                    )}
                </FormikField>
            </div>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <Button isLoading={loading} theme={ButtonThemeEnum.primary} type="submit" className={cx('submit')}>
                {t('common:sign-up.user-form.submit')}
            </Button>
            <div className={cx('footer-links')}>
                {t('common:sign-up.user-form.footer.have-account')}
                <span className={cx('footer-links__link')}>
                    <Link
                        theme={LinkThemeEnum.boldAzul}
                        to={{
                            pathname: authRoutesEnum.signIn,
                        }}
                    >
                        {t('common:sign-up.user-form.footer.sign-in')}
                    </Link>
                </span>
            </div>
        </form>
    );
});

export default UserSignUpForm;
