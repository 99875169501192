import * as React from 'react';
import { ApiDriverStatusT, DriverStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status?: ApiDriverStatusT | null | undefined;
};

export const DRIVER_STATUS_T_MAP: Record<ApiDriverStatusT, string> = {
    [DriverStatusEnum.free]: 'common:driver-status.FREE',
    [DriverStatusEnum.pending]: 'common:driver-status.PENDING',
    [DriverStatusEnum.notAvailable]: 'common:driver-status.NOT_AVAILABLE',
    [DriverStatusEnum.archived]: 'common:driver-status.ARCHIVED',
    [DriverStatusEnum.inTransit]: 'common:driver-status.IN_TRANSIT',
};

const DriverStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(DRIVER_STATUS_T_MAP[status]);
});

export default DriverStatus;
