export enum FieldsEnum {
    dictTrailerTypeId = 'dictTrailerTypeId',

    createDates = 'createDates',

    address = 'address',

    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',
}

export type FormValuesT = {
    [FieldsEnum.createDates]: DateRangeT | null;

    [FieldsEnum.dictTrailerTypeId]: string | null;

    [FieldsEnum.address]: string | null;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;
};
