import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './FieldLabel.scss';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    label?: React.ReactNode;
    subLabel?: React.ReactNode;
    tooltipNode?: React.ReactNode;
    tooltipPosition?: TooltipPositionEnum;
    tooltipClassName?: string;
    rightNode?: React.ReactNode;
};

const SHOW_TOOLTIP_DELAY = 300;

const FieldLabel: React.FC<PropsT> = React.memo((props) => {
    const { className, label, subLabel, tooltipNode, tooltipPosition, tooltipClassName, rightNode } = props;

    return (
        <div className={cs(cx('label'), className)}>
            <div className={cx('left-node')}>
                <span className={cx('text')}>{label || ' '}</span>
                {tooltipNode && (
                    <Tooltip
                        className={cx('tooltip')}
                        position={tooltipPosition || TooltipPositionEnum.topCenter}
                        theme={TooltipThemeEnum.black}
                        tooltipNode={<div className={cs(cx('tooltip__inner'), tooltipClassName)}>{tooltipNode}</div>}
                        delay={SHOW_TOOLTIP_DELAY}
                    >
                        {(isShow) => <TooltipIconTrigger className={cx('tooltip__icon')} isShow={isShow} />}
                    </Tooltip>
                )}
                {subLabel}
            </div>
            <div className={cx('right-node')}>{rightNode}</div>
        </div>
    );
});

export default FieldLabel;
