/* eslint-disable max-len */
import React from 'react';
import { useLocation, Link as RRDLink, LinkProps as RRDLinkProps } from 'react-router-dom';
import { matchPath } from 'react-router';
import cs from 'classnames';
import classNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Logo from './Logo/Logo';
import Avatar, { AvatarSizeEnum } from 'common/components/Avatar/Avatar';

import styles from './Navigation.scss';
import UiContext from 'common/contexts/ui-context';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import useWindowSize from 'common/utils/hooks/useWindowSize';
import { getCurrentYear } from 'common/utils/time';
import HelpIcon from 'common/icons/HelpIcon';

const cx = classNames.bind(styles);

const BREAKING_POINT_WIDTH = 1470; // px
const TOOLTIP_DELAY = 500;

type LinkPropsT = RRDLinkProps<unknown> & {
    testSelector?: string;
};

const Link: React.FC<LinkPropsT> = React.memo((props) => {
    const { testSelector, ...rest } = props;

    return <RRDLink {...rest} data-test-selector={`${testSelector}_link`} />;
});

type MenuAvatarPropsT = {
    accountLink: string;
    isExpanded: boolean;
    userEmail: string;
    userName: string;
    companyName: string;
    location: ReturnType<typeof useLocation>;
};

const MenuAvatar: React.FC<MenuAvatarPropsT> = React.memo((props) => {
    const { accountLink, isExpanded, userEmail, userName, companyName, location } = props;

    const [isHovered, setHover] = React.useState<boolean>(false);
    const handleFocus = () => {
        if (isHovered) {
            return;
        }

        setHover(true);
    };
    const handleBlur = () => {
        if (!isHovered) {
            return;
        }

        setHover(false);
    };
    const hasAccountLinkMatch = matchPath(location?.pathname, {
        path: accountLink,
        exact: false,
        strict: false,
    })?.url;

    const avatarNode = (
        <Avatar
            className={cx('avatar__icon', {
                'avatar__icon--isHovered': isHovered,
                'avatar__icon--hasLinkMatch': hasAccountLinkMatch,
            })}
            hash={userEmail}
            size={AvatarSizeEnum.large}
        />
    );

    return (
        <Link
            className={cx('avatar', {
                'avatar--isExpanded': isExpanded,
            })}
            to={accountLink}
            testSelector="account-menu"
            onMouseMove={handleFocus}
            onMouseLeave={handleBlur}
        >
            {isExpanded ? (
                <>
                    {avatarNode}
                    <div className={cx('avatar__inner')}>
                        <div className={cx('avatar__user')}>{userName}</div>
                        <div className={cx('avatar__company')}>{companyName}</div>
                    </div>
                </>
            ) : (
                <Tooltip
                    position={TooltipPositionEnum.centerRight}
                    theme={TooltipThemeEnum.black}
                    delay={TOOLTIP_DELAY}
                    tooltipNode={
                        <div className={cx('tooltip', 'tooltip--isAvatar')}>
                            <div className={cx('avatar__inner')}>
                                <div className={cx('avatar__user', 'avatar__user--isInTooltip', 'tooltip__text')}>
                                    {userName}
                                </div>
                                <div className={cx('avatar__company', 'tooltip__text')}>{companyName}</div>
                            </div>
                        </div>
                    }
                >
                    {() => avatarNode}
                </Tooltip>
            )}
        </Link>
    );
});

export type MenuLinkT = {
    renderIcon: (isActive: boolean) => React.ReactElement;
    text: string;
    to: string;
    matchPathname: string | null;
    excludeMatchPathname?: string | null;
    exact?: boolean;
    strict?: boolean;
    testSelector: string;
};

export enum BadgeTypeEnum {
    info = 'info',
}

export type BadgeT = {
    type: BadgeTypeEnum;
    text: string | number | null;
};

type PropsT = {
    className?: string;
    userName: string;
    userEmail: string;
    companyName: string;
    menuLinks: Array<MenuLinkT | null>;
    badges: Record<string, BadgeT[]>;
    logoLink: string;
    accountLink: string;
    isAllowHelpButton?: boolean;
};

const Navigation: React.FC<PropsT> = React.memo((props) => {
    const { className, logoLink, userName, userEmail, companyName, menuLinks, accountLink, badges, isAllowHelpButton } =
        props;
    const { t } = useTranslation();

    const uiContext = React.useContext(UiContext);
    const size = useWindowSize();

    const [isExpanded, setExpanded] = React.useState<boolean>(() => {
        return size.width > BREAKING_POINT_WIDTH;
    });

    React.useEffect(() => {
        const shouldExpand = size.width > BREAKING_POINT_WIDTH;
        setExpanded(shouldExpand);
    }, [size.width]);

    const isActiveCompactMenu = !!uiContext.menu?.isVisible;
    React.useEffect(() => {
        if (isActiveCompactMenu) {
            setExpanded(true);
        }
    }, [isActiveCompactMenu]);

    const location = useLocation();

    const helpIcon = <HelpIcon />;
    const helpContent = t('common:help');
    const handleHelp = (): void => {
        if (window.openZendeskWidget) {
            window.openZendeskWidget();
        }
    };

    let helpNode: React.ReactNode = null;
    if (isAllowHelpButton) {
        helpNode = isExpanded ? (
            <span className={cx('menu-item', 'menu-item--isClickable')} onClick={handleHelp} data-test-selector="help">
                <div className={cx('menu-item__icon')}>{helpIcon}</div>
                <div className={cx('menu-item__text')}>{helpContent}</div>
            </span>
        ) : (
            <div className={cx('light-menu-item__wrap')}>
                <Tooltip
                    position={TooltipPositionEnum.centerRight}
                    theme={TooltipThemeEnum.black}
                    delay={TOOLTIP_DELAY}
                    tooltipNode={<div className={cx('tooltip', 'tooltip--isMenuItem')}>{helpContent}</div>}
                >
                    {() => (
                        <span
                            className={cx('light-menu-item', 'light-menu-item--isClickable')}
                            data-test-selector="help"
                            onClick={handleHelp}
                        >
                            {helpIcon}
                        </span>
                    )}
                </Tooltip>
            </div>
        );
    }

    return (
        <div
            data-dismiss="navigation"
            className={cs(
                cx('navigation', {
                    'navigation--isExpanded': isExpanded,
                }),
                className,
            )}
        >
            <div className={cx('inner')}>
                <div className={cx('logo')}>
                    <Link className={cx('logo__link')} to={logoLink} testSelector="logo">
                        <Logo isSmall={!isExpanded} />
                    </Link>
                </div>
                <MenuAvatar
                    accountLink={accountLink}
                    isExpanded={isExpanded}
                    userEmail={userEmail}
                    userName={userName}
                    companyName={companyName}
                    location={location}
                />
                <div className={cx('menu')}>
                    {menuLinks.map((menuLink) => {
                        if (!menuLink) {
                            return null;
                        }

                        const {
                            matchPathname,
                            excludeMatchPathname,
                            to,
                            renderIcon,
                            text,
                            exact,
                            strict,
                            testSelector,
                        } = menuLink;

                        let isActive = false;
                        if (matchPathname) {
                            isActive = !!matchPath(location?.pathname, {
                                path: matchPathname,
                                exact,
                                strict,
                            });
                        }
                        if (isActive && excludeMatchPathname) {
                            isActive = !matchPath(location?.pathname, {
                                path: excludeMatchPathname,
                                exact: false,
                                strict: false,
                            });
                        }

                        const fullTestSelector = `menu_${testSelector}`;

                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const badgesNode = (badges[to] || []).map((badge) => {
                            const { type, text: badgeText } = badge;

                            return (
                                <span
                                    key={type}
                                    className={cx('count', {
                                        [`count--${type}`]: true,
                                    })}
                                >
                                    {badgeText}
                                </span>
                            );
                        });

                        if (!isExpanded) {
                            return (
                                <div className={cx('light-menu-item__wrap')} key={to}>
                                    <Tooltip
                                        position={TooltipPositionEnum.centerRight}
                                        theme={TooltipThemeEnum.black}
                                        delay={TOOLTIP_DELAY}
                                        tooltipNode={<div className={cx('tooltip', 'tooltip--isMenuItem')}>{text}</div>}
                                    >
                                        {() => (
                                            <Link
                                                to={to}
                                                className={cx('light-menu-item', {
                                                    'light-menu-item--isActive': isActive,
                                                })}
                                                testSelector={fullTestSelector}
                                            >
                                                {renderIcon(isActive)}
                                            </Link>
                                        )}
                                    </Tooltip>
                                </div>
                            );
                        }

                        return (
                            <Link
                                key={to}
                                className={cx('menu-item', {
                                    'menu-item--isActive': isActive,
                                })}
                                to={to}
                                testSelector={fullTestSelector}
                            >
                                <div className={cx('menu-item__icon')}>{renderIcon(isActive)}</div>
                                <div
                                    className={cx('menu-item__text', {
                                        'menu-item__text--isActive': isActive,
                                    })}
                                >
                                    {text}
                                </div>
                            </Link>
                        );
                    })}
                </div>
                {helpNode}
                {isExpanded && (
                    <div className={cx('about')}>
                        <div className={cx('about__inner')}>
                            <div className={cx('about__copyright')}>
                                {t('common:about.copyright', {
                                    year: getCurrentYear(),
                                })}
                            </div>
                            <div className={cx('about__disclaimer')}>{t('common:about.disclaimer')}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default Navigation;
