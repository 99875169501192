import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DriversPageLayout.scss';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import Pagination from 'common/components/Table/Pagination/Pagination';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { ApiInviteDriverRequestT, DriversSortEnum } from 'common/utils/api/models';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDriversById,
    selectDriversPages,
    selectDriversTotal,
    selectInviteDriverRequest,
    selectUpdateDriverRequest,
} from 'common/store/drivers/selectors';
import { fetchDriversPage, inviteDriver } from 'common/store/drivers/actions';
import { CompanyDriverT } from 'common/store/drivers/models';
import DriversTable from './DriversTable/DriversTable';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { isNonNil } from 'common/utils';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { DRIVER_STATUS_T_MAP } from 'common/components/status/DriverStatus/DriverStatus';
import TableError from 'common/components/Table/TableError/TableError';
import { prepareFetchPageQuery } from 'common/layouts/DriversPageLayout/prepare-fetch-page-query';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import { TabEnum } from './constants';
import TabLabel, { TabLabelSizeEnum } from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { driversPaginationChannel, driversRefreshChannel } from 'common/store/drivers/channels';
import { useQueryParams, withDefault } from 'use-query-params';
import { createEnumParam } from 'serialize-query-params/src/params';
import { useCheckOpenedSidebar, useOpenedSidebar, useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import DriverInviteModal, {
    DriverInviteModalDataT,
} from 'common/layouts/DriversPageLayout/dialogs/DriverInviteModal/DriverInviteModal';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { selectPermissions } from 'common/store/auth/selectors';
import { DriverDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DriverDetailsSidebarContent/models';
import { EditDriverDetailsSidebarDataT } from 'common/layouts/SideBars/contents/EditDriverSidebarContent/models';
import SideBars from 'common/layouts/SideBars/SideBars';
import { InferChannelEventT } from 'common/utils/view-event-channel';

const cx = classNames.bind(styles);

const TAB_LABEL_T: Record<TabEnum, string> = {
    [TabEnum.active]: 'common:drivers-page.tabs.active',
    [TabEnum.archive]: 'common:drivers-page.tabs.archive',
};

const TABS_ORDER: TabEnum[] = [TabEnum.active, TabEnum.archive];

type PropsT = {};

const DriversPageLayout: React.FC<PropsT> = React.memo(() => {
    const { partnerId } = usePartnerContext();

    const permissions = useSelector(selectPermissions);

    const openLeftSidebar = useOpenLeftSidebar();

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.driversPage]: createPageNumberParam(),
        [QueryKeysEnum.driversSort]: createSortParams<DriversSortEnum>({
            value: DriversSortEnum.name,
            direction: SortDirectionEnum.ASC,
        }),
        [QueryKeysEnum.driversFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.driversTab]: withDefault(createEnumParam<TabEnum>(Object.values(TabEnum)), TabEnum.active),
    });

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const total = useSelector(selectDriversTotal(partnerId));
    const driversPages = useSelector(selectDriversPages(partnerId));
    const driverById = useSelector(selectDriversById(partnerId));

    const driverInviteModal = useModalDialog<DriverInviteModalDataT>();

    const selectedSort = query[QueryKeysEnum.driversSort];
    const pageNumber = query[QueryKeysEnum.driversPage];
    const activeTab = query[QueryKeysEnum.driversTab];

    const queryFilters = query[QueryKeysEnum.driversFilters];
    const page = driversPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const updateDriversRequestStatus = useSelector(selectUpdateDriverRequest(partnerId));

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, activeTab, selectedSort);

        dispatch(fetchDriversPage(pageNumber, query, partnerId));
    }, [pageNumber, selectedSort, queryFilters, activeTab]);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, activeTab, selectedSort);

        dispatch(fetchDriversPage(pageNumber, query, partnerId, { isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters, activeTab, partnerId]);
    useChannelSubscribe(driversRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, activeTab, selectedSort);

        dispatch(fetchDriversPage(pageNumber, query, partnerId, { isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters, activeTab, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const drivers = React.useMemo(() => {
        return (ids || []).map((id) => driverById[id as string]).filter(isNonNil);
    }, [ids, driverById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.driversPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof driversPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(driversPaginationChannel, setPageHandler);

    const sortOptions: Array<SortDropdownOptionT<DriversSortEnum>> = React.useMemo(
        () => [
            {
                label: t('common:drivers-page.sorts.triggers.name'),
                value: DriversSortEnum.name,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<DriversSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.driversPage]: 0,
            [QueryKeysEnum.driversSort]: sort,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.driversPage]: 0,
            [QueryKeysEnum.driversFilters]: selectedQueryFilters,
        });
    };

    const resetQueryFilters = () => {
        changeQuery({
            [QueryKeysEnum.driversPage]: undefined,
        });
    };

    const hasQueryFilters = !!Object.keys(queryFilters).length;

    const inviteRequestStatus = useSelector(selectInviteDriverRequest(partnerId));

    useCloseModalDialogAfterRequest(inviteRequestStatus, [driverInviteModal]);

    const handleInviteUser = (newDriver: ApiInviteDriverRequestT): void => {
        dispatch(inviteDriver(newDriver, partnerId));
    };

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                render: (queryFilters) => {
                    return t('common:drivers-page.filter-labels.name', {
                        value: queryFilters[QueryFiltersKeysEnum.name],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.name],
            },
            {
                render: (queryFilters) => {
                    const value = queryFilters[QueryFiltersKeysEnum.excludedStatus] || [];
                    const statusLabels = value.map((status) => t(DRIVER_STATUS_T_MAP[status]));

                    return t('common:drivers-page.filter-labels.excluded-statuses', {
                        value: statusLabels.join(', '),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.excludedStatus],
            },
            {
                render: (queryFilters) => {
                    return t('common:drivers-page.filter-labels.search-text', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
        ],
        [queryFilters],
    );

    const handleSelectRow = (event: React.MouseEvent, driver: CompanyDriverT) => {
        if (!driver.id) {
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.driverDetails,
            driverId: driver.id,
            partnerId,
        });
    };

    const handleSelectTab = (tab: TabEnum): void => {
        changeQuery({
            [QueryKeysEnum.driversPage]: 0,
            [QueryKeysEnum.driversTab]: tab,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.driversFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.driversPage]: 0,
            [QueryKeysEnum.driversFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.name]: undefined,
            [QueryFiltersKeysEnum.searchText]: searchText,
        });
    };

    const editDriverDetailsSidebarData = useOpenedSidebar<EditDriverDetailsSidebarDataT>(
        CommonSidebarsTypeEnum.editDriverDetails,
    );
    const driverDetailsSidebarData = useOpenedSidebar<DriverDetailsSidebarDataT>(CommonSidebarsTypeEnum.driverDetails);

    const selectedDriverId = editDriverDetailsSidebarData?.driverId || driverDetailsSidebarData?.driverId || null;

    const isOpenedFilters = useCheckOpenedSidebar(CommonSidebarsTypeEnum.driverFilters);
    const openSpotRequestsFilter = React.useCallback(() => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.driverFilters,
        });
    }, [openLeftSidebar]);

    return (
        <>
            <ContentMargins>
                <div className={cx('tabs')}>
                    {TABS_ORDER.map((tab, tabIndex) => (
                        <TabLabel
                            isCompact
                            className={cx('tabs__tab')}
                            key={tabIndex}
                            size={TabLabelSizeEnum.small}
                            theme={TabsThemesEnum.light}
                            onClick={() => {
                                handleSelectTab(tab);
                            }}
                            isActive={tab === activeTab}
                        >
                            {t(TAB_LABEL_T[tab])}
                        </TabLabel>
                    ))}
                </div>
                <ListPageLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('common:drivers-page.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={openSpotRequestsFilter}
                                />
                                <SortDropdown
                                    className={cx('sort-trigger')}
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                                <SearchControl
                                    placeholder={t('common:drivers-page.search-placeholder')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        rightToolsNode={
                            permissions.canEditDrivers ? (
                                <Button
                                    theme={ButtonThemeEnum.primary}
                                    onClick={() => {
                                        driverInviteModal.setData({});
                                    }}
                                >
                                    {t('common:drivers-page.invite')}
                                </Button>
                            ) : null
                        }
                        filterTagsNode={
                            <FilterPills<QueryFiltersT>
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                                config={filterPillsConfig}
                            />
                        }
                    />
                    {!drivers.length && requestStatus?.ok && hasQueryFilters && (
                        <TableMessage
                            iconType={TableMessageIconsEnum.notFound}
                            title={t('common:drivers-page.table.messages.not-found.title')}
                            description={t('common:drivers-page.table.messages.not-found.description')}
                            isShowAction
                            actionTitle={t('common:drivers-page.table.messages.not-found.action')}
                            actionTheme={ButtonThemeEnum.secondary}
                            onActionClick={resetQueryFilters}
                        />
                    )}
                    {!drivers.length && requestStatus?.ok && !hasQueryFilters && (
                        <TableMessage
                            iconType={TableMessageIconsEnum.empty}
                            title={t('common:drivers-page.table.messages.empty.title')}
                            description={t('common:drivers-page.table.messages.empty.description')}
                            isShowAction
                            actionTitle={t('common:drivers-page.table.messages.empty.action')}
                            onActionClick={() => {
                                driverInviteModal.setData({});
                            }}
                        />
                    )}
                    {!drivers.length && requestStatus?.error && <TableError />}
                    <DriversTable
                        drivers={drivers}
                        isShowStatus={activeTab === TabEnum.active}
                        selectedDriverId={selectedDriverId}
                        onSelectRow={handleSelectRow}
                        isLoading={requestStatus?.loading || updateDriversRequestStatus?.loading}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <DriverInviteModal
                data={driverInviteModal.data}
                onClose={driverInviteModal.onClose}
                onInviteUser={handleInviteUser}
                requestStatus={inviteRequestStatus}
            />
            <SideBars />
        </>
    );
});

export default DriversPageLayout;
