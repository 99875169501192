import * as React from 'react';
import { useTranslation } from 'react-i18next';

import LeftMenuLayoutBase from 'common/layouts/LeftMenuLayout/LeftMenuLayout';
import { BadgeTypeEnum, MenuLinkT } from 'common/layouts/LeftMenuLayout/Navigation/Navigation';
import { routesEnum, TrailersRoutesEnum, TrucksRoutesEnum } from 'carrier/constants';
import TruckIcon from 'common/icons/TruckIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import { DEFAULT_ICON_SIZE, ProfileRoutesEnum, StyleGuideColorsEnum } from 'common/constants';
import DriverIcon from 'common/icons/DriverIcon';
import { DatabaseMenuIcon, ProfileMenuIcon, TeamsMenuIcon } from 'common/layouts/LeftMenuLayout/menu-icons';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import RadarIcon from 'common/icons/RadarIcon';
import FeaturesContext from 'common/contexts/features';
import { ServerFeatureEnum } from 'common/utils/hooks/useFeaturesContextValue';

const badges = {
    [routesEnum.orders]: [
        {
            type: BadgeTypeEnum.info,
            text: 10,
        },
    ],
};

type PropsT = {};

const LeftMenuLayout: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;

    const featuresContext = React.useContext(FeaturesContext);

    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);

    const menuLinks: Array<MenuLinkT | null> = [
        {
            renderIcon: (isActive) => <DatabaseMenuIcon isActive={isActive} />,
            text: t('menu.transport-orders'),
            to: routesEnum.transportOrders,
            matchPathname: routesEnum.transportOrders,
            testSelector: 'transport-orders',
        },
        permissions.canViewTrucks
            ? {
                  renderIcon: (isActive) => (
                      <TruckIcon
                          size={DEFAULT_ICON_SIZE}
                          fillColor={isActive ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.light}
                          strokeColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.trucks'),
                  to: TrucksRoutesEnum.list,
                  matchPathname: routesEnum.trucks,
                  testSelector: 'trucks',
              }
            : null,
        permissions.canViewTrailers
            ? {
                  renderIcon: (isActive) => (
                      <TrailerIcon
                          size={DEFAULT_ICON_SIZE}
                          fillColor={isActive ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.light}
                          strokeColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.trailers'),
                  to: TrailersRoutesEnum.list,
                  matchPathname: routesEnum.trailers,
                  testSelector: 'trailers',
              }
            : null,
        permissions.canViewDrivers
            ? {
                  renderIcon: (isActive) => (
                      <DriverIcon
                          size={DEFAULT_ICON_SIZE}
                          fillColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.drivers'),
                  to: routesEnum.drivers,
                  matchPathname: routesEnum.drivers,
                  testSelector: 'drivers',
              }
            : null,
        featuresContext?.checkActiveServerFeature?.(ServerFeatureEnum.spot) && permissions.canViewSpot
            ? {
                  renderIcon: (isActive) => (
                      <RadarIcon
                          size={DEFAULT_ICON_SIZE}
                          strokeColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.spot'),
                  to: routesEnum.spotRequests,
                  matchPathname: routesEnum.spotRequests,
                  testSelector: 'spot',
              }
            : null,
        permissions.canViewTeamMembers
            ? {
                  renderIcon: (isActive) => <TeamsMenuIcon isActive={isActive} />,
                  text: t('menu.team'),
                  to: routesEnum.team,
                  matchPathname: routesEnum.team,
                  testSelector: 'team',
              }
            : null,
        {
            renderIcon: (isActive) => <ProfileMenuIcon isActive={isActive} />,
            text: t('menu.profile'),
            to: ProfileRoutesEnum.basic,
            matchPathname: routesEnum.profile,
            testSelector: 'profile',
        },
    ];

    return (
        <LeftMenuLayoutBase
            menuLinks={menuLinks}
            accountLink={routesEnum.account}
            logoLink={routesEnum.home}
            badges={badges}
            isAllowHelpButton
        >
            {children}
        </LeftMenuLayoutBase>
    );
});

export default LeftMenuLayout;
