import React from 'react';
import classNames from 'classnames/bind';

import styles from './TruckDetails.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrucksDictById } from 'common/store/trucks-dict/selectors';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { AssetTypeEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum, CurrencyEnum } from 'common/constants';
import { TruckDetailsT } from 'common/store/trucks/models';
import FieldValue from 'common/components/FieldValue/FieldValue';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import LastGPSPositionAlert from 'common/components/LastGPSPositionAlert/LastGPSPositionAlert';
import { fetchCarrierContracts } from 'common/store/carrier-contracts/actions';
import { selectCarrierContractById } from 'common/store/carrier-contracts/selectors';
import { getLabel } from 'common/store/trucks-dict/utils';
import TruckIcon from 'common/icons/TruckIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import EmissionStandartLabel from 'common/components/EmissionStandartLabel/EmissionStandartLabel';
import LinkedTrailerAlert from 'common/layouts/CommonEditableTruckDetailsLayout/LinkedTrailerAlert/LinkedTrailerAlert';
import Money from 'common/components/Money/Money';
import { CountryCodeT } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    isLoading: boolean;
    truckDetails: TruckDetailsT | null;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
};

const TruckDetails: React.FC<PropsT> = (props) => {
    const { partnerId, truckDetails, isLoading, onOpenTrailerDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);
    const trucksDictById = useSelector(selectTrucksDictById);

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
        dispatch(fetchTrucksDict());
    }, []);

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const contractById = useSelector(selectCarrierContractById(partnerId));
    const contract = contractById[truckDetails?.contractId as string] || null;

    const ratePerKm = truckDetails?.contracted ? contract?.truckRateKm : truckDetails?.ratePerKm;

    const truck = trucksDictById?.[truckDetails?.dictTruckId as string];

    return (
        <div className={cx('wrap')}>
            {truckDetails?.linkedTrailerId && (
                <LinkedTrailerAlert
                    className={cx('alert')}
                    trailerId={truckDetails?.linkedTrailerId}
                    plateNumber={truckDetails?.linkedTrailerPlateNumber}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                />
            )}
            <LastGPSPositionAlert
                className={cx('alert')}
                companyId={partnerId}
                assetType={AssetTypeEnum.truck}
                plateNumber={truckDetails?.plateNumber || ''}
            />
            {isLoading && <LoaderOverlay />}
            <FieldValue
                className={cx('field--plate-number')}
                label={t('common:trucks-page.add-truck.fields.plate-number.label')}
                value={truckDetails?.plateNumber || ''}
            />
            <FieldValue
                icon={<FlagIcon countryCode={truckDetails?.countryCode} />}
                label={t('common:trucks-page.add-truck.fields.country.label')}
                value={countryByCode?.[truckDetails?.countryCode as CountryCodeT]?.userLangDisplayName}
            />
            <FieldValue
                className={cx('field--vin')}
                label={t('common:trucks-page.add-truck.fields.truck-vin.label')}
                value={truckDetails?.vin || ''}
            />
            <FieldValue
                icon={
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.brandDark}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                    />
                }
                label={t('common:trucks-page.add-truck.fields.truck-model.label')}
                value={getLabel(truck)}
            />
            <FieldGroup>
                <FieldValue
                    icon={<EmissionIcon strokeColor={StyleGuideColorsEnum.brandDark} />}
                    className={cx('field--emission-standard')}
                    label={t('common:trucks-page.add-truck.fields.emission-standard.label')}
                    value={<EmissionStandartLabel isCompact emissionStandart={truckDetails?.emissionClass} />}
                />
                <FieldValue
                    className={cx('field--cabin-color')}
                    label={t('common:trucks-page.add-truck.fields.cabin-color.label')}
                    value={truckDetails?.cabinColor || ''}
                />
            </FieldGroup>
            <FieldGroup>
                <FieldValue
                    className={cx('field--contract')}
                    label={t('common:trucks-page.add-truck.fields.contract.label')}
                    value={
                        truckDetails?.contracted
                            ? t('common:dedicated.contract', {
                                  number: contract?.name || '',
                              })
                            : t('common:dedicated.none')
                    }
                />
                <FieldValue
                    className={cx('field--rate')}
                    label={t('common:trucks-page.add-truck.fields.rate.label')}
                    value={<Money amount={ratePerKm} currency={CurrencyEnum.EUR} />}
                />
            </FieldGroup>
        </div>
    );
};

export default TruckDetails;
