import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const TeamDriveIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M7.75 8.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zm-3.172 5.25h3.21c.007-.045.09-.435.217-.742-.083 0-.173-.008-.255-.008-1.147 0-2.438.375-3.172.75z"
                />
                <path
                    fill={strokeColor}
                    d="M7.75 10a2.628 2.628 0 002.625-2.625A2.628 2.628 0 007.75 4.75a2.628 2.628 0 00-2.625 2.625A2.628 2.628 0 007.75 10zm0-3.75a1.124 1.124 0 110 2.25 1.123 1.123 0 01-1.125-1.125c0-.622.503-1.125 1.125-1.125zm.037 7.5h-3.21C5.32 13.375 6.604 13 7.75 13c.082 0 .173.008.255.008.255-.548.697-.998 1.23-1.358a8.794 8.794 0 00-1.485-.15c-1.755 0-5.25.877-5.25 2.625v1.125h5.25v-1.125a2.1 2.1 0 01.037-.375zm5.588-1.875c-1.38 0-4.125.758-4.125 2.25v1.125h8.25v-1.125c0-1.492-2.745-2.25-4.125-2.25zm.908-1.365c.57-.322.967-.93.967-1.635a1.876 1.876 0 00-3.75 0 1.885 1.885 0 001.875 1.875c.33 0 .637-.09.908-.24z"
                />
            </g>
        </svg>
    );
};

TeamDriveIcon.displayName = 'TeamDriveIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default TeamDriveIcon;
