export enum AuthErrorTypeEnum {
    networkRequestFailed = 'network-request-failed',
    userNotFound = 'user-not-found',
    userDisabled = 'user-disabled',
    wrongPassword = 'wrong-password',
    userIsNotAuthorized = 'userIsNotAuthorized',
    failSendEmailVerification = 'failSendEmailVerification',
    failSendPasswordResetEmail = 'failSendPasswordResetEmail',
    failUpdatePassword = 'fail-update-password',
    failEmailVerification = 'fail-email-verification',
    failChangePassword = 'fail-change-password',
    failUpdateProfile = 'fail-update-profile',
    failReauthenticateWithCredential = 'fail-reauthenticate-with-credential',
    failSignIn = 'failSignIn',
    failSignOut = 'failSignOut',
    failAnonymouslySignIn = 'failAnonymouslySignIn',
    failSignUp = 'failSignUp',
    wrongRole = 'wrong-role',
    expiredActionCode = 'expired-action-code',
    invalidActionCode = 'invalid-action-code',
    weakPassword = 'weak-password',
    unknown = 'unknown',
}

export class AuthApiError extends Error {
    type: AuthErrorTypeEnum;

    message: string;

    constructor(type: AuthErrorTypeEnum, message: string) {
        super(type);

        this.type = type;
        this.message = message;

        // fix for instanceof
        // https://github.com/microsoft/TypeScript/issues/13965
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, AuthApiError.prototype);
    }
}

export const checkIsAuthApiError = (error: AuthApiError | Error | null | undefined): error is AuthApiError => {
    return error instanceof AuthApiError;
};
