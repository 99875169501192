import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import noop from 'lodash/noop';

import styles from './FilterPill.scss';
import CloseIcon from 'common/icons/CloseIcon';

const cx = classNames.bind(styles);

export enum FilterPillThemeEnum {
    brandDark = 'brandDark',
    charcoal = 'charcoal',
    brandAccent = 'brandAccent',
    blazeOrange = 'blazeOrange',
}

export type PropsT = {
    className?: string;
    icon?: React.ReactNode;
    label: React.ReactNode;
    withCloser: boolean;
    theme: FilterPillThemeEnum;
    onClick: () => void;
    testSelector?: string;
    isCompact?: boolean;
};

const FilterPill: React.FC<PropsT> = React.memo((props) => {
    const { icon, label, withCloser, onClick, theme, className, isCompact, testSelector } = props;

    const fullTestSelector = `${testSelector}_filter-pill`;

    return (
        <div
            className={cs(
                cx('pill', {
                    [`pill--theme-${theme}`]: true,
                    'pill--is-compact': isCompact,
                    'pill--has-icon': !!icon,
                    'pill--is-full-clickable': !withCloser,
                }),
                className,
            )}
            onClick={withCloser ? noop : onClick}
            data-test-selector={`${fullTestSelector}_label`}
        >
            {!!icon && (
                <>
                    <div
                        className={cx('icon', {
                            'icon--is-compact': isCompact,
                        })}
                    >
                        {icon}
                    </div>
                    <div
                        className={cx('icon-placeholder', {
                            'icon-placeholder--is-compact': isCompact,
                        })}
                    />
                </>
            )}
            <div
                className={cx('label', {
                    'label--is-compact': isCompact,
                })}
            >
                {label}
            </div>
            {withCloser && (
                <div
                    className={cx('closer', {
                        'closer--is-compact': isCompact,
                    })}
                    onClick={onClick}
                    data-test-selector={`${fullTestSelector}_closer`}
                >
                    <CloseIcon fillColor="currentColor" />
                </div>
            )}
        </div>
    );
});

export default FilterPill;
