import { QueryFiltersT } from 'common/models';
import { FetchTransportOrdersPageQueryT } from 'common/store/transport-orders/models';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { TransportOrderQuickFilterEnum, TransportOrderSortEnum } from 'common/utils/api/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { PageSortT } from 'common/utils/query';

const getQueryFromQuickFilters = (quickFilter?: TransportOrderQuickFilterEnum): FetchTransportOrdersPageQueryT => {
    switch (quickFilter) {
        case TransportOrderQuickFilterEnum.new: {
            return {
                statusBarPage: 'NEW',
            };
        }
        case TransportOrderQuickFilterEnum.noAssets: {
            return {
                statusBarPage: 'ASSETS_UNASSIGNED',
            };
        }
        case TransportOrderQuickFilterEnum.noDriver: {
            return {
                statusBarPage: 'DRIVER_UNASSIGNED',
            };
        }
        case TransportOrderQuickFilterEnum.runningOutOfTimeToAssignDriver: {
            return {
                statusBarPage: 'RUNNING_OUT_OF_TIME_TO_ASSIGN_DRIVER',
            };
        }
        case TransportOrderQuickFilterEnum.ranOutOfTimeToAssignDriver: {
            return {
                statusBarPage: 'RUN_OUT_OF_TIME_TO_ASSIGN_DRIVER',
            };
        }
        case TransportOrderQuickFilterEnum.runningOutOfTimeToAssignAssets: {
            return {
                statusBarPage: 'RUNNING_OUT_OF_TIME_TO_ASSIGN_ASSETS',
            };
        }
        case TransportOrderQuickFilterEnum.ranOutOfTimeToAssignAssets: {
            return {
                statusBarPage: 'RUN_OUT_OF_TIME_TO_ASSIGN_ASSETS',
            };
        }
        case TransportOrderQuickFilterEnum.expired: {
            return {
                statusBarPage: 'NO_SHOW',
            };
        }
        case TransportOrderQuickFilterEnum.missed: {
            return {
                statusBarPage: 'MISSED',
            };
        }
        case TransportOrderQuickFilterEnum.delaying: {
            return {
                statusBarPage: 'DELAYING',
            };
        }
        case TransportOrderQuickFilterEnum.declined: {
            return {
                statusBarPage: 'DECLINED',
            };
        }
        case TransportOrderQuickFilterEnum.assigned: {
            return {
                statusBarPage: 'ASSIGNED',
            };
        }
        case TransportOrderQuickFilterEnum.inTransit: {
            return {
                statusBarPage: 'IN_TRANSIT',
            };
        }
        case TransportOrderQuickFilterEnum.done: {
            return {
                statusBarPage: 'DONE',
            };
        }
        case TransportOrderQuickFilterEnum.canceled: {
            return {
                statusBarPage: 'CANCELED',
            };
        }
        default:
            return {};
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<TransportOrderSortEnum> | null,
): FetchTransportOrdersPageQueryT => {
    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];
    const maxPrice = queryFilters[QueryFiltersKeysEnum.maxPrice];
    const minPrice = queryFilters[QueryFiltersKeysEnum.minPrice];

    return {
        // @ts-expect-error TODO wait backend
        type: queryFilters[QueryFiltersKeysEnum.orderType],

        dictTrailerId: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,
        plateNumber: queryFilters[QueryFiltersKeysEnum.plateNumber],

        // @ts-ignore TODO wait backend
        address: queryFilters[QueryFiltersKeysEnum.address],

        driverId: queryFilters[QueryFiltersKeysEnum.driverId],

        upperPrice: typeof maxPrice === 'string' ? parseInt(maxPrice, 10) : undefined,
        lowerPrice: typeof minPrice === 'string' ? parseInt(minPrice, 10) : undefined,

        receivedDateFrom: queryFilters[QueryFiltersKeysEnum.receiveDatesFrom],
        receivedDateTo: queryFilters[QueryFiltersKeysEnum.receiveDatesTo],

        pickupDateFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom],
        pickupDateTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo],

        dropOffDateFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom],
        dropOffDateTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo],

        textSearch: queryFilters[QueryFiltersKeysEnum.searchText],

        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
