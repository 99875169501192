import { QueryFiltersT } from 'common/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { FieldsEnum, FormValuesT } from './constants';
import { getDateFromDate, getEndDayFromDateZero, getStartDayFromDateZero } from 'common/utils/time';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const minPrice = parseSimpleNumber(values[FieldsEnum.minPrice]);
    const maxPrice = parseSimpleNumber(values[FieldsEnum.maxPrice]);

    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.orderType]: values[FieldsEnum.orderType] || undefined,

        [QueryFiltersKeysEnum.dictTrailerId]: values[FieldsEnum.dictTrailerId] || undefined,
        [QueryFiltersKeysEnum.plateNumber]: simpleStringFormatter(values[FieldsEnum.plateNumber]) || undefined,

        [QueryFiltersKeysEnum.address]: simpleStringFormatter(values[FieldsEnum.address]) || undefined,

        [QueryFiltersKeysEnum.driverId]: values[FieldsEnum.driverId] || undefined,
        [QueryFiltersKeysEnum.driverName]: values[FieldsEnum.driverName] || undefined,
        [QueryFiltersKeysEnum.minPrice]: minPrice ? String(minPrice) : undefined,
        [QueryFiltersKeysEnum.maxPrice]: maxPrice ? String(maxPrice) : undefined,

        [QueryFiltersKeysEnum.pickupDateFrom]: undefined,
        [QueryFiltersKeysEnum.pickupDateTo]: undefined,

        [QueryFiltersKeysEnum.dropOffDateFrom]: undefined,
        [QueryFiltersKeysEnum.dropOffDateTo]: undefined,

        [QueryFiltersKeysEnum.receiveDatesFrom]: undefined,
        [QueryFiltersKeysEnum.receiveDatesTo]: undefined,
    };

    const pickupDates = values[FieldsEnum.pickupDates];
    if (pickupDates?.from) {
        const date = getDateFromDate(pickupDates.from);
        queryFilters[QueryFiltersKeysEnum.pickupDateFrom] = getStartDayFromDateZero(date);
    }

    if (pickupDates?.to) {
        const date = getDateFromDate(pickupDates.to);
        queryFilters[QueryFiltersKeysEnum.pickupDateTo] = getEndDayFromDateZero(date);
    }

    const dropoffDates = values[FieldsEnum.dropoffDates];
    if (dropoffDates?.from) {
        const date = getDateFromDate(dropoffDates.from);
        queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] = getStartDayFromDateZero(date);
    }

    if (dropoffDates?.to) {
        const date = getDateFromDate(dropoffDates.to);
        queryFilters[QueryFiltersKeysEnum.dropOffDateTo] = getEndDayFromDateZero(date);
    }

    const receiveDates = values[FieldsEnum.receiveDates];
    if (receiveDates?.from) {
        const date = getDateFromDate(receiveDates.from);
        queryFilters[QueryFiltersKeysEnum.receiveDatesFrom] = getStartDayFromDateZero(date);
    }

    if (receiveDates?.to) {
        const date = getDateFromDate(receiveDates.to);
        queryFilters[QueryFiltersKeysEnum.receiveDatesTo] = getEndDayFromDateZero(date);
    }

    return queryFilters;
};

export default getQueryFilters;
