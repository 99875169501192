import { put, takeEvery } from 'redux-saga/effects';
import { FETCH_USER_REQUEST, FetchUserActionT, PATCH_USER_REQUEST, PatchUserActionT } from './types';
import {
    fetchUserBegin,
    fetchUserError,
    fetchUserSuccess,
    patchUserBegin,
    patchUserError,
    patchUserSuccess,
} from './actions';
import authApi from 'common/utils/api/auth/auth-api';
import { signInSuccess, signOut } from 'common/store/auth/actions';
import { changeLanguage, LangEnum } from 'common/locales/i18n';
import i18n from 'i18next';
import { logWarning } from 'common/utils/logger';
import { convertToApiUser, patchUser } from 'common/store/user/utils';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { UserStatusEnum } from 'common/utils/api/models';

export function* forceRefreshCurrentUserSaga(): WrapGeneratorT<void> {
    const [error, currentUser]: ReturnApiT<typeof commonTranziitApi.fetchCurrentUser> =
        yield commonTranziitApi.fetchCurrentUser();
    if (error) {
        yield put(fetchUserError(error));
        return;
    }

    if (!currentUser) {
        logWarning('empty current error');
        return;
    }

    yield put(fetchUserSuccess(currentUser));
}

function* fetchUserSaga(action: FetchUserActionT): WrapGeneratorT<void> {
    yield put(fetchUserBegin());
    const [error, currentUser]: ReturnApiT<typeof commonTranziitApi.fetchCurrentUser> =
        yield commonTranziitApi.fetchCurrentUser();
    if (error) {
        yield put(fetchUserError(error));
        return;
    }

    if (currentUser?.language && currentUser?.language !== i18n.language) {
        changeLanguage(currentUser?.language as LangEnum);
    }

    if (!currentUser) {
        logWarning('empty current error');
        return;
    }

    yield put(fetchUserSuccess(currentUser));

    if (currentUser.status === UserStatusEnum.archived) {
        yield put(signOut());
    }
}

function* patchUserSaga(action: PatchUserActionT): WrapGeneratorT<void> {
    const { firebaseUser, user, changes } = action;
    if (!firebaseUser || !user) {
        logWarning('empty firebaseUser and user');
        return;
    }

    if (!firebaseUser.email) {
        logWarning('empty firebaseUser email!');
        return;
    }

    yield put(patchUserBegin());
    if (changes.currentPassword && changes.newPassword) {
        const [reAuthError, newUser] = yield authApi.reauthenticateWithCredential(
            firebaseUser.email,
            changes.currentPassword,
        );
        if (reAuthError) {
            yield put(patchUserError(reAuthError));
            return;
        }

        const [, idTokenResult]: ReturnApiT<typeof authApi.getIdTokenResult> = yield authApi.getIdTokenResult();

        yield put(signInSuccess(newUser, idTokenResult?.claims || null));

        const [changePasswordError] = yield authApi.changePassword(changes.newPassword);
        if (changePasswordError) {
            yield put(patchUserError(changePasswordError));
            return;
        }
    }

    const newDisplayName = `${changes.firstName} ${changes.lastName}`;
    if (newDisplayName !== firebaseUser.displayName) {
        const [updateProfileError] = yield authApi.updateProfile(newDisplayName);
        if (updateProfileError) {
            yield put(patchUserError(updateProfileError));
            return;
        }
    }

    const patchedUser = patchUser(user, changes);
    const patchedApiUser = convertToApiUser(patchedUser);

    const [userPatchError]: ReturnApiT<typeof commonTranziitApi.patchUser> = yield commonTranziitApi.patchUser(
        patchedApiUser,
    );
    if (userPatchError) {
        yield put(patchUserError(userPatchError));
        return;
    }

    yield put(patchUserSuccess(patchedApiUser));

    if (changes.language !== user.language) {
        changeLanguage(changes.language as LangEnum);
    }
}

function* userSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_USER_REQUEST, fetchUserSaga);
    yield takeEvery(PATCH_USER_REQUEST, patchUserSaga);
}

export default userSaga;
