import React from 'react';

type UseAsyncFormErrorsT<AsyncErrorsT> = {
    asyncFormErrors: AsyncErrorsT;
    resetAsyncFormErrors: (fieldName: string) => void;
};

type BaseAsyncErrorsT = Record<string, string>;

const useAsyncFormErrors = <T extends BaseAsyncErrorsT>(asyncErrors: T): UseAsyncFormErrorsT<T> => {
    const [asyncFormErrors, setAsyncFormErrors] = React.useState<T>(asyncErrors);

    React.useEffect(() => {
        setAsyncFormErrors(asyncErrors);
    }, [asyncErrors]);

    const resetAsyncFormErrors = React.useCallback((fieldName: string) => {
        const newAsyncFormErrors = { ...asyncFormErrors };

        delete newAsyncFormErrors[fieldName];

        setAsyncFormErrors(newAsyncFormErrors);
    }, []);

    return {
        asyncFormErrors,
        resetAsyncFormErrors,
    };
};

export default useAsyncFormErrors;
