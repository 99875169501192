import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import MaskedInput from 'react-text-mask';

import styles from './Input.scss';
import CloseIcon from 'common/icons/CloseIcon';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import useTestSelector from 'common/utils/hooks/useTestSelector';

const cx = classNames.bind(styles);

type ValueT = string;

export type InputFormatterT = (value: ValueT) => string;

export type IconMetaT = {
    isLoading: boolean;
    isDisabled: boolean;
    isFocused: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    isHighlighted: boolean;
    hasValue: boolean;
};

export type PropsT = {
    className?: string;
    name: string;
    value: ValueT;
    rightNode?: React.ReactNode;
    type?: string;
    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (meta: IconMetaT) => React.ReactNode;
    isLoading?: boolean;
    placeholder?: string;
    onChange: (value: ValueT) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    hasError?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
    autoFocus?: boolean;
    isDisabled?: boolean;
    isFocused?: boolean;
    formatter?: InputFormatterT;
    mask?: MaskT;
    guide?: boolean;
    isHighlighted?: boolean;
    isTransparent?: boolean;
    hasChanges?: boolean;
    autoComplete?: string;
    hasClearControl?: boolean;
    testSelectorPrefix?: string;
};

const Input: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        name,
        rightNode,
        type,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        onKeyUp,
        renderLeftIcon,
        renderRightIcon,
        hasError,
        hasWarning,
        hasSuccess,
        autoFocus,
        isLoading,
        isDisabled,
        isFocused: isForceFocused,
        formatter,
        mask,
        className,
        guide = true,
        hasChanges,
        autoComplete,
        isHighlighted,
        isTransparent,
        hasClearControl,
        testSelectorPrefix,
    } = props;

    const [isFocused, setFocused] = React.useState<boolean>(false);
    const [isHovered, setHovered] = React.useState<boolean>(false);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const sourceValue = event.currentTarget.value || '';
        const formattedValue = formatter ? formatter(sourceValue) : sourceValue;
        onChange(formattedValue);
    };

    const handleReset = () => {
        onChange('');
    };

    const handleBlur = (): void => {
        setFocused(false);

        if (onBlur) {
            onBlur();
        }
    };

    const handleFocus = (): void => {
        setFocused(true);

        if (onFocus) {
            onFocus();
        }
    };

    const handleMouseMove = (): void => {
        if (!isHovered) {
            setHovered(true);
        }
    };

    const handleMouseLeave = (): void => {
        setHovered(false);
    };

    const isEmpty = !value;

    const testSelector = useTestSelector(testSelectorPrefix || name, 'input');
    const resetControlTestSelector = useTestSelector(testSelector, 'reset');

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        autoFocus: !!autoFocus,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onKeyUp,
        name,
        value: value || '',
        type,
        className: cx('inner-input', {
            'inner-input--isEmpty': isEmpty,
            'inner-input--isDisabled': isDisabled,
        }),
        placeholder,
        disabled: isDisabled,
        autoComplete: autoComplete || 'off',
        // @ts-ignore
        'data-test-selector': testSelector,
    };

    const showClearControl = !!value && !isLoading && hasClearControl;

    return (
        <div
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            className={cs(
                cx('input', {
                    'input--hasWarning': hasWarning,
                    'input--hasSuccess': hasSuccess,
                    'input--hasChanges': hasChanges,
                    'input--isHighlighted': isHighlighted,
                    'input--hasError': hasError,
                    'input--hasLeftIcon': !!renderLeftIcon,
                    'input--hasRightNode': !!renderRightIcon || !!rightNode || showClearControl || isLoading,
                    'input--isDisabled': isDisabled,
                    'input--isHovered': isHovered,
                    'input--isTransparent': isTransparent,
                    'input--isFocused': isFocused || isForceFocused,
                }),
                className,
            )}
        >
            {renderLeftIcon && (
                <div className={cx('icon', 'icon--isLeft')}>
                    {renderLeftIcon({
                        isLoading: !!isLoading,
                        isDisabled: !!isDisabled,
                        isFocused,
                        hasError: !!hasError,
                        hasWarning: !!hasWarning,
                        hasSuccess: !!hasSuccess,
                        isHighlighted: !!isHighlighted,
                        hasValue: !isEmpty,
                    })}
                </div>
            )}
            {mask ? <MaskedInput guide={guide} mask={mask} {...inputProps} /> : <input {...inputProps} />}
            {rightNode && (
                <div
                    className={cx('right-node', {
                        'right-node--isEmpty': isEmpty,
                        'right-node--hasRightNode': rightNode,
                    })}
                >
                    {rightNode}
                </div>
            )}
            {showClearControl && (
                <div
                    className={cx('control', 'control--isClickable')}
                    onClick={handleReset}
                    data-test-selector={resetControlTestSelector}
                >
                    <CloseIcon fillColor="currentColor" />
                </div>
            )}
            {isLoading && (
                <div className={cx('control')}>
                    <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                </div>
            )}
            {renderRightIcon && (
                <div className={cx('icon', 'icon--isRight')}>
                    {renderRightIcon({
                        isLoading: !!isLoading,
                        isDisabled: !!isDisabled,
                        isFocused,
                        hasError: !!hasError,
                        hasWarning: !!hasWarning,
                        hasSuccess: !!hasSuccess,
                        isHighlighted: !!isHighlighted,
                        hasValue: !isEmpty,
                    })}
                </div>
            )}
        </div>
    );
});

export default Input;
