import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from './DriverDetailsSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldValue from 'common/components/FieldValue/FieldValue';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesByCode, selectCountriesByLangCode } from 'common/store/countries-dict/selectors';
import PhoneNumberIcon from 'common/components/PhoneNumberIcon/PhoneNumberIcon';
import { ICON_BY_MEDIA } from 'common/layouts/Members/media-icons';
import { CurrencyEnum, SocialMediaEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import chunk from 'lodash/chunk';
import isNumber from 'lodash/isNumber';
import { FORMAL_T_MAP } from 'common/components/dropdowns/UserFormalDropdown/UserFormalDropdown';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { DriverDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DriverDetailsSidebarContent/models';
import { useDriverActionOptions } from './hooks/use-driver-action-options';
import {
    selectDriverDetailsById,
    selectFetchDriverDetailsRequest,
    selectUpdateDriverRequest,
} from 'common/store/drivers/selectors';
import { activateDriver, archiveDriver, cancelDriverInvite, fetchDriverDetails } from 'common/store/drivers/actions';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import ArchiveDriverConfirmation, {
    ArchiveDriverConfirmationDataT,
} from 'common/layouts/DriversPageLayout/dialogs/ArchiveDriverConfirmation/ArchiveDriverConfirmation';
import ActivateDriverConfirmation, {
    ActivateDriverConfirmationDataT,
} from 'common/layouts/DriversPageLayout/dialogs/ActivateDriverConfirmation/ActivateDriverConfirmation';
import CancelDriverInviteModal, {
    CancelDriverInviteConfirmationDataT,
} from 'common/layouts/DriversPageLayout/dialogs/CancelDriverInviteModal/CancelDriverInviteModal';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { driverDetailsRefreshChannel } from 'common/store/drivers/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import DriverSidebarHeader from './DriverSidebarHeader/DriverSidebarHeader';
import Money from 'common/components/Money/Money';
import { CountryCodeT } from 'common/utils/api/models';
import DriverStatusPill from 'common/components/status-pill/DriverStatusPill/DriverStatusPill';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<DriverDetailsSidebarDataT, CommonSidebarDataT>;

const formatBirthday = (birthday: string) => moment(birthday, 'YYYY-MM-DD').format('DD.MM.YYYY');

const DriverDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onClose, onGoBack, onOpenNextSidebar } = props;

    const driverDetailsById = useSelector(selectDriverDetailsById(data.partnerId));
    const driverDetails = driverDetailsById[data.driverId as DriverIdT];

    const fetchDriverDetailsRequestStatus = useSelector(selectFetchDriverDetailsRequest(data.partnerId));
    const updateDriversRequestStatus = useSelector(selectUpdateDriverRequest(data.partnerId));

    const archiveDriverConfirmation = useModalDialog<ArchiveDriverConfirmationDataT>();
    const activateDriverConfirmation = useModalDialog<ActivateDriverConfirmationDataT>();
    const cancelDriverInviteConfirmation = useModalDialog<CancelDriverInviteConfirmationDataT>();

    useCloseModalDialogAfterRequest(updateDriversRequestStatus, [
        archiveDriverConfirmation,
        activateDriverConfirmation,
        cancelDriverInviteConfirmation,
    ]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchDriverDetails(data.driverId, data.partnerId, { isForceUpdate: false }));
    }, [data]);

    const refreshHandler = useCallback(
        ({ driverId, partnerId }: InferChannelEventT<typeof driverDetailsRefreshChannel>) => {
            if (driverId === data.driverId && partnerId === data.partnerId) {
                dispatch(fetchDriverDetails(data.driverId, data.partnerId, { isForceUpdate: true }));
            }
        },
        [data],
    );
    useChannelSubscribe(driverDetailsRefreshChannel, refreshHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchDriverDetails(data.driverId, data.partnerId, { isForceUpdate: false }));
    }, [data]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const handleEditDriverDetails = useCallback(() => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.editDriverDetails,
            driverId: data.driverId,
            partnerId: data.partnerId,
        });
    }, [data, onOpenNextSidebar]);

    const headerDropdownOptions = useDriverActionOptions(driverDetails, data.partnerId, {
        onEdit: handleEditDriverDetails,
        onCancelInvite: cancelDriverInviteConfirmation.setData,
        onArchiveDriver: archiveDriverConfirmation.setData,
        onActivateDriver: activateDriverConfirmation.setData,
    });

    const { t } = useTranslation();

    const countryByCode = useSelector(selectCountriesByCode);
    const countryByLangCode = useSelector(selectCountriesByLangCode);

    const mediasNodes = [
        <FieldValue
            key="contact"
            icon={<PhoneNumberIcon phoneNumber={driverDetails?.contact?.deskPhone} />}
            className={cx('field--contact')}
            label={t('common:driver-details.fields.desktop-phone.label')}
            placeholder={t('common:driver-details.fields.desktop-phone.placeholder')}
            value={driverDetails?.contact?.deskPhone || ''}
        />,
        <FieldValue
            key="mobile-phone"
            icon={<PhoneNumberIcon phoneNumber={driverDetails?.contact?.mobilePhone} />}
            className={cx('field--contact')}
            label={t('common:driver-details.fields.mobile-phone.label')}
            placeholder={t('common:driver-details.fields.mobile-phone.placeholder')}
            value={driverDetails?.contact?.mobilePhone || ''}
        />,
        ...(driverDetails?.contact?.medias || []).map((media, index) => {
            if (typeof media.login !== 'string') {
                return null;
            }

            const icon = ICON_BY_MEDIA[media.type as SocialMediaEnum];

            return (
                <FieldValue
                    key={index}
                    icon={icon}
                    className={cx('field--contact')}
                    label={t(`common:driver-details.fields.${media.type}.label`)}
                    value={media.login || ''}
                />
            );
        }),
    ].filter(isNonNil);

    const handleConfirmArchive = (data: ArchiveDriverConfirmationDataT): void => {
        dispatch(archiveDriver(data.driverId, data.companyId));
    };

    const handleConfirmActivate = (data: ActivateDriverConfirmationDataT): void => {
        dispatch(activateDriver(data.driverId, data.companyId));
    };

    const handleConfirmCancelDriverInvite = (data: CancelDriverInviteConfirmationDataT): void => {
        dispatch(cancelDriverInvite(data.driverUserId, data.companyId));

        if (onClose) {
            onClose();
        }
    };

    const mediaChunks = chunk(mediasNodes, 2);

    return (
        <>
            {fetchDriverDetailsRequestStatus.loading && <LoaderOverlay />}
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={driverDetails ? <DriverSidebarHeader driverDetails={driverDetails} /> : null}
                    afterTitleNode={
                        driverDetails ? <DriverStatusPill isSymmetrical status={driverDetails?.status} /> : null
                    }
                    dropdownOptions={headerDropdownOptions}
                    onClose={onClose}
                    onGoBack={onGoBack}
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('common:driver-details.sections.name')}</div>
                    <FieldGroup>
                        <FieldValue
                            className={cx('field--formal')}
                            label={t('common:driver-details.fields.formal.label')}
                            value={
                                driverDetails?.contact?.formal ? t(FORMAL_T_MAP[driverDetails?.contact?.formal]) : ''
                            }
                            placeholder={t('common:fields.placeholder.not-specified')}
                        />
                        <FieldValue
                            className={cx('field--first-name')}
                            label={t('common:driver-details.fields.first-name.label')}
                            value={driverDetails?.contact?.name || ''}
                        />
                        <FieldValue
                            className={cx('field--last-name')}
                            label={t('common:driver-details.fields.last-name.label')}
                            value={driverDetails?.contact?.surname || ''}
                        />
                    </FieldGroup>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('common:driver-details.sections.contacts')}</div>
                    {mediaChunks.map((chunk, chunkIndex) => (
                        <FieldGroup key={chunkIndex}>{chunk}</FieldGroup>
                    ))}
                </div>
                <div className={cx('section', 'section--isLast')}>
                    <div className={cx('section__title')}>{t('common:driver-details.sections.personals')}</div>
                    <FieldValue
                        className={cx('field--birthday')}
                        label={t('common:driver-details.fields.address.label')}
                        value={driverDetails?.homeAddress || ''}
                        placeholder={t('common:fields.placeholder.not-specified')}
                    />
                    <FieldGroup>
                        <FieldValue
                            className={cx('field--birthday')}
                            label={t('common:driver-details.fields.birthday.label')}
                            value={
                                driverDetails?.contact?.birthday ? formatBirthday(driverDetails?.contact?.birthday) : ''
                            }
                            placeholder={t('common:fields.placeholder.not-specified')}
                        />
                        <FieldValue
                            className={cx('field--country')}
                            label={t('common:driver-details.fields.residence.label')}
                            value={
                                countryByCode[driverDetails?.contact?.residence as CountryCodeT]?.userLangDisplayName ||
                                ''
                            }
                            placeholder={t('common:fields.placeholder.not-specified')}
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <FieldValue
                            className={cx('field--language')}
                            label={t('common:driver-details.fields.language.label')}
                            value={countryByLangCode[driverDetails?.contact?.language as string]?.code || ''}
                            placeholder={t('common:fields.placeholder.not-specified')}
                        />
                        <FieldValue
                            className={cx('field--country')}
                            label={t('common:driver-details.fields.rate-per-hour.label')}
                            placeholder={t('common:driver-details.fields.rate-per-hour.placeholder')}
                            value={
                                driverDetails && isNumber(driverDetails.rate) ? (
                                    <Money amount={driverDetails.rate} currency={CurrencyEnum.EUR} />
                                ) : null
                            }
                        />
                    </FieldGroup>
                </div>
            </SideBarLayout>
            <ArchiveDriverConfirmation
                data={archiveDriverConfirmation.data}
                onConfirm={handleConfirmArchive}
                onCancel={archiveDriverConfirmation.onCancel}
                onClose={archiveDriverConfirmation.onClose}
                requestStatus={updateDriversRequestStatus}
            />
            <ActivateDriverConfirmation
                data={activateDriverConfirmation.data}
                onConfirm={handleConfirmActivate}
                onCancel={activateDriverConfirmation.onCancel}
                onClose={activateDriverConfirmation.onClose}
                requestStatus={updateDriversRequestStatus}
            />
            <CancelDriverInviteModal
                data={cancelDriverInviteConfirmation.data}
                onConfirm={handleConfirmCancelDriverInvite}
                onCancel={cancelDriverInviteConfirmation.onCancel}
                onClose={cancelDriverInviteConfirmation.onClose}
                requestStatus={updateDriversRequestStatus}
            />
        </>
    );
};

export default DriverDetailsSidebarContent;
