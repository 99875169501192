import {
    UserActionT,
    UserStateT,
    FETCH_USER_REQUEST_BEGIN,
    FETCH_USER_REQUEST_ERROR,
    FETCH_USER_REQUEST_SUCCESS,
    PATCH_USER_REQUEST_BEGIN,
    PATCH_USER_REQUEST_SUCCESS,
    PATCH_USER_REQUEST_ERROR,
} from './types';
import requestStatus from 'common/utils/request-status';
import { prepareUser } from './utils';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: UserStateT = {
    currentUserRequest: requestStatus.initial,
    currentUser: null,
    patchUserRequest: requestStatus.initial,
};

export default (state = initialState, action: UserActionT | DestroySessionActionT): UserStateT => {
    switch (action.type) {
        case FETCH_USER_REQUEST_BEGIN: {
            return {
                ...state,
                currentUserRequest: requestStatus.loading,
            };
        }
        case FETCH_USER_REQUEST_SUCCESS: {
            const { currentUser } = action;

            return {
                ...state,
                currentUser: prepareUser(currentUser),
                currentUserRequest: requestStatus.ok,
            };
        }
        case FETCH_USER_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                currentUserRequest: requestStatus.setError(error),
            };
        }
        case PATCH_USER_REQUEST_BEGIN: {
            return {
                ...state,
                patchUserRequest: requestStatus.loading,
            };
        }
        case PATCH_USER_REQUEST_SUCCESS: {
            const { currentUser } = action;
            return {
                ...state,
                currentUser: prepareUser(currentUser),
                patchUserRequest: requestStatus.ok,
            };
        }
        case PATCH_USER_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                patchUserRequest: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
