import {
    CONTINUE_SIGN_UP_REQUEST,
    ContinueSignUpActionT,
    DESTROY_SESSION,
    DestroySessionActionT,
    EMAIL_VERIFICATION_REQUEST,
    EMAIL_VERIFICATION_REQUEST_BEGIN,
    EMAIL_VERIFICATION_REQUEST_ERROR,
    EMAIL_VERIFICATION_REQUEST_SUCCESS,
    EmailVerificationActionT,
    EmailVerificationBeginActionT,
    EmailVerificationErrorActionT,
    EmailVerificationSuccessActionT,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST_BEGIN,
    RESET_PASSWORD_REQUEST_ERROR,
    RESET_PASSWORD_REQUEST_SUCCESS,
    RESET_USER,
    ResetPasswordActionT,
    ResetPasswordBeginActionT,
    ResetPasswordErrorActionT,
    ResetPasswordSuccessActionT,
    ResetUserActionT,
    SET_USER,
    SetUserActionT,
    SIGN_IN_REQUEST,
    SIGN_IN_REQUEST_BEGIN,
    SIGN_IN_REQUEST_ERROR,
    SIGN_IN_REQUEST_SUCCESS,
    SIGN_OUT_REQUEST,
    SIGN_OUT_REQUEST_BEGIN,
    SIGN_OUT_REQUEST_ERROR,
    SIGN_OUT_REQUEST_SUCCESS,
    SIGN_UP_REQUEST,
    SIGN_UP_REQUEST_BEGIN,
    SIGN_UP_REQUEST_ERROR,
    SIGN_UP_REQUEST_SUCCESS,
    SignInActionT,
    SignInBeginActionT,
    SignInErrorActionT,
    SignInSuccessActionT,
    SignOutActionT,
    SignOutBeginActionT,
    SignOutErrorActionT,
    SignOutSuccessActionT,
    SignUpActionT,
    SignUpBeginActionT,
    SignUpErrorActionT,
    SignUpSuccessActionT,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_REQUEST_BEGIN,
    UPDATE_PASSWORD_REQUEST_ERROR,
    UPDATE_PASSWORD_REQUEST_SUCCESS,
    UpdatePasswordActionT,
    UpdatePasswordBeginActionT,
    UpdatePasswordErrorActionT,
    UpdatePasswordSuccessActionT,
} from './types';
import IdTokenResult = firebase.auth.IdTokenResult;
import { SignUpParamsT } from 'common/store/auth/models';

export const signIn = (email: string, password: string, returnUrl: string | null): SignInActionT => ({
    type: SIGN_IN_REQUEST,
    email,
    password,
    returnUrl,
});

export const signInBegin = (): SignInBeginActionT => ({
    type: SIGN_IN_REQUEST_BEGIN,
});

export const signInSuccess = (
    user: firebase.User,
    tokenClaims: IdTokenResult['claims'] | null,
): SignInSuccessActionT => ({
    type: SIGN_IN_REQUEST_SUCCESS,
    user,
    tokenClaims,
});

export const signInError = (error: Error): SignInErrorActionT => ({
    type: SIGN_IN_REQUEST_ERROR,
    error,
});

export const resetPassword = (email: string): ResetPasswordActionT => ({
    type: RESET_PASSWORD_REQUEST,
    email,
});

export const resetPasswordBegin = (): ResetPasswordBeginActionT => ({
    type: RESET_PASSWORD_REQUEST_BEGIN,
});

export const resetPasswordSuccess = (): ResetPasswordSuccessActionT => ({
    type: RESET_PASSWORD_REQUEST_SUCCESS,
});

export const resetPasswordError = (error: Error): ResetPasswordErrorActionT => ({
    type: RESET_PASSWORD_REQUEST_ERROR,
    error,
});

export const updatePassword = (oobCode: string, password: string): UpdatePasswordActionT => ({
    type: UPDATE_PASSWORD_REQUEST,
    oobCode,
    password,
});

export const updatePasswordBegin = (): UpdatePasswordBeginActionT => ({
    type: UPDATE_PASSWORD_REQUEST_BEGIN,
});

export const updatePasswordSuccess = (): UpdatePasswordSuccessActionT => ({
    type: UPDATE_PASSWORD_REQUEST_SUCCESS,
});

export const updatePasswordError = (error: Error): UpdatePasswordErrorActionT => ({
    type: UPDATE_PASSWORD_REQUEST_ERROR,
    error,
});

export const continueSignUp = (
    firstName: string,
    lastName: string,
    phoneNumber: string,
    password: string,
    oobCode: string | null,
    email: string | null,
    returnUrl: string | null,
): ContinueSignUpActionT => ({
    type: CONTINUE_SIGN_UP_REQUEST,
    firstName,
    lastName,
    phoneNumber,
    password,
    oobCode,
    email,
    returnUrl,
});

export const signUp = (params: SignUpParamsT): SignUpActionT => ({
    type: SIGN_UP_REQUEST,
    params,
});

export const signUpBegin = (): SignUpBeginActionT => ({
    type: SIGN_UP_REQUEST_BEGIN,
});

export const signUpSuccess = (): SignUpSuccessActionT => ({
    type: SIGN_UP_REQUEST_SUCCESS,
});

export const signUpError = (error: Error): SignUpErrorActionT => ({
    type: SIGN_UP_REQUEST_ERROR,
    error,
});

export const setUser = (user: firebase.User | null, tokenClaims: IdTokenResult['claims'] | null): SetUserActionT => ({
    type: SET_USER,
    user,
    tokenClaims,
});

export const resetUser = (): ResetUserActionT => ({
    type: RESET_USER,
});

export const signOut = (): SignOutActionT => ({
    type: SIGN_OUT_REQUEST,
});

export const signOutBegin = (): SignOutBeginActionT => ({
    type: SIGN_OUT_REQUEST_BEGIN,
});

export const signOutSuccess = (): SignOutSuccessActionT => ({
    type: SIGN_OUT_REQUEST_SUCCESS,
});

export const signOutError = (error: Error): SignOutErrorActionT => ({
    type: SIGN_OUT_REQUEST_ERROR,
    error,
});

export const emailVerification = (code: string): EmailVerificationActionT => ({
    type: EMAIL_VERIFICATION_REQUEST,
    code,
});

export const emailVerificationBegin = (): EmailVerificationBeginActionT => ({
    type: EMAIL_VERIFICATION_REQUEST_BEGIN,
});

export const emailVerificationSuccess = (): EmailVerificationSuccessActionT => ({
    type: EMAIL_VERIFICATION_REQUEST_SUCCESS,
});

export const emailVerificationError = (error: Error): EmailVerificationErrorActionT => ({
    type: EMAIL_VERIFICATION_REQUEST_ERROR,
    error,
});

export const destroySession = (): DestroySessionActionT => ({
    type: DESTROY_SESSION,
});
