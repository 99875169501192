import React from 'react';
import classNames from 'classnames/bind';

import styles from './DriverFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { DriverStatusEnum } from 'common/utils/api/models';
import DriverColoredStatus from 'common/components/status-colored/DriverColoredStatus/DriverColoredStatus';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { DriverFiltersSidebarDataT } from './models';
import { useQueryParam } from 'use-query-params';
import { createJsonParams } from 'common/utils/query';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from 'common/layouts/DriversPageLayout/query-models';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<DriverFiltersSidebarDataT, CommonSidebarDataT>;

const AVAILABLE_STATUSES = [
    DriverStatusEnum.free,
    DriverStatusEnum.pending,
    DriverStatusEnum.notAvailable,
    DriverStatusEnum.inTransit,
    DriverStatusEnum.archived,
];

const DriverFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;

    const { t } = useTranslation();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.driversFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);

    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(queryFilters);
        return [values, {}];
    }, [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        initialErrors,
        onSubmit: (values, formikHelpers): void => {
            const newQeryFilters = getQueryFilters(values);

            setQueryFilters({
                [QueryFiltersKeysEnum.searchText]: initialQueryFilters[QueryFiltersKeysEnum.searchText],
                ...newQeryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const excludedStatus = formik.values[FieldsEnum.excludedDriversStatus];
    const toggleExcludeStatus = (status: DriverStatusEnum) => () => {
        const alreadyHas = excludedStatus.includes(status);

        let types;
        if (alreadyHas) {
            types = excludedStatus.filter((type: string) => type !== status);
        } else {
            types = [...excludedStatus, status];
        }

        formik.setFieldValue(FieldsEnum.excludedDriversStatus, types);
    };

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:drivers-page.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <div className={cx('block')}>
                    <FormikField
                        name={FieldsEnum.name}
                        label={t('common:drivers-page.filters.fields.name.label')}
                        error={formik.errors[FieldsEnum.name]}
                        meta={formik.getFieldMeta(FieldsEnum.name)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.name}
                                value={formik.values[FieldsEnum.name]}
                                placeholder={t('common:drivers-page.filters.fields.name.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('block')}>
                    <div className={cx('block__title')}>{t('common:drivers-page.filters.blocks.status')}</div>
                    <div>
                        {AVAILABLE_STATUSES.map((status) => (
                            <div className={cx('checkbox')} key={status}>
                                <Checkbox
                                    checked={!excludedStatus.includes(status)}
                                    onChange={toggleExcludeStatus(status)}
                                    label={
                                        <DriverColoredStatus
                                            isRightIcon
                                            labelClassName={cx('status')}
                                            status={status}
                                        />
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:drivers-page.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default DriverFiltersSidebarContent;
