import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const TruckIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path fill={fillColor} fillOpacity={0.3} d="M5 5h10v8l1 1v2H4v-2l1-1z" />
                <path
                    fill={strokeColor}
                    d="M17.237 12.937c0-.597-.506-1.08-1.13-1.08h-.295V1.723a.267.267 0 00-.126-.225l-.717-.453c-.188-.118-.438.01-.438.225v3.255l-.213-.551c-.288-.741-1.027-1.233-1.853-1.233H7.531c-.832 0-1.574.498-1.858 1.245l-.204.539V1.27c0-.215-.25-.343-.438-.225l-.717.453a.268.268 0 00-.127.225v10.134h-.295c-.623 0-1.129.483-1.129 1.08v2.775h-.48a.276.276 0 00-.283.27v1.493c0 .149.126.27.282.27H3.5v.715c0 .298.253.54.565.54H5.56a.553.553 0 00.565-.54v-.715h7.75v.715c0 .298.253.54.565.54h1.495a.553.553 0 00.565-.54v-.715h1.218a.276.276 0 00.282-.27v-1.493a.276.276 0 00-.282-.27h-.481v-2.775zM5.843 6.029h8.314v2.66H5.843v-2.66zm-.194 8.928h-.964a.522.522 0 01-.533-.51v-.013c0-.282.238-.51.533-.51h.964c.295 0 .534.228.534.51v.013c0 .281-.239.51-.534.51zm6.27.15H8.08a.552.552 0 01-.564-.54v-3.42c0-.299.252-.54.564-.54h3.838c.312 0 .564.241.564.54v3.42c0 .299-.252.54-.564.54zm3.396-.15h-.964a.523.523 0 01-.534-.51v-.013c0-.282.239-.51.534-.51h.964c.294 0 .533.228.533.51v.013c0 .281-.239.51-.533.51z"
                />
            </g>
        </svg>
    );
};

TruckIcon.displayName = 'TruckIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export const TruckIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.transparent,
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getTruckIconProps = (
    preset:
        | 'default'
        | 'assignment-events'
        | 'assignment-normal'
        | 'assignment-selected'
        | 'assignment-not-in-time-normal'
        | 'assignment-not-in-time-selected',
): PropsT => {
    switch (preset) {
        case 'assignment-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-events': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandAccent,
            };
        }
        case 'assignment-not-in-time-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
        case 'assignment-not-in-time-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'default':
        default: {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
    }
};

export { storyProps };
export default TruckIcon;
