import { SpotRequestsInboxQuickFilterEnum, SpotRequestsQuickTabEnum } from './constants';

export enum QueryKeysEnum {
    spotRequestsPage = 'spot-requests-page',
    spotRequestsSort = 'spot-requests-sort',
    spotRequestsFilters = 'spot-requests-filters',
    spotRequestsSourceTab = 'spot-requests-source-tab',
}

export enum QueryFiltersKeysEnum {
    inboxQuickFilter = 'inbox-quick-filter',
    tab = 'tab',

    loadBoardOnlyAvailableToAccept = 'load-board-only-available-to-accept',

    searchText = 'search-text',

    dictTrailerTypeId = 'dict-trailer-type-id',
    createdDateFrom = 'created-date-from',
    createdDateTo = 'created-date-to',

    address = 'address',

    pickupDateFrom = 'pickup-docking-hours-from',
    pickupDateTo = 'pickup-docking-hours-to',

    dropOffDateFrom = 'drop-off-docking-hours-from',
    dropOffDateTo = 'drop-off-docking-hours-to',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.tab]: SpotRequestsQuickTabEnum;
    [QueryFiltersKeysEnum.inboxQuickFilter]: SpotRequestsInboxQuickFilterEnum;
    [QueryFiltersKeysEnum.loadBoardOnlyAvailableToAccept]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dictTrailerTypeId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.address]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
}>;
