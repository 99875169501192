import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProfileRoutesEnum } from 'common/constants';
import ProfileBasicInformation from './ProfileBasicInformation/ProfileBasicInformation';
import ProfileDocuments from './ProfileDocuments/ProfileDocuments';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import useContainerScroll from 'common/utils/hooks/useContainerScroll';
import getScrollbarWidth from 'common/utils/get-scroll-bar-width';
import classNames from 'classnames/bind';
import styles from './BaseProfileLayout.scss';
import NavigationTabs, {
    NavigationTabConfigT,
    NavigationTabsPropsT,
} from 'common/components/NavigationTabs/NavigationTabs';
import history from 'common/utils/history';
import { useTranslation } from 'react-i18next';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';

import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { useSelector } from 'react-redux';
import { selectCompanyVerificationProblems } from 'common/store/company/selectors';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import isString from 'lodash/isString';
import useRemoteFormActions from 'common/utils/hooks/useRemoteFormActions';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import RemoteFormActions from 'common/components/forms/RemoteFormActions/RemoteFormActions';

const cx = classNames.bind(styles);

export type ProfileAdditionalTabT = {
    pathname: string;
    hasWarning: boolean;
    renderTitle: () => React.ReactElement;
    testSelector: string;
    node: React.ReactNode;
};

type PropsT = {
    additionalTabs: Array<ProfileAdditionalTabT>;
};

const BaseProfileLayout: React.FC<PropsT> = React.memo((props) => {
    const { additionalTabs } = props;

    const remoteFormActions = useRemoteFormActions();

    const { containerRef, onContainerScroll, scrollTop } = useContainerScroll();

    const scrollBarWidth = React.useMemo(() => getScrollbarWidth(containerRef?.current), [containerRef?.current]);

    const verificationProblems = useSelector(selectCompanyVerificationProblems);

    const { t } = useTranslation();

    const warningBadgeNode = <NotificationLabel isShowWarning theme={NotificationLabelThemeEnum.red} />;

    const tabsConfig = React.useMemo(
        (): Array<NavigationTabConfigT | null> => [
            {
                pathname: ProfileRoutesEnum.basic,
                to: ProfileRoutesEnum.basic,
                badge: verificationProblems.anyBasicInformation ? warningBadgeNode : null,
                render: () => t('common:profile-page.tabs.basic-information'),
                testSelector: 'basic',
            },
            {
                pathname: ProfileRoutesEnum.documents,
                to: ProfileRoutesEnum.documents,
                badge: verificationProblems.anyDocument ? warningBadgeNode : null,
                render: () => t('common:profile-page.tabs.documents'),
                testSelector: 'documents',
            },
            ...additionalTabs.map((additionalTab) => {
                return {
                    pathname: additionalTab.pathname,
                    to: additionalTab.pathname,
                    badge: additionalTab.hasWarning ? warningBadgeNode : null,
                    render: additionalTab.renderTitle,
                    testSelector: additionalTab.testSelector,
                };
            }),
        ],
        [verificationProblems, additionalTabs],
    );

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        history.push({
            ...(isString(to) ? { pathname: to } : to),
            search: history.location.search,
        });
    };

    return (
        <RemoteFormActionsContext.Provider value={remoteFormActions}>
            <PageTitle title={t('common:page-titles.profile')} />
            <ScrollableContent
                scrollContainerRef={containerRef}
                onContainerScroll={onContainerScroll}
                stickyHeaderNode={
                    <TopBar isSticky isWhite={scrollTop > 10} scrollBarWidth={scrollBarWidth}>
                        <TopBarContent
                            title={t('common:profile-page.title')}
                            rightNode={
                                <>
                                    <Switch>
                                        <Route path={ProfileRoutesEnum.basic}>
                                            <RemoteFormActions className={cx('header__controls')} />
                                        </Route>
                                    </Switch>
                                    <NotificationsBarTrigger />
                                </>
                            }
                        />
                    </TopBar>
                }
            >
                <ContentMargins>
                    <NavigationTabs tabsConfig={tabsConfig} onSelectTab={handleSelectTab} />
                    <Switch>
                        <Route path={ProfileRoutesEnum.basic}>
                            <ProfileBasicInformation hasVerificationProblem={verificationProblems.any} />
                        </Route>
                        <Route path={ProfileRoutesEnum.documents}>
                            <ProfileDocuments hasVerificationProblem={verificationProblems.any} />
                        </Route>
                        {...additionalTabs.map((additionalTab, index) => {
                            return (
                                <Route key={index} path={additionalTab.pathname}>
                                    {additionalTab.node}
                                </Route>
                            );
                        })}
                    </Switch>
                </ContentMargins>
            </ScrollableContent>
        </RemoteFormActionsContext.Provider>
    );
});

export default BaseProfileLayout;
