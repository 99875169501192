import RouteIcon from 'common/icons/RouteIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import OptionsIcon from 'common/icons/OptionsIcon';
import TollIcon from 'common/icons/TollIcon';
import FuelIcon from 'common/icons/FuelIcon';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import UrgentIcon from 'common/icons/UrgentIcon';
import EcoIcon from 'common/icons/EcoIcon';
import FeeIcon from 'common/icons/FeeIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

export const useCommonPriceDetails = () => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            lineHaul: {
                iconNode: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                title: t('common:price-details.line-haul.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.line-haul.tooltip')}
                    </TooltipContent>
                ),
            },
            teamDrive: {
                iconNode: (
                    <TeamDriveIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                title: t('common:price-details.team-drive.title'),
            },
            roadTaxes: {
                iconNode: (
                    <TollIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                title: t('common:price-details.road-taxes.title'),
            },
            fuelCost: {
                iconNode: <FuelIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />,
                title: t('common:price-details.fuel-price.title'),
            },

            additionalServices: {
                iconNode: (
                    <OptionsIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                ),
                title: t('common:price-details.additional-services.title'),
            },

            layoverCost: {
                iconNode: (
                    <TimeWindowIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                ),
                title: t('common:price-details.layover.title'),
            },

            urgentOverprice: {
                iconNode: <UrgentIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.squash} />,
                title: t('common:price-details.express-surcharge.title'),
            },

            greenSurcharge: {
                iconNode: <EcoIcon size={DEFAULT_ICON_SIZE} />,
                title: t('common:price-details.green-surcharge.title'),
            },

            serviceFee: {
                iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:price-details.service-fee.title'),
            },

            tranziitServiceFee: {
                iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:price-details.tranziit-service-fee.title'),
            },
        };
    }, [t]);
};
