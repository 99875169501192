import { useMemo } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { isNonNil } from 'common/utils';
import pickBy from 'lodash/pickBy';

export const useRestQueryFilters = (sourceQueryFilters: Record<string, any>, omitQueryKeys: Array<string>) => {
    return useMemo(() => {
        const queryFilters = pickBy(sourceQueryFilters, isNonNil);
        if (isEmpty(queryFilters)) {
            return queryFilters;
        }

        const restQueryFilters = omit(queryFilters, omitQueryKeys);
        return restQueryFilters;
    }, [sourceQueryFilters, omitQueryKeys]);
};
