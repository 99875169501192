import { TransportOrderTypeEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    orderType = 'orderType',
    receiveDates = 'receiveDates',

    dictTrailerId = 'dictTrailerId',
    plateNumber = 'plateNumber',

    address = 'address',

    driverId = 'driverId',
    driverName = 'driverName',
    minPrice = 'minPrice',
    maxPrice = 'maxPrice',

    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',
}

export type FormValuesT = {
    [FieldsEnum.orderType]: TransportOrderTypeEnum | null;
    [FieldsEnum.receiveDates]: DateRangeT | null;

    [FieldsEnum.dictTrailerId]: string | null;
    [FieldsEnum.plateNumber]: string | null;

    [FieldsEnum.address]: string | null;

    [FieldsEnum.driverId]: DriverIdT | null;
    [FieldsEnum.driverName]: string | null;
    [FieldsEnum.minPrice]: string;
    [FieldsEnum.maxPrice]: string;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;
};
