import React from 'react';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';

type PropsT = {
    hasSelectedFilters: boolean;
    hasVerificationProblem?: boolean;
    goToProfile?: () => void;
    hasSelectedStatus: boolean;
    clearAllFilters: () => void;
};

const EmptyMessage: React.FC<PropsT> = (props) => {
    const { hasSelectedFilters, hasVerificationProblem, hasSelectedStatus, goToProfile, clearAllFilters } = props;

    const { t } = useTranslation();

    if (hasSelectedFilters) {
        return (
            <TableMessage
                iconType={TableMessageIconsEnum.notFound}
                title={t('common:transport-orders-page.table.messages.not-found.title')}
                description={t('common:transport-orders-page.table.messages.not-found.description')}
                isShowAction
                actionTitle={t('common:transport-orders-page.table.messages.not-found.action')}
                actionTheme={ButtonThemeEnum.secondary}
                onActionClick={clearAllFilters}
                testSelector="not-found"
            />
        );
    }

    if (hasSelectedStatus) {
        return (
            <TableMessage
                testSelector="empty"
                iconType={TableMessageIconsEnum.empty}
                title={t('common:transport-orders-page.table.messages.not-found-status.title')}
                description=""
                isShowAction={false}
            />
        );
    }

    if (hasVerificationProblem) {
        return (
            <TableMessage
                testSelector="verification-problem"
                iconType={TableMessageIconsEnum.warning}
                title={t('common:transport-orders-page.table.messages.verification-problem.title')}
                description={t('common:transport-orders-page.table.messages.verification-problem.description')}
                isShowAction
                actionTitle={t('common:transport-orders-page.table.messages.verification-problem.action')}
                actionTheme={ButtonThemeEnum.secondary}
                onActionClick={goToProfile || noop}
            />
        );
    }

    return (
        <TableMessage
            iconType={TableMessageIconsEnum.empty}
            title={t('common:transport-orders-page.table.messages.empty.title')}
            testSelector="empty"
            isShowAction={false}
        />
    );
};

export default EmptyMessage;
