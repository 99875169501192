import * as React from 'react';
import history from 'common/utils/history';
import { useLocation } from 'react-router-dom';

type NextLocationT = Parameters<typeof history.push>[0] | string;

export const useHistoryPush = () => {
    const location = useLocation();

    const historyPush = React.useCallback(
        (event: React.MouseEvent, nextLocation: NextLocationT) => {
            if (event.ctrlKey || event.metaKey) {
                const pathname =
                    typeof nextLocation === 'string' ? nextLocation : nextLocation.pathname || location.pathname;
                const search = typeof nextLocation === 'string' ? '' : nextLocation.search || '';

                window.open(`/#${pathname}${search}`, '_blank');
                return;
            }

            history.push(nextLocation);
        },
        [location],
    );

    return {
        historyPush,
    };
};
