import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TransportOrderStatusPill.scss';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { TransportOrderStatusEnum, ApiTransportOrderStatusT } from 'common/utils/api/models';
import TransportOrderStatus from 'common/components/status/TransportOrderStatus/TransportOrderStatus';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    status?: ApiTransportOrderStatusT;
    isArchived?: boolean;
    isSpot?: boolean;
    isSymmetrical?: boolean;
};

const PILL_THEME_BY_STATUS: Record<ApiTransportOrderStatusT, PillLabelThemeEnum> = {
    [TransportOrderStatusEnum.assetsUnassigned]: PillLabelThemeEnum.tomatoRed,
    [TransportOrderStatusEnum.driverUnassigned]: PillLabelThemeEnum.tomatoRed,
    [TransportOrderStatusEnum.assigned]: PillLabelThemeEnum.slate,
    [TransportOrderStatusEnum.inTransit]: PillLabelThemeEnum.slate,
    [TransportOrderStatusEnum.done]: PillLabelThemeEnum.brandDark,
    [TransportOrderStatusEnum.canceled]: PillLabelThemeEnum.slate,
    [TransportOrderStatusEnum.declined]: PillLabelThemeEnum.slate,
    [TransportOrderStatusEnum.unknown]: PillLabelThemeEnum.light,
};

const TransportOrderStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isArchived, isSpot, isSymmetrical } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return (
        <>
            {isSpot && (
                <PillLabel
                    isNoWrap
                    isSymmetrical={isSymmetrical}
                    className={cx('pill', 'pill--is-spot')}
                    theme={PillLabelThemeEnum.grey}
                >
                    {t('common:transport-order-spot')}{' '}
                </PillLabel>
            )}
            <PillLabel isNoWrap isSymmetrical={isSymmetrical} theme={PILL_THEME_BY_STATUS[status]}>
                <TransportOrderStatus status={status} />
            </PillLabel>
            {isArchived && (
                <PillLabel
                    isNoWrap
                    isSymmetrical={isSymmetrical}
                    className={cx('pill', 'pill--is-archive')}
                    theme={PillLabelThemeEnum.grey}
                >
                    {t('common:transport-order-archived')}{' '}
                </PillLabel>
            )}
        </>
    );
});

export default TransportOrderStatusPill;
