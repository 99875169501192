import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectTrailersByIds,
    selectTrailersDetailsByIds,
    selectUpdateTrailersRequestStatus,
} from 'common/store/trailers/selectors';
import EditTrailerSidebarContent from './EditTrailerSidebarContent/EditTrailerSidebarContent';
import TrailerDetailsSidebarContent from './TrailerDetailsSidebarContent/TrailerDetailsSidebarContent';
import { SetUnavailableVehicleQueryT, TrailerStatusEnum } from 'common/utils/api/models';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { selectPermissions } from 'common/store/auth/selectors';
import SetUnavailableAssetConfirmationModal, {
    SetUnavailableAssetConfirmationDataT,
} from 'common/layouts/dialogs/SetUnavailableAssetConfirmationModal/SetUnavailableAssetConfirmationModal';
import { AssetTypeEnum } from 'common/constants';
import ArchiveAssetConfirmationModal, {
    ArchiveAssetConfirmationDataT,
} from 'common/layouts/dialogs/ArchiveAssetConfirmationModal/ArchiveAssetConfirmationModal';
import ActivateAssetDialogModal, {
    ActivateAssetConfirmationDataT,
} from 'common/layouts/dialogs/ActivateAssetDialogModal/ActivateAssetDialogModal';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { selectSetUnavailableVehicleRequestStatus } from 'common/store/vehicle-schedules/selectors';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { activationTrailersRequest, archiveTrailersRequest } from 'common/store/trailers/actions';
import { setUnavailableVehicleRequest } from 'common/store/vehicle-schedules/actions';

type PropsT = {
    trailerId: TrailerIdT;
    partnerId: PartnerIdT;
    onGoBack?: () => void;
    onClose?: () => void;
    isShowMap: boolean;
    onToggleShowMap: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    isReadOnly?: boolean;
    isDisableShowOnMap?: boolean;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
};

const CommonEditableTrailerDetailsLayout: React.FC<PropsT> = (props) => {
    const {
        isReadOnly,
        isDisableShowOnMap,
        onGoBack,
        trailerId,
        partnerId,
        onClose,
        isShowMap,
        onToggleShowMap,
        setNeedCloseConfirmation,
        onOpenTruckDetails,
        onOpenTrailerDetails,
    } = props;

    const trailerById = useSelector(selectTrailersByIds(partnerId));

    const permissions = useSelector(selectPermissions);

    const [isActiveEditing, setActiveEditing] = React.useState<boolean>(false);

    const trailerDetailsById = useSelector(selectTrailersDetailsByIds(partnerId));

    const updateRequestStatus = useSelector(selectUpdateTrailersRequestStatus(partnerId));

    React.useEffect(() => {
        if (updateRequestStatus.ok) {
            setActiveEditing(false);
        }
    }, [updateRequestStatus.ok]);

    const dispatch = useDispatch();

    const detailsById = useSelector(selectTrailersDetailsByIds(partnerId));
    const handleEdit = () => {
        const trailerDetails = detailsById[trailerId as TrailerIdT];

        if (trailerDetails?.status === TrailerStatusEnum.inTransit) {
            dispatch(
                addAlert(
                    new CommonAnyAlert({
                        type: CommonAlertTypeEnum.trailerNotEditable,
                        data: {},
                    }),
                ),
            );
            return;
        }

        setActiveEditing(true);
    };

    const activateAssetConfirmation = useModalDialog<ActivateAssetConfirmationDataT>();
    const handleActivate = (trailerId: TrailerIdT | null): void => {
        if (!trailerId) {
            return;
        }

        const trailerDetails = trailerDetailsById[trailerId];

        activateAssetConfirmation.setData({
            companyId: partnerId,
            assets: [
                {
                    id: trailerId,
                    plateNumber: trailerDetails?.plateNumber || '',
                    ratePerKm: trailerDetails?.ratePerKm || null,
                },
            ],
        });
    };

    const archiveAssetConfirmation = useModalDialog<ArchiveAssetConfirmationDataT>();
    const handleArchive = (trailerId: TrailerIdT | null): void => {
        if (!trailerId) {
            return;
        }

        const trailer = trailerById[trailerId];

        archiveAssetConfirmation.setData({
            companyId: partnerId,
            assets: [
                {
                    id: trailerId,
                    plateNumber: trailer?.plateNumber || '',
                },
            ],
        });
    };

    useCloseModalDialogAfterRequest(updateRequestStatus, [activateAssetConfirmation, archiveAssetConfirmation]);

    const setUnavailableStatus = useSelector(selectSetUnavailableVehicleRequestStatus);

    const setUnavailableModalConfirmation = useModalDialog<SetUnavailableAssetConfirmationDataT>();
    const handleSetUnavailable = (trailerId: TrailerIdT, query: SetUnavailableVehicleQueryT): void => {
        const trailer = trailerById[trailerId];

        setUnavailableModalConfirmation.setData({
            companyId: partnerId,
            query: {
                ...query,
                trailerId,
            },
            assets: [
                {
                    plateNumber: trailer?.plateNumber || '',
                },
            ],
        });
    };

    useCloseModalDialogAfterRequest(setUnavailableStatus, [setUnavailableModalConfirmation]);

    const handleConfirmActivate = (data: ActivateAssetConfirmationDataT, ratesPerKm: Array<number | null>): void => {
        const ids = data.assets.map((asset) => asset.id);
        dispatch(activationTrailersRequest(ids, ratesPerKm, partnerId));
    };

    const handleConfirmArchive = (data: ArchiveAssetConfirmationDataT): void => {
        const ids = data.assets.map((asset) => asset.id);
        dispatch(archiveTrailersRequest(ids, data.companyId));
    };

    const handleSetUnavailableConfirmation = (data: SetUnavailableAssetConfirmationDataT): void => {
        const { companyId, query } = data;

        dispatch(setUnavailableVehicleRequest(query, companyId));
    };

    return (
        <>
            {isActiveEditing ? (
                <EditTrailerSidebarContent
                    onGoBack={onGoBack}
                    partnerId={partnerId}
                    trailerId={trailerId}
                    onClose={onClose}
                    onCancel={() => {
                        setActiveEditing(false);
                    }}
                    setNeedCloseConfirmation={setNeedCloseConfirmation}
                    onOpenTruckDetails={onOpenTruckDetails}
                    onOpenTrailerDetails={onOpenTrailerDetails}
                />
            ) : (
                <TrailerDetailsSidebarContent
                    onGoBack={onGoBack}
                    partnerId={partnerId}
                    trailerId={trailerId}
                    isShowMap={isShowMap}
                    onToggleShowMap={onToggleShowMap}
                    onClose={onClose}
                    onActivate={handleActivate}
                    onArchive={handleArchive}
                    onEdit={handleEdit}
                    canEditTrailers={permissions.canEditTrailers}
                    onSetUnavailable={handleSetUnavailable}
                    isReadOnly={isReadOnly}
                    isDisableShowOnMap={isDisableShowOnMap}
                    onOpenTruckDetails={onOpenTruckDetails}
                />
            )}
            <SetUnavailableAssetConfirmationModal
                assetType={AssetTypeEnum.trailer}
                data={setUnavailableModalConfirmation.data}
                onConfirm={handleSetUnavailableConfirmation}
                onCancel={setUnavailableModalConfirmation.onCancel}
                onClose={setUnavailableModalConfirmation.onClose}
                requestStatus={setUnavailableStatus}
            />
            <ArchiveAssetConfirmationModal
                assetType={AssetTypeEnum.trailer}
                data={archiveAssetConfirmation.data}
                onConfirm={handleConfirmArchive}
                onCancel={archiveAssetConfirmation.onCancel}
                onClose={archiveAssetConfirmation.onClose}
                requestStatus={updateRequestStatus}
            />
            <ActivateAssetDialogModal
                assetType={AssetTypeEnum.trailer}
                data={activateAssetConfirmation.data}
                onConfirm={handleConfirmActivate}
                onCancel={activateAssetConfirmation.onCancel}
                onClose={activateAssetConfirmation.onClose}
                requestStatus={updateRequestStatus}
            />
        </>
    );
};

export default CommonEditableTrailerDetailsLayout;
