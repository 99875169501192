import React from 'react';
import classNames from 'classnames/bind';

import styles from './DriversAssignmentForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { assign, resetDriverAssignment } from 'common/store/driver-assigment/actions';
import { logWarning } from 'common/utils/logger';
import { selectAssignmentRequestStatus } from 'common/store/driver-assigment/selectors';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { isNonNil } from 'common/utils';
import DriverSuggestInput from 'common/layouts/TransportOrderDetailsPageLayout/DriversAssignmentForm/DriverSuggestInput/DriverSuggestInput';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

const cx = classNames.bind(styles);

type PropsT = {
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onCancel: () => void;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.driverId]: null,
    [FieldsEnum.teamDriverId]: null,
};

const DriversAssignmentForm: React.FC<PropsT> = (props) => {
    const { onCancel, transportOrderId, transportOrderDetails } = props;

    const tourId = transportOrderDetails?.tourInfo?.id || null;
    const driverId = transportOrderDetails?.drivers?.[0]?.id;
    const teamDriverId = transportOrderDetails?.drivers?.[1]?.id;
    const isTeamDrive = !!transportOrderDetails?.tourInfo?.teamDrive;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(resetDriverAssignment());
        };
    }, []);

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, isTeamDrive);
    }, [t, isTeamDrive]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
            [FieldsEnum.driverId]: driverId || null,
            [FieldsEnum.teamDriverId]: teamDriverId || null,
        };

        const errors = validateForm(t, values, isTeamDrive);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const driverIds: DriverIdT[] = [
                values[FieldsEnum.driverId],
                isTeamDrive ? values[FieldsEnum.teamDriverId] : null,
            ].filter(isNonNil);

            if (!driverIds.length) {
                logWarning('empty driverIds!');
                return;
            }

            if (!tourId) {
                logWarning('empty tourId!');
                return;
            }

            dispatch(assign(transportOrderId, driverIds));

            formikHelpers.setTouched({});
        },
    });

    return (
        <div className={cx('wrap')}>
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('title')}>{t('common:driver-assignment.title')}</div>
                <div className={cx('form')}>
                    <FormikField
                        name={FieldsEnum.driverId}
                        error={formik.errors[FieldsEnum.driverId]}
                        meta={formik.getFieldMeta(FieldsEnum.driverId)}
                        label={t('common:driver-assignment.fields.driver.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DriverSuggestInput
                                value={formik.values[FieldsEnum.driverId]}
                                tourId={tourId}
                                onSelect={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                inputPlaceholder={t('common:driver-assignment.fields.driver.input-placeholder')}
                                placeholder={t('common:driver-assignment.fields.driver.placeholder')}
                            />
                        )}
                    </FormikField>
                    {isTeamDrive && (
                        <FormikField
                            name={FieldsEnum.teamDriverId}
                            error={formik.errors[FieldsEnum.teamDriverId]}
                            meta={formik.getFieldMeta(FieldsEnum.teamDriverId)}
                            label={t('common:driver-assignment.fields.team-driver.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DriverSuggestInput
                                    value={formik.values[FieldsEnum.teamDriverId]}
                                    tourId={tourId}
                                    onSelect={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                    inputPlaceholder={t(
                                        'common:driver-assignment.fields.team-driver.input-placeholder',
                                    )}
                                    placeholder={t('common:driver-assignment.fields.team-driver.placeholder')}
                                />
                            )}
                        </FormikField>
                    )}
                </div>
                <div className={cx('actions')}>
                    <Button
                        theme={ButtonThemeEnum.secondary}
                        className={cx('action', 'action--cancel')}
                        onClick={onCancel}
                    >
                        {t('common:driver-assignment.actions.cancel')}
                    </Button>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        className={cx('action', 'action--assign')}
                        isLoading={assignmentRequestStatus.loading}
                        type="submit"
                    >
                        {t('common:driver-assignment.actions.assign')}
                    </Button>
                </div>
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            </form>
        </div>
    );
};

export default DriversAssignmentForm;
