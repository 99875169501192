import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ClientPage.scss';
import LeftMenuLayout from '../LeftMenuLayout/LeftMenuLayout';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routesEnum } from 'carrier/constants';
import AccountPage from 'common/layouts/AccountPage/AccountPage';
import TrucksPage from '../TrucksPage/TrucksPage';
import TrailersPage from '../TrailersPage/TrailersPage';
import TeamPage from 'common/layouts/TeamPage/TeamPage';
import DriversListPage from '../DriversListPage/DriversListPage';
import TransportOrdersPage from '../TransportOrdersPage/TransportOrdersPage';
import User from 'common/components/User/User';
import Company from 'common/components/Company/Company';
import RedirectPendingUser from 'common/components/RedirectPendingUser/RedirectPendingUser';
import SpotRequestsPage from 'carrier/layouts/SpotRequestsPage/SpotRequestsPage';
import ProfilePage from '../ProfilePage/ProfilePage';

const cx = classNames.bind(styles);

type PropsT = {};

const ClientPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <>
            <User />
            <RedirectPendingUser />
            <Company />
            <LeftMenuLayout>
                <Switch>
                    <Route exact path={routesEnum.home}>
                        <Redirect to={routesEnum.transportOrders} />
                    </Route>
                    <Route path={routesEnum.profile}>
                        <ProfilePage />
                    </Route>
                    <Route exact path={routesEnum.account}>
                        <AccountPage />
                    </Route>
                    <Route path={routesEnum.trucks}>
                        <TrucksPage />
                    </Route>
                    <Route path={routesEnum.trailers}>
                        <TrailersPage />
                    </Route>
                    <Route path={routesEnum.drivers}>
                        <DriversListPage />
                    </Route>
                    <Route path={routesEnum.spotRequests}>
                        <SpotRequestsPage />
                    </Route>
                    <Route path={routesEnum.team}>
                        <TeamPage />
                    </Route>
                    <Route path={routesEnum.transportOrders}>
                        <TransportOrdersPage />
                    </Route>
                    <Route path="*">
                        <Redirect to={routesEnum.notFound} />
                    </Route>
                </Switch>
            </LeftMenuLayout>
        </>
    );
});

export default ClientPage;
