import { QueryKeysEnum } from 'common/constants';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { logWarning } from './logger';
import moment from 'moment';
import { QueryFiltersT } from 'common/models';
import pickBy from 'lodash/pickBy';
import { isNonNil } from 'common/utils/index';
import { decodeArray, encodeArray, encodeJson, QueryParamConfig } from 'serialize-query-params';
import { decodeJson } from 'use-query-params';
import * as Serialize from 'serialize-query-params';
import isEqual from 'lodash/isEqual';

const STRINGIFY_QUERY_KEYS = [
    QueryKeysEnum.ordersFilters,
    QueryKeysEnum.ordersFilters,
    QueryKeysEnum.orderCreationAdditionalServices,
    QueryKeysEnum.ratesFromCountryCodesSearch,
    QueryKeysEnum.ratesToCountryCodesSearch,
    QueryKeysEnum.transportOrdersFilters,
    QueryKeysEnum.dispatchesFilters,
];

export const parseQuery = (search: string): any => {
    const query = qs.parse(search.slice(1));

    STRINGIFY_QUERY_KEYS.forEach((queryKey) => {
        const value = query[queryKey];

        if (value) {
            try {
                query[queryKey] = JSON.parse(value as string);
            } catch (error) {
                logWarning(`parse query error: ${error}`);
            }
        }
    });

    return query;
};

type BaseStringifiedParsedQueryT = Record<string, string[] | number[] | string | number | boolean | null>;

export const parseStringifiedQuery = <T extends BaseStringifiedParsedQueryT>(
    queryValue: string | null | undefined,
): T => {
    if (!queryValue) {
        return {} as T;
    }

    try {
        return JSON.parse(queryValue);
    } catch (error) {
        logWarning(`parse query "${queryValue}", error: ${error}`);
        return {} as T;
    }
};

export const formatStringifiedQuery = (rawQuery: BaseStringifiedParsedQueryT): string | null => {
    try {
        return JSON.stringify(rawQuery);
    } catch (error) {
        logWarning(`format query "${rawQuery}" error: ${error}`);
        return null;
    }
};

// TODO use instead any BaseStringifiedParsedQueryT
export const formatQuery = (rawQuery: any): string => {
    const query = {
        ...rawQuery,
    };

    STRINGIFY_QUERY_KEYS.forEach((queryKey) => {
        const value = query[queryKey];
        if (!value) {
            return;
        }

        if (!isEmpty(value)) {
            try {
                query[queryKey] = JSON.stringify(value as string);
            } catch (error) {
                logWarning(`format query error: ${error}`);
            }
        } else {
            // delete empty objects and array
            query[queryKey] = undefined;
        }
    });

    return `?${qs.stringify(query, { skipNulls: true })}`;
};

export const parseQueryValueWithSeparator = (rawValue: string | null | undefined, separator: string): string[] => {
    const value = rawValue || '';
    if (!value || value === separator) {
        return [];
    }

    return value.split(separator).filter((value) => Boolean(value));
};

const utcOffset = moment().utcOffset();

export const parseDateFromQuery = (queryDate: string | null | undefined) => {
    const momentDate = moment(queryDate).utcOffset(utcOffset);

    return new Date(momentDate.format('YYYY-MM-DD'));
};

export const formateStartDateForQuery = (date: Date | undefined | null) => {
    return moment(date).startOf('day').format();
};

export const formateEndDateForQuery = (date: Date | undefined | null) => {
    return moment(date).endOf('day').format();
};

export const parseRelativeTimeFromQuery = (queryDate: string | null | undefined) => {
    const momentDate = moment(queryDate).utcOffset(utcOffset);

    return momentDate.valueOf() - momentDate.clone().startOf('day').valueOf();
};

/**
 * @deprecated
 */
export const mergeQueryFilters = (prevQueryFilters: QueryFiltersT, newQueryFilters: QueryFiltersT): QueryFiltersT => {
    const filteredNewQueryFilters = pickBy(newQueryFilters, isNonNil);

    return {
        ...prevQueryFilters,
        ...filteredNewQueryFilters,
    };
};

/**
 * @deprecated
 */
export const clearEmptyValues = <T extends {}>(obj: T): void => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));
};

/*
For sidebars
 */
export const JSONArrayParam = {
    encode: <T>(objArray: Array<T> | null) => {
        if (!objArray) {
            return encodeArray(objArray);
        }

        const strArray = objArray
            .map((obj) => {
                return encodeJson(obj);
            })
            .filter(isNonNil);

        // @ts-expect-error
        return encodeArray(strArray?.length === 1 ? strArray[0] : strArray);
    },
    decode: <T>(arrayStr: string | (string | null)[] | null | undefined): Array<T> => {
        const decodedArray = decodeArray(arrayStr);
        if (!decodedArray) {
            return [];
        }

        if (typeof decodedArray === 'string') {
            const objStr = decodeJson(decodedArray);
            if (!objStr) {
                return [];
            }

            return [objStr as T];
        }

        return decodedArray
            .map((objStr): T | null => {
                return decodeJson(objStr);
            })
            .filter(isNonNil);
    },
    equals: <T>(valueA: T | null | undefined, valueB: T | null | undefined) => {
        return isEqual(valueA, valueB);
    },
};

/*
For paging
 */
export const createPageNumberParam = (initValue = 0) => {
    const params: QueryParamConfig<number> = {
        encode: Serialize.encodeNumber,
        decode: (value) => {
            return Serialize.decodeNumber(value) || initValue;
        },
    };

    return params;
};

/*
For filters
 */
export const createJsonParams = <T>(initValue: T) => {
    const params: QueryParamConfig<T> = {
        encode: Serialize.encodeJson,
        decode: (value) => {
            return Serialize.decodeJson(value) || initValue;
        },
        equals: (valueA: T | null | undefined, valueB: T | null | undefined) => {
            return isEqual(valueA, valueB);
        },
    };

    return params;
};

export enum SortDirectionEnum {
    DESC = 'DESC',
    ASC = 'ASC',
}

/*
For sorts
 */
const PAGE_SORT_SEPARATOR = ',';

export type PageSortT<T extends string> = {
    direction: SortDirectionEnum;
    value: T;
};

export const createSortParams = <T extends string>(initData: PageSortT<T>) => {
    const params: QueryParamConfig<PageSortT<T>> = {
        encode: (data) => {
            return Serialize.encodeDelimitedArray([data.value, data.direction], PAGE_SORT_SEPARATOR);
        },
        decode: (data) => {
            const decodedData = Serialize.decodeDelimitedArray(data, PAGE_SORT_SEPARATOR) || [];
            if (!decodedData) {
                return initData;
            }

            const [value, direction] = decodedData;
            return {
                value: (value as T) || initData.value,
                direction: (direction as PageSortT<T>['direction']) || initData.direction,
            };
        },
        equals: (valueA: PageSortT<T> | null | undefined, valueB: PageSortT<T> | null | undefined) => {
            return isEqual(valueA, valueB);
        },
    };

    return params;
};
