import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './Widget.scss';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

export type PropsT = {
    title: React.ReactNode;
    afterTitleNode?: React.ReactNode;
    titleTooltipNode?: React.ReactNode;
    rightNode?: React.ReactNode;
    className?: string;
};

const Widget: React.FC<PropsT> = React.memo((props) => {
    const { title, afterTitleNode, titleTooltipNode, rightNode, className, children } = props;

    return (
        <div className={cs(cx('wrap'), className)}>
            <div className={cx('header')}>
                <div className={cx('left-node')}>
                    <div className={cx('title')}>{title}</div>
                    {titleTooltipNode && (
                        <Tooltip
                            className={cx('tooltip')}
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                    {titleTooltipNode}
                                </TooltipContent>
                            }
                        >
                            {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                        </Tooltip>
                    )}
                    {afterTitleNode}
                </div>
                <div className={cx('right-node')}>{rightNode}</div>
            </div>
            <div className={cx('content')}>{children}</div>
        </div>
    );
});

export default Widget;
