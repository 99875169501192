import * as React from 'react';
import { formatTimeInterval } from 'common/utils/time';
import { TransportOrderT } from 'common/store/transport-orders/models';
import LocationCellLayout from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import { DirectionEnum } from 'common/constants';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';

type PropsT = {
    order: TransportOrderT;
    type: DirectionEnum;
};

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { order, type } = props;

    let time = null;
    let address = null;

    if (type === DirectionEnum.destination) {
        address = order.destination;
        time = formatTimeInterval(order.dropOffDockingHoursFrom, order.dropOffDockingHoursTo);
    }
    if (type === DirectionEnum.origin) {
        address = order.origin;
        time = formatTimeInterval(order.pickupDockingHoursFrom, order.pickupDockingHoursTo);
    }

    return <LocationCellLayout address={<LocationLabel format="zip_city_country" location={address} />} time={time} />;
});

export default LocationCell;
