import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const HourglassIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor, ...rest } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            {...rest}
        >
            <defs>
                <path id="aqzl7i901a" d="M6.3 9.369l-2.7-2.7-2.7 2.7V11.7h5.4z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <g transform="translate(6.4 3.7)">
                    <use fill={strokeColor} xlinkHref="#aqzl7i901a" />
                    <use fill={fillColor} fillOpacity="0.7" xlinkHref="#aqzl7i901a" />
                </g>
                <path
                    fill={strokeColor}
                    d="M6.4 16.3h7.2v-3.6L10.9 10l2.7-2.7V3.7H6.4v3.6L9.1 10l-2.7 2.7v3.6zm.9-9.369V4.6h5.4v2.331l-2.7 2.7-2.7-2.7zm0 6.138l2.7-2.7 2.7 2.7V15.4H7.3v-2.331z"
                />
            </g>
        </svg>
    );
};

HourglassIcon.displayName = 'HourglassIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.tomatoRed,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default HourglassIcon;
