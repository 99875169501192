import { eventChannel } from 'redux-saga';
import { Stomp } from '@stomp/stompjs';
import { logWarning } from 'common/utils/logger';
import { selectCurrentUser, selectUserSignedUp } from 'common/store/user/selectors';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import {
    fetchNotificationsError,
    fetchNotificationsPageBegin,
    fetchNotificationsPageSuccess,
    fetchUnreadNotificationsBegin,
    fetchUnreadNotificationsError,
    fetchUnreadNotificationsSuccess,
    markAsReadBegin,
    markAsReadError,
    markAsReadSuccess,
    receivedNotifications,
    resetNotificationPages,
    subscribeToNotificationsError,
} from 'common/store/notifications/actions';
import {
    FETCH_NOTIFICATIONS_PAGE_REQUEST,
    FETCH_UNREAD_NOTIFICATIONS_REQUEST,
    FetchNotificationsPageActionT,
    MARK_AS_READ_REQUEST,
    MarkAsReadActionT,
    SEND_TEST_NOTIFICATION,
    SendTestNotificationActionT,
    SUBSCRIBE_TO_NOTIFICATIONS,
} from 'common/store/notifications/types';
import { FETCH_USER_REQUEST_SUCCESS } from 'common/store/user/types';
import { ApiNotificationT } from 'common/utils/api/models';
import { convertNotification } from './converter';
import { publish } from 'common/utils/notification-pub-sub';
import watchDocumentVisibilityChangeSaga from 'common/utils/watch-document-visibility-change-saga';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectNotificationsPages, selectNotificationsQuery } from 'common/store/notifications/selectors';
import { AnyNotificationT } from 'common/store/notifications/models';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { checkIsSameQuery, convertPageEntities } from 'common/utils/pagination/utils';
import { clientConfig } from 'common/utils/client-config';

const BACKEND_PATH = `wss://${window.location.host}${clientConfig.soketBasepath}`;

function createNotificationsChannel(token: string, userId: string) {
    return eventChannel((emitter) => {
        const webSocket = new WebSocket(BACKEND_PATH);

        const stompClient = Stomp.over(webSocket);

        stompClient.connect(
            {
                login: token,
                passcode: 'passcode',
            },
            () => {
                /* start debug logs */
                console.info(`Subscribe to /topic/user/${userId}`);
                /* end debug logs */

                stompClient.subscribe(`/topic/user/${userId}`, (message) => {
                    try {
                        const parsedBody: ApiNotificationT = JSON.parse(message.body);

                        /* start debug logs */
                        console.info('receive notifications: ', parsedBody);
                        /* end debug logs */

                        emitter(parsedBody);
                    } catch (error) {
                        const apiError = new Error(error?.message);
                        emitter(apiError);
                    }
                });
            },
            (error: TODO) => {
                const message = error?.headers?.message || '';
                if (message.include('Invalid token')) {
                    logWarning('Invalid token');
                    return;
                }

                const apiError = new Error(error?.message);
                emitter(apiError);
            },
            (event: TODO) => {
                // event.reason === "Cannot connect to server"
                if (event?.code === 1002) {
                    const apiError = new Error(event.message);
                    emitter(apiError);
                }
            },
        );

        return () => {
            // TODO exit
        };
    });
}

function* receiveNewNotificationsSaga(notifications: AnyNotificationT[]): WrapGeneratorT<void> {
    publish(notifications);

    // eslint-disable-next-line no-use-before-define
    yield call(fetchUnReadNotificationsSaga);
}

function* subscribeToNotificationsSaga(): WrapGeneratorT<void> {
    const userSignedUp: ReturnType<typeof selectUserSignedUp> = yield select(selectUserSignedUp);
    if (!userSignedUp) {
        return;
    }

    const currentUser: ReturnType<typeof selectCurrentUser> = yield select(selectCurrentUser);
    if (!currentUser) {
        return;
    }

    const token: ReturnApiT<typeof commonTranziitApi.getToken> = yield commonTranziitApi.getToken();

    // @ts-ignore TS7057: 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
    const notificationsChannel = yield call(createNotificationsChannel, token, currentUser?.id);
    try {
        while (true) {
            // @ts-ignore TS7057: 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
            const apiNotification: ApiNotificationT = yield take(notificationsChannel);

            const notification = convertNotification(apiNotification, { isNew: true });

            yield put(receivedNotifications([notification]));
            yield call(receiveNewNotificationsSaga, [notification]);
        }
    } catch (error) {
        let apiError = null;
        if (error instanceof Error) {
            apiError = error;
        } else {
            apiError = new Error(error?.message);
        }

        yield put(subscribeToNotificationsError(apiError));
    } finally {
        // TODO cannel terminated
    }
}

function* sendTestNotificationSaga(action: SendTestNotificationActionT): WrapGeneratorT<void> {
    try {
        yield commonTranziitApi.sendTestNotification(JSON.parse(action.text));
    } catch (error) {
        /* start debug logs */
        console.error('sent test notification error: ', error);
        /* end debug logs */
    }
}

function* markAsReadNotificationsSaga(action: MarkAsReadActionT): WrapGeneratorT<void> {
    const { ids, isAll } = action;

    yield put(markAsReadBegin());
    const [error]: ReturnApiT<typeof commonTranziitApi.markAsRead> = yield commonTranziitApi.markAsRead(ids, isAll);
    if (error) {
        yield put(markAsReadError(error));
        return;
    }

    yield put(markAsReadSuccess(ids));

    // eslint-disable-next-line no-use-before-define
    yield refreshUnreadNotificationsSaga();
}

function* fetchNotificationsPageSaga(action: FetchNotificationsPageActionT): WrapGeneratorT<void> {
    const { query: rawQuery, pageNumber, options } = action;

    const query: typeof rawQuery = {
        sort: 'timestamp,DESC',
    };

    const prevQuery: ReturnType<typeof selectNotificationsQuery> = yield select(selectNotificationsQuery);
    const pages: ReturnType<typeof selectNotificationsPages> = yield select(selectNotificationsPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetNotificationPages());
    }

    yield put(fetchNotificationsPageBegin(query, pageNumber));
    const [error, response]: ReturnApiT<typeof commonTranziitApi.fetchNotificationsPage> =
        yield commonTranziitApi.fetchNotificationsPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchNotificationsError(query, pageNumber, error));
        return;
    }

    const newResponse = convertPageEntities(response, (apiNotification) => {
        return convertNotification(apiNotification, { isNew: false });
    });

    yield put(fetchNotificationsPageSuccess(query, pageNumber, newResponse));
}

function* refreshUnreadNotificationsSaga(): WrapGeneratorT<void> {
    // eslint-disable-next-line no-use-before-define
    yield call(fetchUnReadNotificationsSaga);
}

function* fetchUnReadNotificationsSaga(): WrapGeneratorT<void> {
    const userSignedUp: ReturnType<typeof selectUserSignedUp> = yield select(selectUserSignedUp);
    if (!userSignedUp) {
        return;
    }

    yield put(fetchUnreadNotificationsBegin());

    const [errorApiUnReadNotifications, apiUnReadNotifications]: ReturnApiT<
        typeof commonTranziitApi.fetchUnReadNotifications
    > = yield commonTranziitApi.fetchUnReadNotifications();

    if (errorApiUnReadNotifications) {
        yield put(fetchUnreadNotificationsError(errorApiUnReadNotifications));
        return;
    }

    const unreadNotifications = (apiUnReadNotifications || []).map((apiUnReadNotification) => {
        return convertNotification(apiUnReadNotification, { isNew: false });
    });

    yield put(fetchUnreadNotificationsSuccess(unreadNotifications));
}

function* notificationsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_USER_REQUEST_SUCCESS, SUBSCRIBE_TO_NOTIFICATIONS], subscribeToNotificationsSaga);
    yield takeEvery(SEND_TEST_NOTIFICATION, sendTestNotificationSaga);
    yield takeEvery(MARK_AS_READ_REQUEST, markAsReadNotificationsSaga);
    yield takeEvery(FETCH_NOTIFICATIONS_PAGE_REQUEST, fetchNotificationsPageSaga);
    yield takeEvery([FETCH_USER_REQUEST_SUCCESS, FETCH_UNREAD_NOTIFICATIONS_REQUEST], fetchUnReadNotificationsSaga);
    yield call(watchDocumentVisibilityChangeSaga, refreshUnreadNotificationsSaga);
}

export default notificationsSaga;
