import { ApiRequestError, ApiRequestErrorTypeEnum } from '../errors/api-request-error';
import BaseAuthApi from '../auth/base-api';
import parseErrors from './errors/parse-errors';
import { TranziitApiRequestError, TranziitApiRequestErrorSubTypeEnum } from './errors/tranziit-api-errors';
import doFetch from 'common/utils/api/do-fetch';
import { NotImplementedError } from 'common/utils/api/errors/not-implemented-error';
import { BaseFetchConfigT, FetchConfigT } from '../do-fetch-models';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

const delay = async (time: number) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(null);
        }, time);
    });
};

const CHECK_USER_TOKEN_TIMEOUT = 500;

export const throwNotImplementedError = (): TranziitApiResultT<null> => {
    return [new NotImplementedError(), null];
};

export type TranziitApiResultT<D> = [TranziitApiRequestError | ApiRequestError | NotImplementedError | null, D | null];

abstract class BaseTranziitApi {
    private authApi: BaseAuthApi;

    private baseRequestConfig: BaseFetchConfigT;

    constructor(authApi: BaseAuthApi, baseRequestConfig: BaseFetchConfigT) {
        this.authApi = authApi;
        this.baseRequestConfig = baseRequestConfig;
    }

    async getHeaders(): Promise<Record<string, string>> {
        const token = await this.getToken();

        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
    }

    private checkIsValidConfig = (): boolean => {
        return isString(this.baseRequestConfig.basepath) && isNumber(this.baseRequestConfig.timeout);
    };

    private waitValidConfig = async (): Promise<void> => {
        while (!this.checkIsValidConfig()) {
            await delay(50);
        }
    };

    public setBasePath = (basepath: string): void => {
        this.baseRequestConfig.basepath = basepath;
    };

    async getToken(): Promise<string> {
        try {
            let token = null;

            // wait token
            while (!token) {
                token = await this.authApi.getIdToken();
                await delay(CHECK_USER_TOKEN_TIMEOUT);
            }

            return token;
        } catch (error) {
            return '';
        }
    }

    async doFetch<D, E = any>(requestConfig: FetchConfigT): Promise<TranziitApiResultT<D>> {
        await this.waitValidConfig();

        const headers = await this.getHeaders();

        /*
        console.log("[DEBUG] request: ", JSON.stringify({
            ...this.baseRequestConfig,
            ...requestConfig,
            headers: {
                ...headers,
                ...requestConfig.headers,
            },
        }, null, 4));
        */

        const [error, result] = await doFetch<D, E>({
            ...this.baseRequestConfig,
            ...requestConfig,
            headers: {
                ...headers,
                ...requestConfig.headers,
            },
        });

        if (error) {
            if (error.type === ApiRequestErrorTypeEnum.badRequest) {
                const subTypes = Object.values(TranziitApiRequestErrorSubTypeEnum);
                for (let i = 0; i < subTypes.length; i += 1) {
                    const subType = subTypes[i];

                    const parseError = parseErrors[subType];
                    if (parseError && parseError(error)) {
                        const apiError = new TranziitApiRequestError(error, subType);
                        return [apiError, null];
                    }
                }
            }

            return [error, null];
        }

        return [null, result];
    }
}

export default BaseTranziitApi;
