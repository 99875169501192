import * as React from 'react';

import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import AuthLayout from '../AuthLayout/AuthLayout';

import styles from './SignInPage.scss';

import { authRoutesEnum, QueryKeysEnum } from '../../constants';
import { signIn } from 'common/store/auth/actions';
import { selectSignInRequestStatus } from 'common/store/auth/selectors';
import Notification from './Notification/Notification';
import useQuery from 'common/utils/hooks/useQuery';
import validateForm from './validate-form';
import { FieldsEnum, FormValuesT } from './constants';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { parseQuery } from 'common/utils/query';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import asyncValidate from './async-validations';
import useAsyncFormErrorMessage from 'common/utils/hooks/useAsyncFormErrorMessage';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import RedirectSignedUser from 'common/components/RedirectSignedUser/RedirectSignedUser';

const cx = classNames.bind(styles);

type PropsT = {};

const INITIAL_VALUES = {
    [FieldsEnum.email]: '',
    [FieldsEnum.password]: '',
};

type QueryT = {
    [QueryKeysEnum.returnUrl]: string;
};

type NotificationT = {
    title: string;
    message: string;
};

const SignInPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const requestStatus = useSelector(selectSignInRequestStatus);
    const location = useLocation();
    const query = useQuery<QueryT>();
    const [notification, showNotification] = React.useState<NotificationT | null>(null);

    React.useEffect(() => {
        const searchParams = parseQuery(location.search);

        if (searchParams[QueryKeysEnum.successSignUp]) {
            showNotification({
                title: t('common:sign-in.check-inbox-notification.title'),
                message: t('common:sign-in.check-inbox-notification.message'),
            });
        }

        if (searchParams[QueryKeysEnum.successChangePassword]) {
            showNotification({
                title: t('common:sign-in.success-change-password.title'),
                message: t('common:sign-in.success-change-password.message'),
            });
        }
    }, [location, t]);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const initialErrors = React.useMemo(() => {
        return validateForm(t, INITIAL_VALUES);
    }, [t, INITIAL_VALUES]);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues: INITIAL_VALUES,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const email = simpleStringFormatter(values[FieldsEnum.email]);
            const password = simpleStringFormatter(values[FieldsEnum.password]);
            const returnUrl = query[QueryKeysEnum.returnUrl] || null;
            dispatch(signIn(email, password, returnUrl));

            formikHelpers.setTouched({});
        },
    });

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(requestStatus);
    }, [requestStatus.error]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    const emailAsyncError = useAsyncFormErrorMessage(asyncFormErrors[FieldsEnum.email]);
    const passwordAsyncError = useAsyncFormErrorMessage(asyncFormErrors[FieldsEnum.password]);

    return (
        <>
            <RedirectSignedUser />
            <AuthLayout title={t('common:sign-in.title')} testSelector="sign-in-page">
                <PageTitle title={t('common:page-titles.sign-in')} />
                {notification && (
                    <div className={cx('notification')}>
                        <Notification
                            title={notification.title}
                            message={notification.message}
                            testSelector="notification"
                        />
                    </div>
                )}
                <form onSubmit={formik.handleSubmit}>
                    <div className={cx('fields')}>
                        <div className={cx('input')}>
                            <FormikField
                                name={FieldsEnum.email}
                                error={formik.errors[FieldsEnum.email]}
                                meta={formik.getFieldMeta(FieldsEnum.email)}
                                label={t('common:sign-in.fields.email.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                asyncError={emailAsyncError}
                                resetAsyncError={resetAsyncFormErrors}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.email}
                                        placeholder={t('common:sign-in.fields.email.placeholder')}
                                        value={formik.values[FieldsEnum.email]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                        </div>
                        <div className={cx('input')}>
                            <FormikField
                                name={FieldsEnum.password}
                                error={formik.errors[FieldsEnum.password]}
                                meta={formik.getFieldMeta(FieldsEnum.password)}
                                label={t('common:sign-in.fields.password.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                asyncError={passwordAsyncError}
                                resetAsyncError={resetAsyncFormErrors}
                            >
                                {(props) => (
                                    <Input
                                        type="password"
                                        name={FieldsEnum.password}
                                        placeholder={t('common:sign-in.fields.password.placeholder')}
                                        value={formik.values[FieldsEnum.password]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                        </div>
                    </div>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={requestStatus.loading}
                        testSelector="sign-in"
                        type="submit"
                        className={cx('submit')}
                    >
                        {t('common:sign-in.submit')}
                    </Button>
                    <div className={cx('footer-links')}>
                        {t('common:sign-in.links.sign-up')}
                        <span className={cx('footer-links__link')}>
                            <Link
                                theme={LinkThemeEnum.boldAzul}
                                testSelector="sign-up"
                                to={{
                                    pathname: authRoutesEnum.signUp,
                                }}
                            >
                                {t('common:sign-in.links.sign-up-link')}
                            </Link>
                        </span>
                    </div>
                    <div className={cx('footer-links')}>
                        <Link
                            theme={LinkThemeEnum.boldAzul}
                            testSelector="forgot-password"
                            to={{
                                pathname: authRoutesEnum.forgotPassword,
                            }}
                        >
                            {t('common:sign-in.links.forgot-password')}
                        </Link>
                    </div>
                </form>
            </AuthLayout>
        </>
    );
});

export default SignInPage;
