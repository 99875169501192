import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatusCell.scss';
import { TransportOrderT } from 'common/store/transport-orders/models';
import TransportOrderStatusPill from 'common/components/status-pill/TransportOrderStatusPill/TransportOrderStatusPill';

type PropsT = {
    order: TransportOrderT;
    className?: string;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { order, className } = props;

    return <TransportOrderStatusPill isSymmetrical className={className} status={order?.calculatedStatus} />;
});

export default StatusCell;
