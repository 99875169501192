import { SettingsStateT } from './types';
import { SharableStateT } from 'common/store/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNil from 'lodash/isNil';
import { MS_IN_HOUR, MS_IN_MIN, MS_IN_SEC } from 'common/utils/time';

export const selectSettingsStatus = (state: SharableStateT): SettingsStateT['fetchRequest'] => {
    return state.settings.fetchRequest;
};

export const selectTimeSlotsDelegationLimitMs = (state: SharableStateT): number | null => {
    const value = state.settings.data?.TIME_SLOTS_DELEGATION_LIMIT?.value;
    if (isNil(value)) {
        return null;
    }

    const parsedValue = parseSimpleNumber(value);
    if (parsedValue) {
        return parsedValue * MS_IN_HOUR;
    }

    return parsedValue || null;
};

export const selectMinWindowSizeMs = (state: SharableStateT): number => {
    const value = state.settings.data?.MIN_WINDOW_SIZE?.value;
    if (isNil(value)) {
        return MS_IN_HOUR;
    }

    const parsedValue = parseSimpleNumber(value);
    if (parsedValue) {
        return parsedValue * MS_IN_SEC;
    }

    return MS_IN_HOUR;
};

export const selectMaxWindowSizeMs = (state: SharableStateT): number => {
    const value = state.settings.data?.MAX_WINDOW_SIZE?.value;
    if (isNil(value)) {
        return MS_IN_HOUR;
    }

    const parsedValue = parseSimpleNumber(value);
    if (parsedValue) {
        return parsedValue * MS_IN_SEC;
    }

    return MS_IN_HOUR;
};

const DEFAULT_TIME_WINDOW_QUANTUM = 15 * MS_IN_MIN;

export const selectTimeWindowQuantumMs = (state: SharableStateT): number => {
    const value = state.settings.data?.QUANTUM_MINUTES?.value;
    if (isNil(value)) {
        return DEFAULT_TIME_WINDOW_QUANTUM;
    }

    const parsedValue = parseSimpleNumber(value);
    if (parsedValue) {
        return parsedValue * MS_IN_MIN;
    }

    return DEFAULT_TIME_WINDOW_QUANTUM;
};
