import { createSelector } from 'reselect';
import { AuthStateT } from './types';
import { SharableStateT } from '../models';
import { PermissionsT } from './models';
import { FirebaseClameRoleT, FirebaseRolesEnum, PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';
import { getPermissions } from 'common/utils/permissions';
import { ALL_ROLES_BY_PARTNER_TYPE, AuthProfileTypeEnum } from 'common/store/auth/constants';
import UserCredential = firebase.auth.UserCredential;

export const selectSignInRequestStatus = (state: SharableStateT): AuthStateT['signInRequest'] =>
    state.auth.signInRequest;

export const selectUser = (state: SharableStateT): UserCredential['user'] | null => state.auth?.user || null;
export const selectUserClaims = (state: SharableStateT): AuthStateT['tokenClaims'] => state.auth?.tokenClaims;

export const checkRegisteredUser = (state: SharableStateT): boolean =>
    state.auth.user !== null && !state.auth.user.isAnonymous;

export const selectResetPasswordRequestStatus = (state: SharableStateT): AuthStateT['resetPasswordRequest'] =>
    state.auth.resetPasswordRequest;

export const selectUpdatePasswordRequestStatus = (state: SharableStateT): AuthStateT['updatePasswordRequest'] =>
    state.auth.updatePasswordRequest;

export const selectSignUpRequestStatus = (state: SharableStateT): AuthStateT['signUpRequest'] =>
    state.auth.signUpRequest;

export const selectSignOutRequestStatus = (state: SharableStateT): AuthStateT['signOutRequest'] =>
    state.auth.signOutRequest;

export const selectEmailVerificationRequestStatus = (state: SharableStateT): AuthStateT['emailVerificationRequest'] =>
    state.auth.emailVerificationRequest;

export const selectProfileType = (state: SharableStateT): AuthStateT['profileType'] => state.auth.profileType;

export type RolesConfigT = Record<keyof PermissionsT, Array<FirebaseClameRoleT | FirebaseClameRoleT[]>>;
export type ProfileTypeRestrictConfigT = Partial<Record<keyof PermissionsT, AuthProfileTypeEnum[]>>;

export const selectPermissions = createSelector(
    selectUserClaims,
    selectProfileType,
    (tokenClaims, profileType): PermissionsT => {
        // rule: [a or b or [c and d]]
        const config: RolesConfigT = {
            canViewPriceSettings: [RolesEnum.companyAdmin],
            canViewFullStatistic: [RolesEnum.companyAdmin],

            canCancelOrder: [RolesEnum.companyAdmin, ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker]],
            canCreateOrder: [RolesEnum.companyAdmin, RolesEnum.customService, RolesEnum.keyAccountManager],

            canViewTrailers: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,
                RolesEnum.customService,
                RolesEnum.keyAccountManager,
            ],
            canEditTrailers: [RolesEnum.companyAdmin, RolesEnum.customService],

            canViewTrucks: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,
                RolesEnum.customService,
                RolesEnum.keyAccountManager,
            ],
            canEditTrucks: [RolesEnum.companyAdmin, RolesEnum.customService],

            canAddPartner: [RolesEnum.companyAdmin, RolesEnum.customService],
            canDeletePartner: [],

            canDownloadDocuments: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],
            canUploadDocuments: [RolesEnum.companyAdmin, RolesEnum.customService],
            canVerifyDocuments: [RolesEnum.companyAdmin, RolesEnum.customService],
            canRevokeDocuments: [RolesEnum.companyAdmin, RolesEnum.customService],
            canEditDocuments: [RolesEnum.companyAdmin, RolesEnum.customService],
            canReviewDocuments: [RolesEnum.companyAdmin, RolesEnum.customService],

            canDownloadCarrierContracts: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],
            canUploadCarrierContracts: [RolesEnum.companyAdmin, RolesEnum.customService],
            canRevokeCarrierContracts: [RolesEnum.companyAdmin, RolesEnum.customService],
            canEditCarrierContracts: [RolesEnum.companyAdmin, RolesEnum.customService],
            canReviewCarrierContracts: [RolesEnum.companyAdmin, RolesEnum.customService],

            canUploadShipperContracts: [RolesEnum.companyAdmin, RolesEnum.customService],
            canEditShipperContracts: [RolesEnum.companyAdmin, RolesEnum.customService],

            canVerifyPartner: [RolesEnum.companyAdmin, RolesEnum.customService],

            canEditTeamMembers: [
                RolesEnum.companyAdmin,
                RolesEnum.customService,
                RolesEnum.companyAdmin,
                RolesEnum.keyAccountManager,
            ],
            canViewTeamMembers: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],
            canEditDrivers: [RolesEnum.companyAdmin, RolesEnum.customService, RolesEnum.keyAccountManager],
            canViewDrivers: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,
                RolesEnum.customService,
                RolesEnum.keyAccountManager,
            ],

            canViewSpot: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,
                RolesEnum.dispatcher,
            ],

            canViewInvoices: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],

            canAssignAssetsForSpotTransportOrder: [...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier]],
            canAssignDriverForTransportOrder: [...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier]],
            canDeclineTransportOrders: [[RolesEnum.companyAdmin, FirebaseRolesEnum.carrier]],

            canEditSpotLanes: [...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier]],
            canUseBrokerAdminSuggest: [RolesEnum.companyAdmin],
        };

        const userRolesSet = new Set(tokenClaims?.roles || []);

        const profileTypeRestrictConfig: ProfileTypeRestrictConfigT = {
            canRevokeDocuments: [AuthProfileTypeEnum.broker],
            canVerifyDocuments: [AuthProfileTypeEnum.broker],
            canEditDocuments: [AuthProfileTypeEnum.broker],
            canReviewDocuments: [AuthProfileTypeEnum.broker],

            canRevokeCarrierContracts: [AuthProfileTypeEnum.broker],
            canEditCarrierContracts: [AuthProfileTypeEnum.broker],
            canReviewCarrierContracts: [AuthProfileTypeEnum.broker],

            canUploadShipperContracts: [AuthProfileTypeEnum.broker],
            canEditShipperContracts: [AuthProfileTypeEnum.broker],

            canEditSpotLanes: [AuthProfileTypeEnum.carrier],

            canUseBrokerAdminSuggest: [AuthProfileTypeEnum.broker],

            canAssignDriverForTransportOrder: [AuthProfileTypeEnum.carrier],
            canAssignAssetsForSpotTransportOrder: [AuthProfileTypeEnum.carrier],
        };

        return getPermissions(userRolesSet, config, profileType, profileTypeRestrictConfig);
    },
);
