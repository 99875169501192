import React, { useCallback } from 'react';

import classNames from 'classnames/bind';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import styles from './ActiveContractDropdown.scss';
import { useTranslation } from 'react-i18next';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import {
    AdditionalOptionFilterT,
    useAsyncFilterOptions,
} from 'design-system/components/dropdowns/DropdownSearchInput/hooks/use-async-filter-options';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { AsyncRequestFactoryT } from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import { checkIsDefaultCompanyId } from 'common/utils';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { ApiPartnerContractT } from 'common/utils/api/models';
import ContractName from 'common/components/ContractName/ContractName';

const cx = classNames.bind(styles);

type ValueT = CarrierContractIdT | null;

type PropsT = {
    className?: string;
    placeholder?: string;
    value: ValueT;
    hasNoneOption?: boolean;
    isDisabled: boolean;
    onChange: (value: ValueT) => void;
    hasChanges?: boolean;
    companyId: CompanyIdT;
    onBlur: () => void;
    onFocus: () => void;
    hasError?: boolean;
    hasWarning?: boolean;
    excludedContractIds?: Array<CarrierContractIdT>;
    overlayPosition?: DropdownOverlayPositionEnum;
};

type NoneOptionT = {
    id: null;
    name: string;
};

export type OptionT = ApiPartnerContractT | NoneOptionT;

const ActiveContractDropdown: React.FC<PropsT> = (props) => {
    const {
        className,
        value,
        placeholder,
        onChange,
        hasNoneOption,
        isDisabled,
        hasChanges,
        onBlur,
        onFocus,
        hasError,
        hasWarning,
        companyId,
        excludedContractIds,
        overlayPosition,
    } = props;

    const { t } = useTranslation();

    const requestFactory: AsyncRequestFactoryT<OptionT> = useCallback(async () => {
        let options: Array<OptionT> = [];

        const isPartner = !checkIsDefaultCompanyId(companyId);
        if (isPartner) {
            const brokerResponse = await brokerTranziitApi.fetchCarrierApprovedContracts(companyId);
            const [brokerError, brokerResult] = brokerResponse;
            if (brokerError) {
                return [brokerError, null];
            }
            if (brokerResult) {
                options = brokerResult;
            }
        } else {
            const carrierResponse = await carrierTranziitApi.fetchCarrierApprovedContracts();
            const [carrierError, carrierResult] = carrierResponse;
            if (carrierError) {
                return [carrierError, null];
            }
            if (carrierResult) {
                options = carrierResult;
            }
        }

        if (hasNoneOption) {
            options.unshift({
                name: t('common:dedicated.none'),
                id: null,
            });
        }
        return [null, options];
    }, [companyId, excludedContractIds, hasNoneOption]);

    const additionalOptionFilter: AdditionalOptionFilterT<OptionT> = useCallback(
        (option) => {
            return !!option.id && !excludedContractIds?.includes(option.id);
        },
        [excludedContractIds],
    );

    const getOptionText = useCallback((option: OptionT) => option.name || '', []);

    const asyncFilterOptionsRequest = useAsyncFilterOptions<OptionT>({
        requestFactory,
        getOptionText,
        additionalOptionFilter,
    });

    const renderOption = (option: OptionT | null | undefined, placeholder?: string): React.ReactElement | null => {
        if (!option && hasNoneOption) {
            return t('common:dedicated.none');
        }

        if (!option) {
            return <div>{placeholder || null}</div>;
        }

        return (
            <div className={cx('option')} key={option.id}>
                <ContractName name={option.name} />
            </div>
        );
    };

    const handleSelect = (value: ValueT): void => {
        onChange(value);
    };

    const getOptionValue = (option: OptionT): ValueT => option.id || null;

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }

        asyncFilterOptionsRequest.onRefresh();
    }, [onFocus, asyncFilterOptionsRequest.onRefresh]);

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={asyncFilterOptionsRequest.options}
            isDisabled={isDisabled}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={asyncFilterOptionsRequest.onChangeQuery}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            onBlur={onBlur}
            renderRightIcon={() =>
                asyncFilterOptionsRequest.requestStatus.loading ? (
                    <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} />
                ) : null
            }
            onFocus={handleFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default ActiveContractDropdown;
