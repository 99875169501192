import React from 'react';
import classNames from 'classnames/bind';

import styles from './TrailerDetails.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { AssetTypeEnum, CurrencyEnum, StyleGuideColorsEnum, TrailerTypeEnum } from 'common/constants';
import FieldValue from 'common/components/FieldValue/FieldValue';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import TrailerIcon from 'common/icons/TrailerIcon';
import { TrailerDetailsT } from 'common/store/trailers/models';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import Money from 'common/components/Money/Money';
import LastGPSPositionAlert from 'common/components/LastGPSPositionAlert/LastGPSPositionAlert';
import { fetchCarrierContracts } from 'common/store/carrier-contracts/actions';
import { selectCarrierContractById } from 'common/store/carrier-contracts/selectors';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TrailerType from 'common/layouts/CommonEditableTrailerDetailsLayout/TrailerType/TrailerType';
import LinkedTruckAlert from 'common/layouts/CommonEditableTrailerDetailsLayout/LinkedTruckAlert/LinkedTruckAlert';
import { CountryCodeT } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    isLoading: boolean;
    trailerDetails: TrailerDetailsT | null;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
};

const TrailerDetails: React.FC<PropsT> = (props) => {
    const { partnerId, trailerDetails, isLoading, onOpenTruckDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);
    const trailersDictById = useSelector(selectTrailersDictById) || {};

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
        dispatch(fetchTrailersDict());
    }, []);

    const trailerDictModel = trailerDetails?.dictTrailerId ? trailersDictById[trailerDetails?.dictTrailerId] : null;

    const trailerTypeLabel = trailerDictModel
        ? t(`common:trailers-page.add-trailer.fields.trailer.${trailerDictModel?.trailerType}.label`)
        : '';

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const contractById = useSelector(selectCarrierContractById(partnerId));
    const contract = contractById[trailerDetails?.contractId as string] || null;

    const contractRatePerKm =
        trailerDictModel?.trailerType === TrailerTypeEnum.box ? contract?.boxRateKm : contract?.tiltRateKm;

    const ratePerKm = trailerDetails?.contracted ? contractRatePerKm : trailerDetails?.ratePerKm;

    const contractId = trailerDetails?.contractId || null;
    return (
        <div className={cx('wrap')}>
            {trailerDetails?.linkedTruckId && (
                <LinkedTruckAlert
                    plateNumber={trailerDetails?.linkedTruckPlateNumber}
                    truckId={trailerDetails.linkedTruckId}
                    className={cx('alert')}
                    onOpenTruckDetails={onOpenTruckDetails}
                />
            )}
            <LastGPSPositionAlert
                companyId={partnerId}
                assetType={AssetTypeEnum.trailer}
                plateNumber={trailerDetails?.plateNumber || ''}
                className={cx('alert')}
            />
            {isLoading && <LoaderOverlay />}
            <TrailerType trailerType={trailerDictModel?.trailerType} className={cx('icon')} />
            <FieldValue
                label={trailerTypeLabel}
                icon={
                    <TrailerIcon
                        size={20}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                        strokeColor={StyleGuideColorsEnum.brandDark}
                    />
                }
                value={
                    trailerDictModel
                        ? t('common:trailer-subtype-template', {
                              length: trailerDictModel.length,
                              model: trailerDictModel.model,
                              eur1Pallets: trailerDictModel.eur1Pallets,
                              eur2Pallets: trailerDictModel.eur2Pallets,
                          })
                        : ''
                }
            />
            <FieldValue
                className={cx('field--plate-number')}
                label={t('common:trailers-page.add-trailer.fields.plate-number.label')}
                value={trailerDetails?.plateNumber || ''}
            />
            <FieldValue
                className={cx('field--country')}
                icon={<FlagIcon countryCode={trailerDetails?.countryOfRegistration} />}
                label={t('common:trailers-page.add-trailer.fields.country.label')}
                value={
                    countryByCode?.[trailerDetails?.countryOfRegistration as CountryCodeT]?.userLangDisplayName || ''
                }
            />
            <FieldGroup>
                <FieldValue
                    className={cx('field--contract')}
                    label={t('common:trailers-page.add-trailer.fields.contract.label')}
                    value={trailerDetails?.contracted ? contract?.name : t('common:condition.free')}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('common:trailers-page.add-trailer.fields.contract.tooltip')}
                        </TooltipContent>
                    }
                />
                <FieldValue
                    className={cx('field--rate')}
                    label={t('common:trailers-page.add-trailer.fields.rate.label')}
                    value={ratePerKm ? <Money amount={ratePerKm} currency={CurrencyEnum.EUR} /> : ''}
                />
            </FieldGroup>
        </div>
    );
};

export default TrailerDetails;
