import DatabaseIcon from 'common/icons/DatabaseIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import * as React from 'react';
import PartnersIcon from 'common/icons/PartnersIcon';
import PadIcon from 'common/icons/PadIcon';
import CaseIcon from 'common/icons/CaseIcon';
import PlusIcon from 'common/icons/PlusIcon';

type MenuIconPropsT = {
    isActive: boolean;
};

const DEFAULT_ICON_PRESET = {
    active: {
        fill: StyleGuideColorsEnum.brandAccent,
        stroke: StyleGuideColorsEnum.brandDark,
    },
    default: {
        fill: StyleGuideColorsEnum.light,
        stroke: StyleGuideColorsEnum.slate,
    },
};

export const DatabaseMenuIcon: React.FC<MenuIconPropsT> = (props) => {
    const { isActive } = props;

    return (
        <DatabaseIcon
            fillColor={isActive ? DEFAULT_ICON_PRESET.active.fill : DEFAULT_ICON_PRESET.default.fill}
            strokeColor={isActive ? DEFAULT_ICON_PRESET.active.stroke : DEFAULT_ICON_PRESET.default.stroke}
        />
    );
};

export const PlusMenuIcon: React.FC<MenuIconPropsT> = (props) => {
    const { isActive } = props;

    return (
        <PlusIcon
            size={DEFAULT_ICON_SIZE}
            strokeColor={isActive ? DEFAULT_ICON_PRESET.active.stroke : DEFAULT_ICON_PRESET.default.stroke}
        />
    );
};

export const TeamsMenuIcon: React.FC<MenuIconPropsT> = (props) => {
    const { isActive } = props;

    return (
        <PartnersIcon
            fillColor={isActive ? DEFAULT_ICON_PRESET.active.fill : DEFAULT_ICON_PRESET.default.fill}
            strokeColor={isActive ? DEFAULT_ICON_PRESET.active.stroke : DEFAULT_ICON_PRESET.default.stroke}
        />
    );
};

export const BusinessPartnerMenuIcon: React.FC<MenuIconPropsT> = (props) => {
    const { isActive } = props;

    return (
        <CaseIcon
            size={DEFAULT_ICON_SIZE}
            fillColor={isActive ? DEFAULT_ICON_PRESET.active.fill : DEFAULT_ICON_PRESET.default.fill}
            strokeColor={isActive ? DEFAULT_ICON_PRESET.active.stroke : DEFAULT_ICON_PRESET.default.stroke}
        />
    );
};

export const ProfileMenuIcon: React.FC<MenuIconPropsT> = (props) => {
    const { isActive } = props;

    return (
        <PadIcon
            fillColor={isActive ? DEFAULT_ICON_PRESET.active.fill : DEFAULT_ICON_PRESET.default.fill}
            strokeColor={isActive ? DEFAULT_ICON_PRESET.active.stroke : DEFAULT_ICON_PRESET.default.stroke}
        />
    );
};
