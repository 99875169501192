import React from 'react';

import classNames from 'classnames/bind';
import styles from './TrailerStatusDropdown.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';

import { TrailerStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import { TRAILER_STATUS_T_MAP } from 'common/components/status/TrailerStatus/TrailerStatus';

const cx = classNames.bind(styles);

type OptionValueT = TrailerStatusEnum | null;

type PropsT = {
    placeholder?: string;
    value: OptionValueT;
    isDisabled?: boolean;
    onChange: (value: OptionValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
    overlayPosition?: DropdownOverlayPositionEnum;
};

type TrailerStatusDropdownOptionT = {
    value: OptionValueT;
    label: React.ReactNode;
};

const TrailerStatusDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
        overlayPosition,
    } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): TrailerStatusDropdownOptionT[] => {
        return [
            TrailerStatusEnum.free,
            TrailerStatusEnum.inTransit,
            TrailerStatusEnum.archived,
            TrailerStatusEnum.unavailable,
        ].map((status: TrailerStatusEnum): TrailerStatusDropdownOptionT => {
            return {
                label: t(TRAILER_STATUS_T_MAP[status]),
                value: status,
            };
        });
    }, [t]);

    const renderTrigger = (
        option: TrailerStatusDropdownOptionT | null | undefined,
        placeholder?: string,
    ): React.ReactNode => {
        if (!option) {
            return placeholder || '';
        }

        return option.label;
    };

    const renderOption = (option: TrailerStatusDropdownOptionT | null | undefined): React.ReactNode => {
        if (!option) {
            return null;
        }

        return option.label;
    };

    const handleSelect = (value: OptionValueT): void => {
        onChange(value);
    };

    const getOptionValue = (option: TrailerStatusDropdownOptionT): OptionValueT => option.value;

    return (
        <DropdownInput<TrailerStatusDropdownOptionT, OptionValueT>
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            testSelector="trailer-status"
            hasClearControl={hasClearControl}
            onReset={() => {
                onChange(null);
            }}
        />
    );
});

export default TrailerStatusDropdown;
