import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './EditDriverSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, MEDIA_FIELDS_NAME } from './constants';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { uppercaseFirstCharacterFormatter } from 'common/utils/form-formatters';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import prepareApiDriverChanges from './prepare-api-driver-changes';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import LanguageSelector from 'common/components/LanguageSelector/LanguageSelector';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { AUTO_COMPLETE_OFF_FIX, SocialMediaEnum, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import CloseIcon from 'common/icons/CloseIcon';
import FieldValue from 'common/components/FieldValue/FieldValue';
import PhoneNumberIcon from 'common/components/PhoneNumberIcon/PhoneNumberIcon';
import { ICON_BY_MEDIA } from 'common/layouts/Members/media-icons';
import NumberInput from 'common/components/NumberInput/NumberInput';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import { logWarning } from 'common/utils/logger';
import UserFormalDropdown from 'common/components/dropdowns/UserFormalDropdown/UserFormalDropdown';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import AddOtherMessengerDropdown from 'common/layouts/SideBars/contents/EditContactDetailsSidebarContent/AddOtherMessengerDropdown/AddOtherMessengerDropdown';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import {
    activateDriver,
    archiveDriver,
    cancelDriverInvite,
    fetchDriverDetails,
    updateDriver,
} from 'common/store/drivers/actions';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { EditDriverDetailsSidebarDataT } from 'common/layouts/SideBars/contents/EditDriverSidebarContent/models';
import { useDriverActionOptions } from 'common/layouts/SideBars/contents/DriverDetailsSidebarContent/hooks/use-driver-action-options';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDriverDetailsById,
    selectFetchDriverDetailsRequest,
    selectUpdateDriverRequest,
} from 'common/store/drivers/selectors';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import ArchiveDriverConfirmation, {
    ArchiveDriverConfirmationDataT,
} from 'common/layouts/DriversPageLayout/dialogs/ArchiveDriverConfirmation/ArchiveDriverConfirmation';
import ActivateDriverConfirmation, {
    ActivateDriverConfirmationDataT,
} from 'common/layouts/DriversPageLayout/dialogs/ActivateDriverConfirmation/ActivateDriverConfirmation';
import CancelDriverInviteModal, {
    CancelDriverInviteConfirmationDataT,
} from 'common/layouts/DriversPageLayout/dialogs/CancelDriverInviteModal/CancelDriverInviteModal';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import noop from 'lodash/noop';
import DriverSidebarHeader from 'common/layouts/SideBars/contents/DriverDetailsSidebarContent/DriverSidebarHeader/DriverSidebarHeader';
import useCloseSidebarAfterRequest from 'common/utils/hooks/useCloseSidebarAfterRequest';
import DriverStatusPill from 'common/components/status-pill/DriverStatusPill/DriverStatusPill';

const cx = classNames.bind(styles);

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type PropsT = SidebarContentPropsT<EditDriverDetailsSidebarDataT, CommonSidebarDataT>;

const EditDriverSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onClose, onGoBack, setNeedCloseConfirmation } = props;

    const { t } = useTranslation();

    const fetchDriverDetailsRequestStatus = useSelector(selectFetchDriverDetailsRequest(data.partnerId));

    const driverDetailsById = useSelector(selectDriverDetailsById(data.partnerId));
    const driverDetails = driverDetailsById[data.driverId as DriverIdT];
    React.useEffect(() => {
        dispatch(fetchDriverDetails(data.driverId, data.partnerId, { isForceUpdate: false }));
    }, [data]);

    const updateDriversRequestStatus = useSelector(selectUpdateDriverRequest(data.partnerId));

    useCloseSidebarAfterRequest(updateDriversRequestStatus, onGoBack || onClose);

    const archiveDriverConfirmation = useModalDialog<ArchiveDriverConfirmationDataT>();
    const activateDriverConfirmation = useModalDialog<ActivateDriverConfirmationDataT>();
    const cancelDriverInviteConfirmation = useModalDialog<CancelDriverInviteConfirmationDataT>();

    useCloseModalDialogAfterRequest(updateDriversRequestStatus, [
        archiveDriverConfirmation,
        activateDriverConfirmation,
        cancelDriverInviteConfirmation,
    ]);

    const headerDropdownOptions = useDriverActionOptions(driverDetails, data.partnerId, {
        onCancelInvite: cancelDriverInviteConfirmation.setData,
        onArchiveDriver: archiveDriverConfirmation.setData,
        onActivateDriver: activateDriverConfirmation.setData,
    });

    const dispatch = useDispatch();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(driverDetails);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [driverDetails]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!data.driverId) {
                logWarning('empty driverId!');
                return;
            }

            const driverChanges = prepareApiDriverChanges(values, driverDetails);
            if (!driverChanges) {
                logWarning('empty driverChanges!');
                return;
            }

            dispatch(updateDriver(data.driverId, driverChanges, data.partnerId));

            formikHelpers.setTouched({});
        },
    });

    const handleSelectLanguage = (langCode: string | undefined | null): void => {
        if (!langCode) {
            return;
        }

        formik.setFieldValue(FieldsEnum.language, langCode);
    };

    const [lastAddedMediaField, setLastAddedMediaField] = React.useState<SocialMediaEnum | null>(null);

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);
    useEffect(() => {
        setNeedCloseConfirmation(hasAnyFieldValueChanges);
    }, [hasAnyFieldValueChanges]);

    const handleConfirmArchive = (data: ArchiveDriverConfirmationDataT): void => {
        dispatch(archiveDriver(data.driverId, data.companyId));
    };

    const handleConfirmActivate = (data: ActivateDriverConfirmationDataT): void => {
        dispatch(activateDriver(data.driverId, data.companyId));
    };

    const handleConfirmCancelDriverInvite = (data: CancelDriverInviteConfirmationDataT): void => {
        dispatch(cancelDriverInvite(data.driverUserId, data.companyId));
    };

    const isLoading = fetchDriverDetailsRequestStatus.loading || updateDriversRequestStatus.loading;

    return (
        <>
            <form onSubmit={formik.handleSubmit} className={cx('form')}>
                {isLoading && <LoaderOverlay />}
                <HeaderSideBarLayout>
                    <HeaderSideBarContent
                        onGoBack={onGoBack}
                        title={driverDetails ? <DriverSidebarHeader driverDetails={driverDetails} /> : null}
                        afterTitleNode={
                            driverDetails ? <DriverStatusPill isSymmetrical status={driverDetails.status} /> : null
                        }
                        dropdownOptions={headerDropdownOptions}
                        rightNode={
                            <>
                                {hasAnyFieldValueChanges && (
                                    <ColoredStatusLabel
                                        label={t('common:driver-details.status.not-saved')}
                                        color={StyleGuideColorsEnum.orange}
                                    />
                                )}
                            </>
                        }
                        onClose={onClose}
                    />
                </HeaderSideBarLayout>
                <SideBarLayout>
                    <div className={cx('section')}>
                        <div className={cx('section__title')}>{t('common:driver-details.sections.name')}</div>
                        <div className={cx('section__block')}>
                            <FieldGroup>
                                <FormikField
                                    className={cx('field--formal')}
                                    name={FieldsEnum.formal}
                                    error={formik.errors[FieldsEnum.formal]}
                                    meta={formik.getFieldMeta(FieldsEnum.formal)}
                                    label={t('common:driver-details.fields.formal.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <UserFormalDropdown
                                            value={formik.values[FieldsEnum.formal]}
                                            onChange={props.onChange}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            placeholder={t('common:driver-details.fields.formal.placeholder')}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field--first-name')}
                                    name={FieldsEnum.firstName}
                                    error={formik.errors[FieldsEnum.firstName]}
                                    meta={formik.getFieldMeta(FieldsEnum.firstName)}
                                    label={t('common:driver-details.fields.first-name.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.firstName}
                                            value={formik.values[FieldsEnum.firstName]}
                                            formatter={uppercaseFirstCharacterFormatter}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field--last-name')}
                                    name={FieldsEnum.lastName}
                                    error={formik.errors[FieldsEnum.lastName]}
                                    meta={formik.getFieldMeta(FieldsEnum.lastName)}
                                    label={t('common:driver-details.fields.last-name.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.lastName}
                                            value={formik.values[FieldsEnum.lastName]}
                                            formatter={uppercaseFirstCharacterFormatter}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                        </div>
                    </div>
                    <div className={cx('section')}>
                        <div className={cx('section__title')}>{t('common:driver-details.sections.contacts')}</div>
                        <div className={cx('section__block')}>
                            <FieldGroup>
                                <FormikField
                                    className={cx('field--desktop-phone')}
                                    name={FieldsEnum.desktopPhone}
                                    error={formik.errors[FieldsEnum.desktopPhone]}
                                    meta={formik.getFieldMeta(FieldsEnum.desktopPhone)}
                                    label={t('common:driver-details.fields.desktop-phone.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <PhoneNumberInput
                                            name={FieldsEnum.desktopPhone}
                                            value={formik.values[FieldsEnum.desktopPhone]}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FieldValue
                                    key="mobile-phone"
                                    icon={<PhoneNumberIcon phoneNumber={driverDetails?.contact?.mobilePhone} />}
                                    className={cx('field--mobile-phone')}
                                    label={t('common:driver-details.fields.mobile-phone.label')}
                                    placeholder={t('common:driver-details.fields.mobile-phone.placeholder')}
                                    value={driverDetails?.contact?.mobilePhone || ''}
                                />
                            </FieldGroup>
                            {MEDIA_FIELDS_NAME.map((media) => {
                                const mediaValue = formik.values[media];

                                if (typeof mediaValue !== 'string') {
                                    return null;
                                }

                                return (
                                    <FormikField
                                        key={media}
                                        name={media}
                                        error={formik.errors[media]}
                                        meta={formik.getFieldMeta(media)}
                                        label={t(`common:driver-details.fields.${media}.label`)}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <div className={cx('media')}>
                                                <Input
                                                    name={media}
                                                    className={cx('media__input')}
                                                    value={mediaValue}
                                                    onChange={props.onChange}
                                                    onBlur={props.onBlur}
                                                    onFocus={props.onFocus}
                                                    renderLeftIcon={() => ICON_BY_MEDIA[media]}
                                                    hasError={props.hasError}
                                                    hasWarning={props.hasWarning}
                                                    hasChanges={props.hasChanges}
                                                    autoFocus={lastAddedMediaField === media}
                                                />
                                                <TransparentTrigger
                                                    className={cx('media__icon')}
                                                    onClick={() => {
                                                        formik.setFieldValue(media, undefined);
                                                    }}
                                                    reflectionTheme={ReflectionThemeEnum.light}
                                                    leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                                                />
                                            </div>
                                        )}
                                    </FormikField>
                                );
                            })}
                            <AddOtherMessengerDropdown
                                formValues={formik.values}
                                onCreateField={(socialMedia) => {
                                    setLastAddedMediaField(socialMedia);
                                    formik.setFieldValue(socialMedia, '');
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx('section')}>
                        <div className={cx('section__title')}>{t('common:driver-details.sections.personals')}</div>
                        <div className={cx('section__block')}>
                            <FormikField
                                name={FieldsEnum.address}
                                error={formik.errors[FieldsEnum.address]}
                                meta={formik.getFieldMeta(FieldsEnum.address)}
                                label={t('common:driver-details.fields.address.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.address}
                                        value={formik.values[FieldsEnum.address]}
                                        formatter={uppercaseFirstCharacterFormatter}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FieldGroup>
                                <FormikField
                                    className={cx('field--birthday')}
                                    name={FieldsEnum.birthday}
                                    error={formik.errors[FieldsEnum.birthday]}
                                    meta={formik.getFieldMeta(FieldsEnum.birthday)}
                                    label={t('common:driver-details.fields.birthday.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <DatePicker
                                            hasYearMonthForm
                                            {...datePicketRangePresets.birthday}
                                            name={FieldsEnum.birthday}
                                            value={formik.values[FieldsEnum.birthday]}
                                            initialValue={initialValues[FieldsEnum.birthday]}
                                            placeholder={t('common:driver-details.fields.birthday.placeholder')}
                                            onChange={props.onChange}
                                            overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                            hasChanges={props.hasChanges}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            autoComplete={AUTO_COMPLETE_OFF_FIX}
                                            hasClearControl
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field--country')}
                                    name={FieldsEnum.countryCode}
                                    error={formik.errors[FieldsEnum.countryCode]}
                                    label={t('common:driver-details.fields.residence.label')}
                                    meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <CountryDropdown
                                            value={formik.values[FieldsEnum.countryCode]}
                                            onChange={(countryCode) => {
                                                props.onChange(countryCode);
                                            }}
                                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                            <FieldGroup>
                                <FormikField
                                    className={cx('field--language')}
                                    name={FieldsEnum.language}
                                    error={formik.errors[FieldsEnum.language]}
                                    label={t('common:driver-details.fields.language.label')}
                                    meta={formik.getFieldMeta(FieldsEnum.language)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <LanguageSelector
                                            value={formik.values[FieldsEnum.language]}
                                            onChange={handleSelectLanguage}
                                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field--rate')}
                                    name={FieldsEnum.ratePerKm}
                                    error={formik.errors[FieldsEnum.ratePerKm]}
                                    meta={formik.getFieldMeta(FieldsEnum.ratePerKm)}
                                    label={t('common:driver-details.fields.rate-per-hour.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <NumberInput
                                            name={FieldsEnum.ratePerKm}
                                            unitType={UnitTypeEnum.euroAbbreviation}
                                            placeholder={t('common:driver-details.fields.rate-per-hour.placeholder')}
                                            value={formik.values[FieldsEnum.ratePerKm]}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasChanges={props.hasChanges}
                                            step={1}
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                        </div>
                    </div>
                </SideBarLayout>
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                <FooterSideBarLayout hasPaddings>
                    <div className={cx('actions')}>
                        <Button
                            className={cx('actions__action', 'actions__action--cancel')}
                            theme={ButtonThemeEnum.secondary}
                            onClick={onGoBack ? () => onGoBack({ needCloseConfirmation: false }) : noop}
                        >
                            {t('common:form-actions.cancel')}
                        </Button>
                        <Button
                            className={cx('actions__action', 'actions__action--update')}
                            theme={ButtonThemeEnum.primary}
                            type="submit"
                        >
                            {t('common:driver-details.update-contact')}
                        </Button>
                    </div>
                </FooterSideBarLayout>
            </form>
            <ArchiveDriverConfirmation
                data={archiveDriverConfirmation.data}
                onConfirm={handleConfirmArchive}
                onCancel={archiveDriverConfirmation.onCancel}
                onClose={archiveDriverConfirmation.onClose}
                requestStatus={updateDriversRequestStatus}
            />
            <ActivateDriverConfirmation
                data={activateDriverConfirmation.data}
                onConfirm={handleConfirmActivate}
                onCancel={activateDriverConfirmation.onCancel}
                onClose={activateDriverConfirmation.onClose}
                requestStatus={updateDriversRequestStatus}
            />
            <CancelDriverInviteModal
                data={cancelDriverInviteConfirmation.data}
                onConfirm={handleConfirmCancelDriverInvite}
                onCancel={cancelDriverInviteConfirmation.onCancel}
                onClose={cancelDriverInviteConfirmation.onClose}
                requestStatus={updateDriversRequestStatus}
            />
        </>
    );
};

export default EditDriverSidebarContent;
