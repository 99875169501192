import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './OrderRouteLocations.scss';
import { ApiLocationT, ApiOrderWaypointT, CountryCodeT } from 'common/utils/api/models';
import { formatTimeInterval } from 'common/utils/time';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import RightArrowIcon from 'common/icons/RightArrowIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import AsyncDictCountryNameLabel from 'common/components/AsyncDictCountryNameLabel/AsyncDictCountryNameLabel';

type PropsT = {
    origin?: ApiOrderWaypointT['address'];
    pickupDockingHoursFrom?: ApiOrderWaypointT['dateTimeFrom'];
    pickupDockingHoursTo?: ApiOrderWaypointT['dateTimeTo'];
    destination?: ApiOrderWaypointT['address'];
    dropOffDockingHoursFrom?: ApiOrderWaypointT['dateTimeFrom'];
    dropOffDockingHoursTo?: ApiOrderWaypointT['dateTimeTo'];
    className?: string;
};

const cx = classNames.bind(styles);

type TimeIntervalT = {
    from: string;
    to: string;
};

const FULL_DATE_FORMAT = 'D MMM YYYY';

const OrderRouteLocations: React.FC<PropsT> = React.memo((props) => {
    const {
        origin,
        pickupDockingHoursFrom,
        pickupDockingHoursTo,
        destination,
        dropOffDockingHoursFrom,
        dropOffDockingHoursTo,
        className,
    } = props;

    const renderLocationNode = (
        locationNode: React.ReactNode,
        countryCode: CountryCodeT | undefined,
        countryNode: React.ReactNode,
        timeWindowNode: React.ReactNode,
    ): React.ReactNode => {
        return (
            <div className={cx('location')}>
                <div className={cx('location__locality')} data-test-selector={`${testSelector}_location`}>
                    {locationNode}
                </div>
                <div className={cx('location__country')} data-test-selector={`${testSelector}_country`}>
                    <FlagIcon className={cx('location__flag')} countryCode={countryCode} />
                    {countryNode}
                </div>
                <div className={cx('location__time-window')} data-test-selector={`${testSelector}_time-window`}>
                    {timeWindowNode}
                </div>
            </div>
        );
    };

    const renderCountry = (location: ApiLocationT | undefined): React.ReactNode => {
        if (!location) {
            return null;
        }

        return <AsyncDictCountryNameLabel countryCode={location.country} />;
    };

    const renderLocation = (
        location: ApiLocationT | undefined,
        timeInterval: TimeIntervalT,
        testSelector: string,
    ): React.ReactNode => {
        if (!location) {
            return renderLocationNode(
                <div className={cx('skeleton')} style={{ width: '30%' }} />,
                undefined,
                <div className={cx('skeleton')} style={{ width: '30%' }} />,
                <div className={cx('skeleton')} style={{ width: '60%' }} />,
            );
        }

        const title = [location.zipCode, location.city].filter((value) => !!value).join(', ');

        return renderLocationNode(
            title,
            location.country,
            renderCountry(location),
            formatTimeInterval(timeInterval.from, timeInterval.to, FULL_DATE_FORMAT),
        );
    };

    const testSelector = 'order-route';

    return (
        <div className={cs(cx('route'), className)}>
            <div className={cx('route__location')}>
                {renderLocation(
                    origin,
                    {
                        from: pickupDockingHoursFrom || '',
                        to: pickupDockingHoursTo || '',
                    },
                    `${testSelector}_origin`,
                )}
            </div>
            <div className={cx('route__direction')}>
                <RightArrowIcon fillColor={StyleGuideColorsEnum.light} />
            </div>
            <div className={cx('route__location')}>
                {renderLocation(
                    destination,
                    {
                        from: dropOffDockingHoursFrom || '',
                        to: dropOffDockingHoursTo || '',
                    },
                    `${testSelector}_destination`,
                )}
            </div>
        </div>
    );
});

export default OrderRouteLocations;
