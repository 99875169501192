import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

const routePolylineOptions = {
    strokeOpacity: 1.0,
    strokeWeight: 4,
};

const routeShadowPolylineOptions = {
    strokeColor: StyleGuideColorsEnum.white,
    strokeOpacity: 1.0,
    strokeWeight: 6,
};

export enum MapRouteThemeEnum {
    assignmentRoute = 'assignment-route',
    assignmentAccent = 'assignment-accent',
    assignmentAttention = 'assignment-attention',
    assignmentWarning = 'assignment-warning',
    assignmentPositive = 'assignment-positive',
    assignmentSelected = 'assignment-selected',
    trackFuture = 'track-future',
    trackPast = 'track-past',
    trackCurrent = 'track-current',
    orderCreation = 'order-creation',
    laneCreation = 'lane-creation',
}

const COLOR_MAP: Record<MapRouteThemeEnum, StyleGuideColorsEnum> = {
    [MapRouteThemeEnum.assignmentRoute]: StyleGuideColorsEnum.gray,
    [MapRouteThemeEnum.assignmentAccent]: StyleGuideColorsEnum.charcoal,
    [MapRouteThemeEnum.assignmentAttention]: StyleGuideColorsEnum.orange,
    [MapRouteThemeEnum.assignmentWarning]: StyleGuideColorsEnum.tomatoRed,
    [MapRouteThemeEnum.assignmentPositive]: StyleGuideColorsEnum.brandAccent,
    [MapRouteThemeEnum.assignmentSelected]: StyleGuideColorsEnum.charcoal,
    [MapRouteThemeEnum.trackCurrent]: StyleGuideColorsEnum.brandAccent,
    [MapRouteThemeEnum.trackPast]: StyleGuideColorsEnum.brandAccent,
    [MapRouteThemeEnum.trackFuture]: StyleGuideColorsEnum.gray,
    [MapRouteThemeEnum.orderCreation]: StyleGuideColorsEnum.brandDark,
    [MapRouteThemeEnum.laneCreation]: StyleGuideColorsEnum.charcoal,
};

const HOVER_COLOR_MAP: Record<MapRouteThemeEnum, StyleGuideColorsEnum> = {
    ...COLOR_MAP,
};

type PropsT = {
    map: MapT | null | undefined;
    maps: MapsT | null | undefined;
    theme: MapRouteThemeEnum;

    geometryLibrary?: GeometryLibraryT | null | undefined;

    polylines?: Array<GooglePolylineT> | null | undefined;
    route?: Array<GeoPointT> | null | undefined;
};

const MapRoute: React.FC<PropsT> = React.memo((props) => {
    const { map, maps, theme, polylines, route, geometryLibrary } = props;

    const routeShadowPolylineRef = React.useRef<google.maps.Polyline | null>(null);
    const routePolyline = React.useRef<google.maps.Polyline | null>(null);

    const [isHover, setHover] = React.useState<boolean>(false);

    const strokeColor = isHover ? HOVER_COLOR_MAP[theme] : COLOR_MAP[theme];

    React.useEffect(() => {
        let path: Array<GeoPointT> | Array<LatLngT> | null = route || null;

        if (polylines) {
            path = (polylines || []).reduce((acc: Array<LatLngT>, polyline) => {
                const decodedPath = geometryLibrary?.encoding?.decodePath(polyline) || [];

                return [...acc, ...decodedPath];
            }, []);
        }

        if (!maps || !map) {
            return;
        }

        /* start change routeShadowPolyline */
        if (routeShadowPolylineRef.current) {
            routeShadowPolylineRef.current.setMap(null);
        }

        if (path?.length) {
            routeShadowPolylineRef.current = new maps.Polyline({
                path,
                geodesic: true,
                ...routeShadowPolylineOptions,
            });
            routeShadowPolylineRef.current.setMap(map);
        }
        /* end change routeShadowPolyline */

        /* start change routePolyline */
        if (routePolyline.current) {
            routePolyline.current.setMap(null);
        }

        if (path?.length) {
            // fix google maps bug, old polyline delete all polylines
            routePolyline.current = new maps.Polyline({
                path,
                geodesic: true,
                strokeColor,
                ...routePolylineOptions,
            });
            routePolyline.current.setMap(map);
            routePolyline.current?.addListener('mouseover', () => {
                setHover(true);
            });
            routePolyline.current?.addListener('mouseout', () => {
                setHover(false);
            });
        }
        /* end change routePolyline */
    }, [map, maps, polylines, strokeColor, geometryLibrary]);

    React.useEffect(() => {
        return () => {
            if (routeShadowPolylineRef.current) {
                routeShadowPolylineRef.current.setMap(null);
            }

            if (routePolyline.current) {
                routePolyline.current.setMap(null);
            }
        };
    }, []);

    return null;
});

export default MapRoute;
