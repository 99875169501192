import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
};

const DriverIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 2a5.94 5.94 0 014.465 2.022A6.086 6.086 0 0115.633 8H4.367c.26-.727.653-1.39 1.151-1.959A5.942 5.942 0 0110 4zm0 7a1 1 0 110-2 1 1 0 110 2zm-6-1c2.732 0 4.945 2.633 4.992 5.899C6.164 15.414 4 12.963 4 10zm7.008 5.899C11.055 12.633 13.268 10 16 10c0 2.963-2.164 5.414-4.992 5.899z"
            />
        </svg>
    );
};

DriverIcon.displayName = 'DriverIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default DriverIcon;

export const DriverIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            fillColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getDriverIconProps = (preset: 'default' | 'selected'): Pick<PropsT, 'fillColor'> => {
    switch (preset) {
        case 'selected': {
            return {
                fillColor: StyleGuideColorsEnum.brandDark,
            };
        }
        default: {
            return {
                fillColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
