import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TruckDetailsPage.scss';
import { useTranslation } from 'react-i18next';
import DetailsLayout from 'common/layouts/DetailsLayout/DetailsLayout';
import AssetDetailsMap from 'common/components/AssetDetailsMap/AssetDetailsMap';
import { AssetTypeEnum, QueryKeysEnum } from 'common/constants';
import useQuery from 'common/utils/hooks/useQuery';
import { fetchVehicleSchedule } from 'common/store/vehicle-schedules/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchScheduleRequest, selectVehicleSchedule } from 'common/store/vehicle-schedules/selectors';
import { useParams } from 'react-router-dom';
import { formatQuery } from 'common/utils/query';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { selectTrucksDetailsByIds } from 'common/store/trucks/selectors';
import isNumber from 'lodash/isNumber';
import AssetScheduleSidebar from 'common/components/AssetScheduleSidebar/AssetScheduleSidebar';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import CommonEditableTruckDetailsLayout from 'common/layouts/CommonEditableTruckDetailsLayout/CommonEditableTruckDetailsLayout';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { useStateSafeHistory } from 'common/utils/hooks/useStateSafeHistory';
import { useAssetServiceLocationContextValue } from 'common/components/AssetSidebarContent/hooks/useAssetServiceLocationContextValue';
import AssetServiceLocationContext from 'common/components/AssetSidebarContent/contexts/asset-service-location-context';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import GoogleMapContext from 'common/contexts/google-map-context';

const cx = classNames.bind(styles);

type PropsT = {
    onCloseMap: () => void;
    onClose: () => void;
};

type QueryT = {
    [QueryKeysEnum.truckOrderId]?: string;
};

const TruckDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const { onCloseMap, onClose } = props;

    const assetServiceLocationContextValue = useAssetServiceLocationContextValue();

    const openLeftSidebar = useOpenLeftSidebar();

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();

    const params = useParams<{ truckId: string; partnerId: string }>();
    const { truckId } = params;

    const detailsById = useSelector(selectTrucksDetailsByIds(partnerId));
    const truckDetails = detailsById[truckId as string];

    const fetchScheduleRequestStatus = useSelector(selectFetchScheduleRequest(truckId));
    const schedule = useSelector(selectVehicleSchedule(truckId));

    const dispatch = useDispatch();

    const query = useQuery<QueryT>();

    const { stateSafeHistory } = useStateSafeHistory();

    const handleOrderClick = (orderId: string) => {
        stateSafeHistory.replace({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.truckOrderId]: orderId,
            }),
        });
    };

    React.useEffect(() => {
        dispatch(fetchVehicleSchedule(truckId));
    }, [truckId]);

    const currentPosition = React.useMemo((): GeoPointT | null => {
        if (!truckDetails) {
            return null;
        }

        const { latitude, longitude } = truckDetails;
        if (!isNumber(latitude) || !isNumber(longitude)) {
            return null;
        }

        return {
            lat: latitude,
            lng: longitude,
        };
    }, [truckDetails]);

    const handleCloseMap = () => {
        onCloseMap();
    };

    const handleOpenTruckDetails = (truckId: TruckIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.truckDetails,
            partnerId,
            truckId,
        });
    };

    const handleOpenTrailerDetails = (trailerId: TrailerIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.trailerDetails,
            partnerId,
            trailerId,
        });
    };

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <AssetServiceLocationContext.Provider value={assetServiceLocationContextValue}>
                <PageTitle
                    title={t('page-titles.truck-details', {
                        plateNumber: truckDetails?.plateNumber,
                    })}
                />
                <DetailsLayout
                    className={cx('wrap')}
                    sideBarTitle={t('common:order-details.main-tabs.details')}
                    sideBarNode={
                        <CommonEditableTruckDetailsLayout
                            partnerId={partnerId}
                            truckId={truckId}
                            onClose={onClose}
                            isShowMap
                            onToggleShowMap={handleCloseMap}
                            onOpenTrailerDetails={handleOpenTrailerDetails}
                            onOpenTruckDetails={handleOpenTruckDetails}
                        />
                    }
                    mapTitle={t('common:truck-page.tabs.map')}
                    mapNode={
                        <AssetDetailsMap
                            type={AssetTypeEnum.truck}
                            currentPosition={currentPosition}
                            telematicUpdateDate={truckDetails?.telematicUpdateDate}
                            isLoading={fetchScheduleRequestStatus.loading || !truckDetails}
                        />
                    }
                    historyTitle={t('common:truck-page.tabs.activity')}
                    historyNode={null}
                    timelineTitle={t('common:timeline')}
                    timelineNode={(close) => (
                        <AssetScheduleSidebar
                            type="truck"
                            isLoading={fetchScheduleRequestStatus.loading}
                            onClose={close}
                            onEventClick={handleOrderClick}
                            selectedOrderId={query[QueryKeysEnum.truckOrderId]}
                            schedule={schedule}
                        />
                    )}
                />
            </AssetServiceLocationContext.Provider>
        </GoogleMapContext.Provider>
    );
});

export default TruckDetailsPage;
